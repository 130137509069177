import { observer } from 'mobx-react';
import Button from '@amzn/meridian/button';
import Modal, { ModalFooter } from '@amzn/meridian/modal';
import React from 'react';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import UnassignmentController from '../../controllers/UnassignmentController';
import assignmentStore from '../../stores/assignmentStore';
import transporterStore from '../../stores/transporterStore';

export interface UnassignmentConfirmDialogProps {}

class UnassignmentConfirmDialogComp extends React.Component<UnassignmentConfirmDialogProps> {
  render() {
    const { confirmMessages } = assignmentStore;
    const messageComps = confirmMessages.map((m, index) => {
      return <li key={`unassignment-confirm-msg-${index + 1}`}>{m}</li>;
    });
    const { selectedTransporterId } = transporterStore;
    return (
      !selectedTransporterId && (
        <Modal
          title={`Unassignment Confirmation`}
          open={confirmMessages.length > 0}
          scrollContainer="viewport"
        >
          <Text tag="ul">{messageComps}</Text>
          <ModalFooter>
            <Row alignmentHorizontal="end" widths="fit">
              <Button
                type="secondary"
                size="small"
                onClick={() => UnassignmentController.cancelUnassignment()}
                propagateClickEvent={false}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                size="small"
                onClick={() => UnassignmentController.confirmUnassignment()}
                propagateClickEvent={false}
              >
                Confirm
              </Button>
            </Row>
          </ModalFooter>
        </Modal>
      )
    );
  }
}

export default observer(UnassignmentConfirmDialogComp);
