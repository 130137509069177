import { BehaviorSubject, Observable } from 'rxjs';
import Log from '../logging/Log';
import OrderController from './OrderController';
import OrderService from '../services/OrderService';
import RouteController from './RouteController';
import RouteService from '../services/RouteService';
import SiteController from './SiteController';
import SiteService from '../services/SiteService';
import Subscription from '../services/Subscription';
import TransporterController from './TransporterController';
import TransporterService from '../services/TransporterService';
import orderStore from '../stores/orderStore';
import routeStore from '../stores/routeStore';
import transporterStore from '../stores/transporterStore';

/**
 * This controller will be responsible for switching from one site to another including unsubscribing to old
 * site data and subscribing to new site data as well as handling any dropped subscription errors
 */
export default class SiteDataController {
  static subscriptionErrorSubject = new BehaviorSubject<boolean>(false);
  static subscriptionError$: Observable<boolean> =
    this.subscriptionErrorSubject.asObservable();

  static currentSiteCode: string = '';

  static subscriptions: Subscription<object>[] = [];

  static async handleSiteChange(newSiteCode: string): Promise<void> {
    if (SiteDataController.currentSiteCode === newSiteCode) {
      return;
    }

    SiteDataController.currentSiteCode = newSiteCode;
    await SiteDataController.initSiteData(newSiteCode);
    //this is a temporary mitigation solution for this issue: https://t.corp.amazon.com/V1225471263
    await SiteDataController.refreshTransporterData(newSiteCode);
  }

  static async handleError(error: any): Promise<void> {
    SiteDataController.subscriptionErrorSubject.next(true);
  }

  static async initSiteData(siteCode: string): Promise<void> {
    // close existing subscriptions
    await Promise.all(
      SiteDataController.subscriptions.map((s) => s.closeSubscription())
    );

    // create new subscriptions
    SiteDataController.subscriptions =
      SiteDataController.createAllSubscriptions(siteCode);

    // open all the new subscriptions with an error handler which will reinitialize site data on error
    await Promise.all(
      SiteDataController.subscriptions.map((subscription) => {
        subscription.setErrorReceiver(SiteDataController.handleError);
        return subscription.openSubscription();
      })
    );

    // perform all queries for site data in parallel
    const [transporters, routes, orders] = await Promise.all([
      TransporterService.getTransportersForSite(siteCode),
      RouteService.getRoutesForSite(siteCode),
      OrderService.getOrdersForSite(siteCode),
    ]);
    transporters.forEach((transporter) =>
      TransporterController.fixTransporterData(transporter)
    );
    routes.forEach((route) => RouteController.fixRouteData(route));
    orders.forEach((order) => OrderController.fixOrderData(order));

    const transportersToSet = transporters.filter(
      (transporter) => transporter.transporterId
    );
    Log.debug(
      `SiteDataController.initSiteData: ${transportersToSet.length} transporters`
    );
    transportersToSet.forEach((transporter) => {
      Log.debug(
        `SiteDataController.initSiteData: transporter=${JSON.stringify(
          transporter
        )}`
      );
    });
    transporterStore.setTransporters(transportersToSet);

    const routesToSet = routes.filter((route) => route.routeId);
    Log.debug(`SiteDataController.initSiteData: ${routesToSet.length} routes`);
    routesToSet.forEach((route) => {
      Log.debug(
        `SiteDataController.initSiteData: route=${JSON.stringify(route)}`
      );
    });
    routeStore.setRoutes(routesToSet);

    const ordersToSet = orders.filter((order) => order.orderId);
    Log.debug(`SiteDataController.initSiteData: ${ordersToSet.length} orders`);
    ordersToSet.forEach((order) => {
      Log.debug(
        `SiteDataController.initSiteData: order=${JSON.stringify(order)}`
      );
    });
    orderStore.setOrders(ordersToSet);
  }

  private static createAllSubscriptions(
    siteCode: string
  ): Subscription<object>[] {
    return [
      // site update
      SiteService.subscribeToUpdateSite(
        siteCode,
        SiteController.handleUpdateSite
      ),

      // transporter create, destroy, and update
      TransporterService.subscribeToCreateTransporter(
        siteCode,
        TransporterController.handleUpdateTransporter
      ),
      TransporterService.subscribeToDestroyTransporter(
        siteCode,
        TransporterController.handleDestroyTransporter
      ),
      TransporterService.subscribeToUpdateTransporter(
        siteCode,
        TransporterController.handleUpdateTransporter
      ),

      // route create, destroy, and update
      RouteService.subscribeToCreateRoute(
        siteCode,
        RouteController.handleUpdateRoute
      ),
      RouteService.subscribeToDestroyRoute(
        siteCode,
        RouteController.handleDestroyRoute
      ),
      RouteService.subscribeToUpdateRoute(
        siteCode,
        RouteController.handleUpdateRoute
      ),

      // order create, destroy, and update
      OrderService.subscribeToCreateOrder(
        siteCode,
        OrderController.handleUpdateOrder
      ),
      OrderService.subscribeToDestroyOrder(
        siteCode,
        OrderController.handleDestroyOrder
      ),
      OrderService.subscribeToUpdateOrder(
        siteCode,
        OrderController.handleUpdateOrder
      ),
    ];
  }

  static async refreshTransporterData(siteCode: string): Promise<void> {
    // Add a 5-second delay before executing the actual logic
    await new Promise((resolve) => setTimeout(resolve, 5000));

    const transporters = await TransporterService.getTransportersForSite(
      siteCode
    );
    transporters.forEach((transporter) =>
      TransporterController.fixTransporterData(transporter)
    );

    const transportersToSet = transporters.filter(
      (transporter) => transporter.transporterId
    );
    Log.debug(
      `SiteDataController.refreshTransporterData: ${transportersToSet.length} transporters`
    );
    transportersToSet.forEach((transporter) => {
      Log.debug(
        `SiteDataController.refreshTransporterData: transporter=${JSON.stringify(
          transporter
        )}`
      );
    });
    transporterStore.setTransporters(transportersToSet);
  }
}
