import './MapComp.css';
import { observer } from 'mobx-react';
import MapObject from '../../map/MapObject';
import MapOrdersComp from './order/MapOrdersComp';
import MapRouteTransportersComp from './route/MapRouteTransportersComp';
import MapRoutesComp from './route/MapRoutesComp';
import MapSiteComp from './site/MapSiteComp';
import MapTransportersComp from './transporter/MapTransportersComp';
import React from 'react';
import orderStore from '../../stores/orderStore';
import routeStore from '../../stores/routeStore';
import siteStore from '../../stores/siteStore';
import transporterStore from 'stores/transporterStore';

interface MapProps {}

class MapComp extends React.Component<MapProps> {
  async componentDidMount() {
    await MapObject.createOpenLayersMap();

    // perform queued overlay and feature work
    MapObject.performQueuedWork();
  }

  render() {
    const { selectedSite } = siteStore;
    const { transporters } = transporterStore;
    const { routes } = routeStore;

    return (
      <div className="mapContainer">
        <div id="map" className="map"></div>
        <MapSiteComp
          key={`site-${selectedSite.siteCode}`}
          site={selectedSite}
        />
        <MapRouteTransportersComp />
        <MapOrdersComp orderGroups={orderStore.filteredOrderGroups} />
        <MapTransportersComp
          transporterGroups={transporterStore.filteredTransporterGroups}
          transporters={transporters}
        />
        <MapRoutesComp routes={routes} />
      </div>
    );
  }
}

export default observer(MapComp);
