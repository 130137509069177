import { auditTime, merge } from 'rxjs';
import Log from '../logging/Log';
import SiteDataController from '../controllers/SiteDataController';
import applicationStatusStore from '../stores/applicationStatusStore';
import networkReactor from './networkReactor';

class SiteDataReactor {
  constructor() {
    /**
     * listen to network down events and set application status store state
     * */
    const rateLimitedNetworkLost = networkReactor.networkDown$.pipe(
      auditTime(1000)
    );
    rateLimitedNetworkLost.subscribe(async (event) => {
      if (event) {
        Log.warn(`SiteDataReactor.networkStatus: network is down!`);
        applicationStatusStore.setNetworkUp(false);
      }
    });

    /**
     * listen to network up events and set application status store state
     * */
    const rateLimitedNetworkUp = networkReactor.networkUp$.pipe(
      auditTime(1000)
    );
    rateLimitedNetworkUp.subscribe(async (event) => {
      if (event) {
        Log.info(`SiteDataReactor.networkStatus: network is up`);
        applicationStatusStore.setNetworkUp(true);
      }
    });

    /**
     * listen to network related or subscription error events and refresh the site data
     * */
    const restartData = merge(
      networkReactor.dataRefreshNeeded$.pipe(),
      SiteDataController.subscriptionError$.pipe()
    ).pipe(auditTime(1000));
    restartData.subscribe(async (event) => {
      if (event) {
        Log.info(
          `SiteDataReactor.networkStatus: data refresh is required due to potential data loss`
        );
        await SiteDataController.initSiteData(
          SiteDataController.currentSiteCode
        );
      }
    });
  }
}

export default new SiteDataReactor();
