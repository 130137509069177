import MapLocation from './model/MapLocation';

/**
 * The default map resolution in meters/pixel
 */
export const MAP_DEFAULT_RESOLUTION = 250;

// web mercator projection used by the map
export const PROJECTION_MAP = 'EPSG:3857';

// GPS projection
export const PROJECTION_GPS = 'EPSG:4326';

export const DEFAULT_MAP_CENTER: MapLocation = { latitude: 1, longitude: 1 };

/**
 * This feature draws a directed arrow on the middle of the line segment
 */
export const FEATURE_TYPE_DIRECTED_LINE_SEGMENT = 'directed_line_segment';

export const FEATURE_ATTRIBUTE_FEATURE_TYPE = 'featureType';
