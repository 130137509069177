import {
  ORDER_VIEW_ROUTES,
  ORDER_VIEW_UNPLANNED_ORDERS,
} from '../../util/ViewConstants';
import { observer } from 'mobx-react';
import ButtonGroup, { ButtonOption } from '@amzn/meridian/button-group';
import React from 'react';
import Row from '@amzn/meridian/row';
import SiteHelper from '../../util/SiteHelper';
import Text from '@amzn/meridian/text';
import ViewController from '../../controllers/ViewController';
import viewStore from '../../stores/viewStore';

interface OrderViewProps {}

class OrderViewComp extends React.Component<OrderViewProps> {
  render() {
    const { orderViews } = viewStore;

    return (
      <Row>
        <Text>{'View:'}</Text>
        <ButtonGroup
          value={orderViews}
          onChange={(newValues) => this.handleValueChange(newValues)}
          size="small"
        >
          <ButtonOption value={ORDER_VIEW_ROUTES}>Routes</ButtonOption>
          {!SiteHelper.isSsdSite() && (
            <ButtonOption value={ORDER_VIEW_UNPLANNED_ORDERS}>
              Orders
            </ButtonOption>
          )}
        </ButtonGroup>
      </Row>
    );
  }

  handleValueChange(values: string[]) {
    ViewController.handleOrderViewsChange(values);
  }
}

export default observer(OrderViewComp);
