import { MAP_VIEW_ROUTES } from '../../../util/ViewConstants';
import { observer } from 'mobx-react';
import MapTransporterGroupComp from '../transporter/MapTransporterGroupComp';
import React from 'react';
import routeStore from '../../../stores/routeStore';
import viewStore from '../../../stores/viewStore';

interface MapRouteTransportersProps {}

class MapRouteTransporterComp extends React.Component<MapRouteTransportersProps> {
  render() {
    const { mapView } = viewStore;

    const content = [];

    if (mapView === MAP_VIEW_ROUTES) {
      const { selectedRouteTransporterGroups } = routeStore;
      selectedRouteTransporterGroups.forEach((tg) => {
        const key = `map-route-transporter-group${tg.entities
          .map((t) => t.transporterId)
          .join('~')}}-${tg.centroid.latitude},${tg.centroid.longitude}`;
        content.push(
          <MapTransporterGroupComp
            key={key}
            highlighted={true}
            transporterGroup={tg}
          />
        );
      });
    }

    return content;
  }
}

export default observer(MapRouteTransporterComp);
