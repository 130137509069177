import { DELIVERY_WINDOW_END_TIME } from '../util/SortConstants';
import { action, makeObservable, observable } from 'mobx';

class SortStore {
  selectedSortCode: string;

  constructor() {
    makeObservable(this, {
      selectedSortCode: observable,
      setSelectedSortCode: action,
    });

    this.selectedSortCode = DELIVERY_WINDOW_END_TIME;
  }

  setSelectedSortCode(sortCode: string) {
    this.selectedSortCode = sortCode;
  }
}
export default new SortStore();
