import {
  AssignRouteToTransporterDocument,
  AssignmentResponse,
  GetRoutesForSiteDocument,
  GetRoutesForSiteQueryVariables,
  MutationAssignRouteToTransporterArgs,
  MutationUnassignRouteFromTransporterArgs,
  RouteLevelAssignmentInput,
  RoutesPage,
  SubscribeToCreateRouteDocument,
  SubscribeToDestroyRouteDocument,
  SubscribeToUpdateRouteDocument,
  UnassignRouteFromTransporterDocument,
} from '../graphqlGenerated/graphql';
import { Route } from '@amzn/gsf-dispatcher-schema';
import Performance from '../logging/Performance';
import Service from './Service';
import Subscription from './Subscription';

export const ROUTE_QUERY_LIMIT = 250;

export default class RouteService {
  static async getRoutesForSite(siteCode: string): Promise<Route[]> {
    const operationName = 'getRoutesForSite';
    const startTime = Date.now();
    const routes: Route[] = [];
    let nextToken = undefined;
    do {
      const variables: GetRoutesForSiteQueryVariables = {
        limit: ROUTE_QUERY_LIMIT,
        nextToken,
        siteCode,
      };
      const routesPage: RoutesPage = await Service.query(
        GetRoutesForSiteDocument,
        variables
      );
      const routesForPage = routesPage.routes;
      if (routesForPage) {
        routes.push(...routesForPage);
      }
      nextToken = routesPage.nextToken;
    } while (nextToken);
    Performance.log(operationName, startTime);
    return routes;
  }

  static subscribeToCreateRoute(
    siteCode: string,
    entityReceiver: (Route) => void
  ): Subscription<Route> {
    return Service.subscribe(
      SubscribeToCreateRouteDocument,
      { siteCode },
      entityReceiver
    );
  }

  static subscribeToDestroyRoute(
    siteCode: string,
    entityReceiver: (Route) => void
  ): Subscription<Route> {
    return Service.subscribe(
      SubscribeToDestroyRouteDocument,
      { siteCode },
      entityReceiver
    );
  }

  static subscribeToUpdateRoute(
    siteCode: string,
    entityReceiver: (Route) => void
  ): Subscription<Route> {
    return Service.subscribe(
      SubscribeToUpdateRouteDocument,
      { siteCode },
      entityReceiver
    );
  }

  static async assignRouteToTransporter(
    siteCode: string,
    routeAssignmentInput: RouteLevelAssignmentInput,
    transporterId: string
  ): Promise<AssignmentResponse> {
    const variables: MutationAssignRouteToTransporterArgs = {
      siteCode: siteCode,
      routeAssignmentInput: routeAssignmentInput,
      transporterId: transporterId,
    };
    return Service.query(AssignRouteToTransporterDocument, variables);
  }

  static async unassignRouteFromTransporter(
    siteCode: string,
    routeAssignmentInput: RouteLevelAssignmentInput
  ): Promise<AssignmentResponse> {
    const variables: MutationUnassignRouteFromTransporterArgs = {
      siteCode: siteCode,
      routeAssignmentInput: routeAssignmentInput,
    };
    return Service.query(UnassignRouteFromTransporterDocument, variables);
  }
}
