import * as _ from 'lodash';
import {
  ASSIGNMENT_ASSIGNED,
  ASSIGNMENT_UNASSIGNED,
  PREFIX_ASSIGNMENT,
  PREFIX_DELIVERY_WINDOW,
  PREFIX_ORDER_ID,
  PREFIX_PICKUP_ADDRESS,
  PREFIX_ROUTE_ID,
  PREFIX_ROUTE_LOCK,
} from '../util/FilterConstants';
import { Order, Route } from '@amzn/gsf-dispatcher-schema';
import AddressHelper from '../util/AddressHelper';
import FilterHelper, { FilterEvaluator } from '../util/FilterHelper';
import OrderHelper from '../util/OrderHelper';
import TimeHelper from '../util/TimeHelper';
import filterStore from '../stores/filterStore';

export default class OrderFilterController {
  static filterOrders(allOrders: Order[]): Order[] {
    return allOrders.filter((o) =>
      OrderFilterController.doesOrderPassFilter(o)
    );
  }

  private static doesOrderPassFilter(order: Order): boolean {
    const { selectedOrderFilterCodes } = filterStore;

    const evaluators: FilterEvaluator<Order>[] = [];

    // route id filter in order to cause no standalone orders to be found which will cause the
    // order view to switch to route view
    evaluators.push({
      prefix: PREFIX_ROUTE_ID,
      evaluatorFunction: (route: Route, codeValue: string) => {
        return false; // never match standalone orders if searching by route id
      },
    });

    // order id filter
    evaluators.push({
      prefix: PREFIX_ORDER_ID,
      evaluatorFunction: (order: Order, codeValue: string) => {
        return codeValue === order.orderId;
      },
    });

    // assignment filter
    evaluators.push({
      prefix: PREFIX_ASSIGNMENT,
      evaluatorFunction: (order: Order, codeValue: string) => {
        const isOrderAssigned = !!order.transporter?.transporterId;
        return (
          (codeValue === ASSIGNMENT_ASSIGNED && isOrderAssigned) ||
          (codeValue === ASSIGNMENT_UNASSIGNED && !isOrderAssigned)
        );
      },
    });

    // if filtering by route lock, then we don't want to show any orders matching
    evaluators.push({
      prefix: PREFIX_ROUTE_LOCK,
      evaluatorFunction: (order: Order, codeValue: string) => false,
    });

    // pickup address filter
    evaluators.push({
      prefix: PREFIX_PICKUP_ADDRESS,
      evaluatorFunction: (order: Order, codeValue: string) => {
        const pickupAddresses = OrderHelper.uniquePickupAddresses([order]).map(
          (pickupAddress) => AddressHelper.getDisplayAddress(pickupAddress)
        );
        return _.includes(pickupAddresses, codeValue);
      },
    });

    // delivery window filter
    evaluators.push({
      prefix: PREFIX_DELIVERY_WINDOW,
      evaluatorFunction: (order: Order, codeValue: string) => {
        const deliveryWindows = OrderHelper.uniqueDeliveryWindows([order]).map(
          (deliveryWindow) => TimeHelper.timeWindowAsString(deliveryWindow)
        );
        return _.includes(deliveryWindows, codeValue);
      },
    });

    return FilterHelper.passAllFilterGroups(
      order,
      selectedOrderFilterCodes,
      evaluators
    );
  }
}
