import './RemainingStopComp.css';
import * as _ from 'lodash';
import {
  Order,
  Transporter,
  TransporterStop,
  TransporterStopType,
} from '@amzn/gsf-dispatcher-schema';
import { observer } from 'mobx-react';
import AddressHelper from 'util/AddressHelper';
import Box from '@amzn/meridian/box';
import Column from '@amzn/meridian/column';
import React from 'react';
import RemainingTimeComp from 'components/common/RemainingTimeComp';
import Row from '@amzn/meridian/row';
import StringHelper from 'util/StringHelper';
import Text from '@amzn/meridian/text';
import UnitHelper from 'util/UnitHelper';
import deliveryImage from '../../assets/images/arrow_dn.svg';
import pickupImage from '../../assets/images/arrow_up.svg';

export interface RemainingStopProps {
  transporter: Transporter;
  stop: TransporterStop;
}

class RemainingStopComp extends React.Component<RemainingStopProps> {
  render() {
    const { transporter, stop } = this.props;
    const isPickup = stop.stopType === TransporterStopType.Pickup;
    const orders = transporter.remainingOrders.filter(
      (o) => stop.orderIds && _.includes(stop.orderIds, o.orderId)
    );
    const totalPackageCount = this.getTotalPackageCount(orders);
    const totalStopWeight = UnitHelper.getOrdersWeight(orders);
    const firstOrder: Order = orders[0];
    const address = isPickup
      ? firstOrder.pickupAddress
      : firstOrder.deliveryAddress;
    const endTime = isPickup
      ? firstOrder.pickupWindow.endDate
      : firstOrder.deliveryWindow.endDate;
    return (
      <Box type="outline" spacingInset="200" backgroundColor="white">
        <Row widths={['2em', 'fill', '6em']}>
          <Column>
            <img
              className="stop-type-image"
              src={isPickup ? pickupImage : deliveryImage}
            />
          </Column>
          <Column spacing={'100'}>
            <Row>
              <Text type="h100">
                {AddressHelper.getDisplayAddress(address)}
              </Text>
            </Row>
            <Row>
              <Text>
                {StringHelper.makeCountDisplay(totalPackageCount, 'package')}
              </Text>
              <Text>{totalStopWeight}</Text>
            </Row>
          </Column>
          <Column>
            <Row spacing="200">
              <RemainingTimeComp endTime={endTime} />
            </Row>
          </Column>
        </Row>
      </Box>
    );
  }

  getTotalPackageCount(orders: Order[]) {
    let totalCount = 0;
    orders.forEach((o) => {
      totalCount += o.packageCount;
    });
    return totalCount;
  }
}

export default observer(RemainingStopComp);
