import { MAP_VIEW_ROUTES, MAP_VIEW_TRANSPORTERS } from '../util/ViewConstants';
import { Transporter } from '@amzn/gsf-dispatcher-schema';
import { WARNING_MESSAGE_TIMEOUT } from '../util/TimeHelper';
import { auditTime, merge } from 'rxjs';
import GeospatialHelper from '../map/GeospatialHelper';
import MapObject from '../map/MapObject';
import MessagesController from '../controllers/MessagesController';
import RouteHelper from '../util/RouteHelper';
import TransporterHelper from '../util/TransporterHelper';
import routeReactor from './routeReactor';
import routeStore from '../stores/routeStore';
import transporterReactor from './transporterReactor';
import transporterStore from '../stores/transporterStore';
import viewReactor from './viewReactor';
import viewStore from '../stores/viewStore';
class SelectedTransporterGroupsReactor {
  constructor() {
    /**
     * listen to changes in the selected route id or map zoom/center or
     * the transporters locations and update the selectedRouteTransporterGroups
     */
    const rateLimitedSelectedRouteTransporters = merge(
      routeReactor.selectedRouteIdChange$.pipe(),
      transporterReactor.filteredTransportersChange$.pipe(),
      transporterReactor.filteredTransportersLocationChange$.pipe(),
      MapObject.mapResolutionChange$.pipe(),
      MapObject.mapCenterChange$.pipe(),
      viewReactor.mapViewChange$.pipe()
    ).pipe(auditTime(500));
    rateLimitedSelectedRouteTransporters.subscribe(async (event) => {
      if (event) {
        const { mapView } = viewStore;
        if (mapView === MAP_VIEW_ROUTES) {
          const { selectedRouteId } = routeStore;
          const route = RouteHelper.findRouteById(selectedRouteId);
          if (route) {
            const transporterMap = RouteHelper.getTransporterMap(route);
            const transporterIds = Array.from(transporterMap.keys());
            const onlineTransporters: Transporter[] = [];
            transporterIds.forEach((transporterId) => {
              const transporter =
                TransporterHelper.findTransporterById(transporterId);
              if (transporter) {
                onlineTransporters.push(transporter);
              }
            });
            const transporterGroups = await GeospatialHelper.groupTransporters(
              onlineTransporters
            );
            const ordersForRoute = RouteHelper.getOrders([route]);
            const orderGroups = await GeospatialHelper.groupOrders(
              ordersForRoute,
              false
            );
            routeStore.setSelectedRouteTransporterGroups(transporterGroups);
            routeStore.setSelectedRouteOrderGroups(orderGroups);
          } else {
            routeStore.setSelectedRouteOrderGroups([]);
            routeStore.setSelectedRouteTransporterGroups([]);
          }
        } else {
          routeStore.setSelectedRouteOrderGroups([]);
          routeStore.setSelectedRouteTransporterGroups([]);
        }
      }
    });

    /**
     * listen to changes in the selected transporter id or map zoom/center or
     * the location change of transporters and update the transporterTransporterGroup
     */
    const rateLimitedSelectedTransporter = merge(
      transporterReactor.selectedTransporterIdChange$.pipe(),
      transporterReactor.filteredTransportersChange$.pipe(),
      transporterReactor.filteredTransportersLocationChange$.pipe(),
      MapObject.mapResolutionChange$.pipe(),
      MapObject.mapCenterChange$.pipe(),
      viewReactor.mapViewChange$.pipe()
    ).pipe(auditTime(500));
    rateLimitedSelectedTransporter.subscribe(async (event) => {
      if (event) {
        const { mapView } = viewStore;
        if (mapView === MAP_VIEW_TRANSPORTERS) {
          const { selectedTransporterId } = transporterStore;
          const transporter = TransporterHelper.findTransporterById(
            selectedTransporterId
          );
          if (transporter) {
            const transporterGroups = await GeospatialHelper.groupTransporters([
              transporter,
            ]);
            if (transporterGroups && transporterGroups.length > 0) {
              transporterStore.setSelectedTransporterTransporterGroup(
                transporterGroups[0]
              );
            }
          } else {
            transporterStore.setSelectedTransporterTransporterGroup(undefined);
          }
        } else {
          transporterStore.setSelectedTransporterTransporterGroup(undefined);
        }
      }
    });

    /**
     * listen to changes in the selected route id and warn if transporter is not online
     */
    routeReactor.selectedRouteIdChange$.subscribe(async (event) => {
      if (event) {
        const { selectedRouteId } = routeStore;
        const route = RouteHelper.findRouteById(selectedRouteId);
        if (route) {
          const transporterMap = RouteHelper.getTransporterMap(route);
          const transporterIds = Array.from(transporterMap.keys());
          transporterIds.forEach((transporterId) => {
            const transporter =
              TransporterHelper.findTransporterById(transporterId);
            if (!transporter) {
              const offlineTransporter = transporterMap.get(transporterId);
              MessagesController.addWarningMessage(
                `'${offlineTransporter.name}' is not online`,
                WARNING_MESSAGE_TIMEOUT
              );
            }
          });
        }
      }
    });
  }
}

export default new SelectedTransporterGroupsReactor();
