import { Subscription } from 'rxjs';
import { observer } from 'mobx-react';
import DataAgeComp from '../common/DataAgeComp';
import React from 'react';
import SiteHelper from '../../util/SiteHelper';
import Text from '@amzn/meridian/text';
import TimeHelper from '../../util/TimeHelper';
import siteStore from '../../stores/siteStore';

export interface StaleSiteDataWarningProps {}

class StaleSiteDataWarningComp extends React.Component<StaleSiteDataWarningProps> {
  private subscription: Subscription;

  componentDidMount() {
    this.subscription = TimeHelper.secondChange$.subscribe(() => {
      this.forceUpdate();
    });
  }

  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  render() {
    const { selectedSite } = siteStore;
    const oldestUpdateTime = SiteHelper.getOldestUpdateTime(selectedSite);
    const isSiteDataStale = SiteHelper.isSiteDataStale(selectedSite);
    return (
      isSiteDataStale && (
        <React.Fragment>
          <Text type="h100" color="error" className="nowrap">
            WARNING: Stale Site Data!!
          </Text>
          <DataAgeComp
            key={'transporterDataAge'}
            pastTime={oldestUpdateTime}
            description={`Site '${selectedSite.siteCode}' data age`}
          />
        </React.Fragment>
      )
    );
  }
}

export default observer(StaleSiteDataWarningComp);
