import * as _ from 'lodash';
import { Transporter } from '@amzn/gsf-dispatcher-schema';
import GeospatialHelper from '../map/GeospatialHelper';
import TransporterHelper from '../util/TransporterHelper';
import siteStore from 'stores/siteStore';

export default class TransporterRankController {
  static rankTransporters(transporters: Transporter[]): Map<string, number> {
    const now = Date.now();
    const selectedSite = siteStore.selectedSite;
    if (selectedSite) {
      const rankedTransporters = _.clone(transporters);
      const rankers = [
        TransporterRankController.feasibleTransporter,
        TransporterRankController.noRemainingStops,
        TransporterRankController.remainingTimeOnBlock,
        TransporterRankController.remainingStopDistance,
        TransporterRankController.distanceFromSite,
      ];
      rankedTransporters.sort((t1, t2) => {
        for (const ranker of rankers) {
          const rank = ranker(t1, t2, now);
          if (rank !== 0) {
            return rank;
          }
        }
        return 0;
      });
      const transporterRankMap: Map<string, number> = new Map();
      rankedTransporters.forEach((transporter, index) => {
        transporterRankMap.set(transporter.transporterId, index + 1);
      });
      return transporterRankMap;
    } else {
      return new Map();
    }
  }

  private static feasibleTransporter(
    t1: Transporter,
    t2: Transporter,
    now: number
  ): number {
    return TransporterRankController.compareBoolean(
      TransporterHelper.feasibleTransporter(t1, now),
      TransporterHelper.feasibleTransporter(t2, now)
    );
  }

  private static noRemainingStops(
    t1: Transporter,
    t2: Transporter,
    now: number
  ): number {
    return TransporterRankController.compareBoolean(
      TransporterHelper.noRemainingStops(t1),
      TransporterHelper.noRemainingStops(t2)
    );
  }

  private static remainingTimeOnBlock(
    t1: Transporter,
    t2: Transporter,
    now: number
  ): number {
    const t1RemainingBlockTime =
      TransporterHelper.calculateRemainingBlockTimeInMinutes(t1, now);
    const t2RemainingBlockTime =
      TransporterHelper.calculateRemainingBlockTimeInMinutes(t2, now);
    // sort descending because more block time mo better
    return t2RemainingBlockTime - t1RemainingBlockTime;
  }

  private static remainingStopDistance(
    t1: Transporter,
    t2: Transporter,
    now: number
  ): number {
    const t1RemainingStopDistance = TransporterHelper.remainingStopDistance(t1);
    const t2RemainingStopDistance = TransporterHelper.remainingStopDistance(t2);
    return t1RemainingStopDistance - t2RemainingStopDistance;
  }

  private static distanceFromSite(
    t1: Transporter,
    t2: Transporter,
    now: number
  ): number {
    const { selectedSite } = siteStore;
    const t1DistanceFromSite = GeospatialHelper.getDistance(
      t1.lastKnownLocation,
      selectedSite.pickupLocation
    );
    const t2DistanceFromSite = GeospatialHelper.getDistance(
      t2.lastKnownLocation,
      selectedSite.pickupLocation
    );
    return t1DistanceFromSite - t2DistanceFromSite;
  }

  private static compareBoolean(b1: boolean, b2: boolean): number {
    if (b1 === b2) {
      return 0;
    }
    if (b1) {
      return -1;
    }
    return 1;
  }
}
