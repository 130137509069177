import RouteHelper from '../util/RouteHelper';
import RouteStore from '../stores/routeStore';
import viewStore from '../stores/viewStore';

export default class ViewController {
  static handleMapViewChange(mapView: string) {
    viewStore.setMapView(mapView);
  }

  static handleOrderViewsChange(orderViews: string[]) {
    viewStore.setOrderViews(orderViews);
  }

  static toggleRoutesCollapsed() {
    const routesCollapsed = viewStore.routesCollapsed;
    const updatedRoutesCollapsed = !routesCollapsed;
    viewStore.setRoutesCollapsed(updatedRoutesCollapsed);
    if (updatedRoutesCollapsed) {
      RouteStore.setExpandedRouteIds([]);
    } else {
      RouteStore.setExpandedRouteIds(RouteHelper.getRouteIds());
    }
  }

  static resetRoutesCollapsed() {
    viewStore.setRoutesCollapsed(true);
    RouteStore.setExpandedRouteIds([]);
  }

  static toggleGroupByAssignmentStatus() {
    const groupByAssignmentStatus = viewStore.groupByAssignmentStatus;
    viewStore.setGroupByAssignmentStatus(!groupByAssignmentStatus);
  }
}
