import * as _ from 'lodash';
import {
  ASSIGNMENT_ASSIGNED,
  ASSIGNMENT_UNASSIGNED,
  FILTER_DELIMITER,
  FilterOption,
  PREFIX_ASSIGNMENT,
  PREFIX_NUMBER_OF_STOPS,
  PREFIX_ROUTE_LOCK,
  PREFIX_TIME_LEFT_ON_BLOCK,
  PREFIX_TRANSPORTER_WITHIN_DISTANCE,
  ROUTE_LOCK_LOCKED,
  STOPS_0,
  TIME_LEFT_ON_BLOCK_30,
  TRANSPORTER_WITHIN_DISTANCE_200,
} from './FilterConstants';

export type EvaluatorFunctionMap = Map<string, (EntityType, string) => boolean>;

export interface FilterEvaluator<EntityType> {
  prefix: string;
  evaluatorFunction: (EntityType, string) => boolean;
}

export default class FilterHelper {
  static getStaticOrderFilterOptions(): FilterOption[] {
    return [
      {
        code: FilterHelper.makeFilterString(
          PREFIX_ASSIGNMENT,
          ASSIGNMENT_ASSIGNED
        ),
      },
      {
        code: FilterHelper.makeFilterString(
          PREFIX_ASSIGNMENT,
          ASSIGNMENT_UNASSIGNED
        ),
      },
      {
        code: FilterHelper.makeFilterString(
          PREFIX_ROUTE_LOCK,
          ROUTE_LOCK_LOCKED
        ),
      },
    ];
  }

  static getStaticTransporterFilterOptions(): FilterOption[] {
    return [
      {
        code: FilterHelper.makeFilterString(PREFIX_NUMBER_OF_STOPS, STOPS_0),
      },
      {
        code: FilterHelper.makeFilterString(
          PREFIX_TIME_LEFT_ON_BLOCK,
          TIME_LEFT_ON_BLOCK_30
        ),
      },
      {
        code: FilterHelper.makeFilterString(
          PREFIX_TRANSPORTER_WITHIN_DISTANCE,
          TRANSPORTER_WITHIN_DISTANCE_200
        ),
      },
    ];
  }

  static makeFilterString(s1: string, s2: string): string {
    return `${s1}${FILTER_DELIMITER}${s2}`;
  }

  static passAllFilterGroups<EntityType>(
    entity: EntityType,
    allCodes: string[],
    evaluators: (FilterEvaluator<EntityType> | EvaluatorFunctionMap)[]
  ): boolean {
    return _.every(evaluators, (evaluatorOrMapOfEvaluators) => {
      if (evaluatorOrMapOfEvaluators instanceof Map) {
        const mapOfEvaluators = evaluatorOrMapOfEvaluators as Map<
          string,
          (EntityType, string) => boolean
        >;
        const prefixes = Array.from(mapOfEvaluators.keys());

        const codesWithPrefix = prefixes.flatMap((prefix) =>
          FilterHelper.filterCodesWithPrefix(prefix, allCodes)
        );
        if (codesWithPrefix.length === 0) {
          // a filter group is passed if no selections are made for that filter group
          return true;
        }

        return _.some(prefixes, (prefix) => {
          const evaluatorFunction = mapOfEvaluators.get(prefix);
          return FilterHelper.passFilterGroup(
            entity,
            prefix,
            allCodes,
            evaluatorFunction
          );
        });
      } else {
        const evaluator =
          evaluatorOrMapOfEvaluators as FilterEvaluator<EntityType>;
        const { prefix, evaluatorFunction } = evaluator;
        const codesWithPrefix = FilterHelper.filterCodesWithPrefix(
          prefix,
          allCodes
        );
        if (codesWithPrefix.length === 0) {
          // a filter group is passed if no selections are made for that filter group
          return true;
        }
        return FilterHelper.passFilterGroup(
          entity,
          prefix,
          allCodes,
          evaluatorFunction
        );
      }
    });
  }

  static startsWithPrefix(prefix: string, code: string): boolean {
    return code.startsWith(`${prefix}${FILTER_DELIMITER}`);
  }

  static getCodeValue(prefix: string, code: string): string {
    return code.substring(`${prefix}${FILTER_DELIMITER}`.length);
  }

  private static passFilterGroup<EntityType>(
    entity: EntityType,
    filterPrefix: string,
    allCodes: string[],
    evaluator: (EntityType, string) => boolean
  ): boolean {
    const codesWithPrefix = FilterHelper.filterCodesWithPrefix(
      filterPrefix,
      allCodes
    );
    const filterResults = codesWithPrefix.map((codeWithPrefix) =>
      FilterHelper.passFilter(entity, filterPrefix, codeWithPrefix, evaluator)
    );
    return _.some(filterResults, (result) => result === true);
  }

  private static filterCodesWithPrefix(
    prefix: string,
    codes: string[]
  ): string[] {
    return codes.filter((code) => FilterHelper.startsWithPrefix(prefix, code));
  }
  private static passFilter<EntityType>(
    entity: EntityType,
    filterPrefix: string,
    code: string,
    evaluator: (EntityType, string) => boolean
  ): boolean {
    const codeValue = FilterHelper.getCodeValue(filterPrefix, code);
    return evaluator(entity, codeValue);
  }
}
