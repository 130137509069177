import { Address } from '@amzn/gsf-dispatcher-schema';

export default class AddressHelper {
  static getDisplayAddress(address: Address): string {
    const addressLines = [
      address?.address1,
      address?.address2,
      address?.address3,
    ];
    const addressLinesToUse = addressLines
      .filter((line) => line && line.trim() !== '')
      .map((line) => line.trim());
    const displayAddress = addressLinesToUse.join(' ');
    return displayAddress === '' ? undefined : displayAddress;
  }
}
