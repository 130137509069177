import searchByTransporterNameStore from '../stores/searchByTransporterNameStore';

export default class SearchTransporterByNameController {
  static handleSearchByTransporterNameSelection(transporterName: string) {
    if (transporterName.trim() !== '') {
      searchByTransporterNameStore.addSearchByNameValue(transporterName);
      searchByTransporterNameStore.setSearchByPartialStringInput('');
    }
  }

  static handleMultipleSearchByTransporterNameSelection(
    transporterNames: string[]
  ) {
    searchByTransporterNameStore.setSearchByNameValues(transporterNames);
  }

  static handleSearchByPartialString(partialString: string) {
    searchByTransporterNameStore.setSearchByPartialStringInput(partialString);
  }

  static handleRemoveSearchByTransporterNameValue(transporterName: string) {
    searchByTransporterNameStore.removeSearchByNameValue(transporterName);
  }

  static handleRemoveAll() {
    searchByTransporterNameStore.clearSearchByNameValues();
  }
}
