import {
  MAP_VIEW_TRANSPORTERS,
  ORDER_VIEW_ROUTES,
  ORDER_VIEW_UNPLANNED_ORDERS,
} from '../util/ViewConstants';
import { action, makeObservable, observable } from 'mobx';

class ViewStore {
  mapView: string = MAP_VIEW_TRANSPORTERS;
  orderViews: string[] = [ORDER_VIEW_ROUTES, ORDER_VIEW_UNPLANNED_ORDERS];
  routesCollapsed = true;
  groupByAssignmentStatus = true;
  constructor() {
    makeObservable(this, {
      mapView: observable,
      orderViews: observable,
      routesCollapsed: observable,
      groupByAssignmentStatus: observable,
      setMapView: action,
      setOrderViews: action,
      setRoutesCollapsed: action,
      setGroupByAssignmentStatus: action,
    });
  }

  setMapView(mapView: string) {
    this.mapView = mapView;
  }

  setOrderViews(orderViews: string[]) {
    this.orderViews = orderViews;
  }

  setRoutesCollapsed(routesCollapsed: boolean) {
    this.routesCollapsed = routesCollapsed;
  }

  setGroupByAssignmentStatus(groupByAssignmentStatus: boolean) {
    this.groupByAssignmentStatus = groupByAssignmentStatus;
  }
}

export default new ViewStore();
