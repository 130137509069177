import { observer } from 'mobx-react';
import AuthHelper from '../../auth/AuthHelper';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import React from 'react';
import signOutTokens from '@amzn/meridian-tokens/base/icon/sign-out';

export interface LogoutProps {}

class LogoutComp extends React.Component<LogoutProps> {
  render() {
    return (
      <Button
        type="icon"
        size="small"
        onClick={() => AuthHelper.signOut()}
        propagateClickEvent={false}
      >
        <Icon tokens={signOutTokens} />
      </Button>
    );
  }
}

export default observer(LogoutComp);
