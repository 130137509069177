import filterStore from '../stores/filterStore';

export default class DataFilterController {
  static handleRouteFilterValueChange(values: string[]) {
    filterStore.setSelectedOrderFilterCodes(values);
  }

  static handleTransporterFilterValueChange(values: string[]) {
    filterStore.setSelectedTransporterFilterCodes(values);
  }
}
