import {
  PREFIX_COMPANY_NAME,
  PREFIX_NUMBER_OF_STOPS,
  PREFIX_PROVIDER_TYPE,
  PREFIX_TIME_LEFT_ON_BLOCK,
  PREFIX_TRANSPORTER_NAME,
  PREFIX_TRANSPORTER_WITHIN_DISTANCE,
} from '../util/FilterConstants';
import { Transporter } from '@amzn/gsf-dispatcher-schema';
import FilterHelper, { FilterEvaluator } from '../util/FilterHelper';
import GeospatialHelper from 'map/GeospatialHelper';
import TransporterHelper from '../util/TransporterHelper';
import filterStore from '../stores/filterStore';
import siteStore from '../stores/siteStore';

export default class TransporterFilterController {
  static filterTransporters(allTransporters: Transporter[]): Transporter[] {
    return allTransporters.filter((t) =>
      TransporterFilterController.doesTransporterPassFilter(t)
    );
  }

  private static doesTransporterPassFilter(transporter: Transporter): boolean {
    const { selectedTransporterFilterCodes } = filterStore;
    const { selectedSite } = siteStore;

    const distance = GeospatialHelper.getDistance(
      transporter.lastKnownLocation,
      selectedSite.pickupLocation
    );

    const evaluators: FilterEvaluator<Transporter>[] = [];

    // company name filter
    evaluators.push({
      prefix: PREFIX_COMPANY_NAME,
      evaluatorFunction: (transporter: Transporter, codeValue: string) => {
        return codeValue === transporter.companyName;
      },
    });

    // provider type filter
    evaluators.push({
      prefix: PREFIX_PROVIDER_TYPE,
      evaluatorFunction: (transporter: Transporter, codeValue: string) => {
        return codeValue === TransporterHelper.getTransporterType(transporter);
      },
    });

    evaluators.push({
      prefix: PREFIX_TRANSPORTER_NAME,
      evaluatorFunction: (transporter: Transporter, codeValue: string) => {
        return codeValue === transporter.name;
      },
    });

    // within distance filter
    evaluators.push({
      prefix: PREFIX_TRANSPORTER_WITHIN_DISTANCE,
      evaluatorFunction: (transporter: Transporter, codeValue: string) => {
        return distance <= parseInt(codeValue);
      },
    });

    // stops filter
    evaluators.push({
      prefix: PREFIX_NUMBER_OF_STOPS,
      evaluatorFunction: (transporter: Transporter, codeValue: string) => {
        return parseInt(codeValue) === transporter.remainingStops?.length;
      },
    });

    // time left on block filter
    evaluators.push({
      prefix: PREFIX_TIME_LEFT_ON_BLOCK,
      evaluatorFunction: (transporter: Transporter, codeValue: string) => {
        return (
          parseInt(codeValue) <=
          TransporterHelper.calculateRemainingBlockTimeInMinutes(
            transporter,
            Date.now()
          )
        );
      },
    });

    return FilterHelper.passAllFilterGroups(
      transporter,
      selectedTransporterFilterCodes,
      evaluators
    );
  }
}
