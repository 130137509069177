import { action, makeObservable, observable } from 'mobx';

class AssignmentStore {
  confirmMessages: string[] = [];

  constructor() {
    makeObservable(this, {
      confirmMessages: observable,
      setConfirmationMessages: action,
      clearConfirmationMessages: action,
    });
  }

  setConfirmationMessages(messages: string[]) {
    this.confirmMessages = messages;
  }

  clearConfirmationMessages() {
    this.confirmMessages = [];
  }
}

export default new AssignmentStore();
