import { Subscription } from 'rxjs';
import { TextColor } from '@amzn/meridian/text/text';
import { observer } from 'mobx-react';
import React from 'react';
import Text from '@amzn/meridian/text';
import TimeHelper from '../../util/TimeHelper';

export interface RemainingTimeProps {
  endTime: string;
  outOfTimeString?: string;
  alertThreshold?: string;
  color?: TextColor;
  prefix?: string;
  suffix?: string;
}

class RemainingTimeComp extends React.Component<RemainingTimeProps> {
  private subscription: Subscription;

  componentDidMount() {
    this.subscription = TimeHelper.minuteChange$.subscribe(() => {
      this.forceUpdate();
    });
  }

  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  render() {
    const { endTime, outOfTimeString, alertThreshold, color, prefix, suffix } =
      this.props;
    let timeRemaining = TimeHelper.hoursTillEndTime(endTime);
    const alertActive = alertThreshold
      ? timeRemaining.localeCompare(alertThreshold) <= 0
      : false;
    if (
      (timeRemaining.startsWith('-') || timeRemaining.startsWith('0:00')) &&
      outOfTimeString
    ) {
      timeRemaining = outOfTimeString;
    }
    return (
      <Text
        type="h100"
        color={alertActive ? 'error' : color}
        className="nowrap"
      >
        {`${prefix || ''}${timeRemaining}${suffix || ''}`}
      </Text>
    );
  }
}

export default observer(RemainingTimeComp);
