import operationStore from '../stores/operationStore';

export default class OperationController {
  static startOperation(operationName: string) {
    operationStore.setOperationName(operationName);
  }

  static completeOperation() {
    operationStore.clearOperationName();
  }
}
