import { BehaviorSubject, Observable, merge } from 'rxjs';
import { reaction } from 'mobx';
import RouteController from '../controllers/RouteController';
import TransporterController from '../controllers/TransporterController';
import selectionReactor from './selectionReactor';
import viewStore from '../stores/viewStore';

class ViewReactor {
  private mapViewChangeSubject = new BehaviorSubject<boolean>(false);
  mapViewChange$: Observable<boolean> =
    this.mapViewChangeSubject.asObservable();

  constructor() {
    reaction(
      () => {
        return viewStore.mapView;
      },
      (mapView: string) => {
        this.mapViewChangeSubject.next(true);
      }
    );

    /**
     * set the map extents for map for selected transporter or route
     */
    const observableForSetMapExtentsForSelectedEntity = merge(
      this.mapViewChange$.pipe(),
      selectionReactor.selectedTransporterIdChange$.pipe(),
      selectionReactor.selectedRouteIdChange$.pipe()
    );
    observableForSetMapExtentsForSelectedEntity.subscribe(async (event) => {
      TransporterController.setMapExtendsToSelectedTransporter();
      RouteController.setMapExtentsToSelectedRoute();
    });
  }
}

export default new ViewReactor();
