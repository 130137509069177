import { observer } from 'mobx-react';
import React from 'react';
import Toggle from '@amzn/meridian/toggle';
import ViewController from '../../controllers/ViewController';
import viewStore from '../../stores/viewStore';

export interface GroupByAssignmentProps {}

class GroupByAssignmentComp extends React.Component<GroupByAssignmentProps> {
  constructor(props: GroupByAssignmentProps) {
    super(props);
  }
  render() {
    const { groupByAssignmentStatus } = viewStore;
    return (
      <Toggle
        checked={groupByAssignmentStatus}
        onChange={() => this.toggleChecked()}
        size="small"
      >
        Group by Assignment Status
      </Toggle>
    );
  }

  toggleChecked() {
    ViewController.toggleGroupByAssignmentStatus();
  }
}

export default observer(GroupByAssignmentComp);
