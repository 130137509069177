import { BehaviorSubject, Observable } from 'rxjs';
import {
  HTTP_PARAM_ORDER_IDS,
  HTTP_PARAM_ORDER_IDS_ALTERNATIVE,
  HTTP_PARAM_ROUTE_ID,
  HTTP_PARAM_SERVICE_AREA_ID,
  HTTP_PARAM_SITE_CODE,
} from '../util/UrlConstants';
import UrlController from 'controllers/UrlController';
import UrlHelper from '../util/UrlHelper';
import orderStore from '../stores/orderStore';
import siteStore from '../stores/siteStore';

class UrlReactor {
  private lastSiteCode: string;
  private lastServiceAreaId: string;
  private lastOrderIds: string;
  private lastRouteId: string;

  private siteCodeParameterChangeSubject = new BehaviorSubject<string>(
    undefined
  );
  siteCodeParameterChange$: Observable<string> =
    this.siteCodeParameterChangeSubject.asObservable();

  private serviceAreaIdParameterChangeSubject = new BehaviorSubject<string>(
    undefined
  );
  serviceAreaIdParameterChange$: Observable<string> =
    this.serviceAreaIdParameterChangeSubject.asObservable();

  private orderIdsParameterChangeSubject = new BehaviorSubject<string>(
    undefined
  );
  orderIdsParameterChange$: Observable<string> =
    this.orderIdsParameterChangeSubject.asObservable();

  private routeIdParameterChangeSubject = new BehaviorSubject<string>(
    undefined
  );
  routeIdParameterChange$: Observable<string> =
    this.routeIdParameterChangeSubject.asObservable();

  constructor() {
    setInterval(() => {
      const siteCode = UrlHelper.getParameterValue(HTTP_PARAM_SITE_CODE);
      if (siteCode !== this.lastSiteCode) {
        this.lastSiteCode = siteCode;
        this.siteCodeParameterChangeSubject.next(siteCode);
      }

      const serviceAreaId = UrlHelper.getParameterValue(
        HTTP_PARAM_SERVICE_AREA_ID
      );
      if (serviceAreaId !== this.lastServiceAreaId) {
        this.lastServiceAreaId = serviceAreaId;
        this.serviceAreaIdParameterChangeSubject.next(serviceAreaId);
      }

      // only emit the orderId or routeId events if the siteCode and serviceAreaId are not
      // equal to the currently selected site
      const { selectedSite } = siteStore;
      if (
        selectedSite &&
        (selectedSite.siteCode === siteCode ||
          selectedSite.serviceAreaId === serviceAreaId)
      ) {
        const orderIds =
          UrlHelper.getParameterValue(HTTP_PARAM_ORDER_IDS) ||
          UrlHelper.getParameterValue(HTTP_PARAM_ORDER_IDS_ALTERNATIVE);
        if (orderIds != this.lastOrderIds) {
          this.lastOrderIds = orderIds;
          this.orderIdsParameterChangeSubject.next(orderIds);
        }
        orderStore.setEnableTwoWayHighlightedOrderSync(true);
        const routeId = UrlHelper.getParameterValue(HTTP_PARAM_ROUTE_ID);
        if (routeId !== this.lastRouteId) {
          this.lastRouteId = routeId;
          this.routeIdParameterChangeSubject.next(routeId);
        }
      }
    }, 1000);

    // listen to the serviceAreaIdParameterChange observable and update the store state to change the selected site
    this.serviceAreaIdParameterChange$.subscribe(async (serviceAreaId) => {
      await UrlController.handleServiceAreaIdParameterChange(serviceAreaId);
    });

    // listen to the siteCodeParameterChange observable and update the store state to change the selected site
    this.siteCodeParameterChange$.subscribe(async (siteCode) => {
      await UrlController.handleSiteCodeParameterChange(siteCode);
    });

    // listen to the orderIdChange observable and set the order id search code
    this.orderIdsParameterChange$.subscribe(async (orderIds) => {
      if (orderIds) {
        await UrlController.handleOrderIdsParameterChange(orderIds);
      }
    });

    // listen to the routeIdChange observable and set the route id search code
    this.routeIdParameterChange$.subscribe(async (routeId) => {
      await UrlController.handleRouteIdParameterChange(routeId);
    });
  }
}

export default new UrlReactor();
