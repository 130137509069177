import { PREFIX_TRANSPORTER_NAME } from '../../util/FilterConstants';
import { observer } from 'mobx-react';
import Box from '@amzn/meridian/box';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import FilterHelper from 'util/FilterHelper';
import Icon from '@amzn/meridian/icon';
import React from 'react';
import Row from '@amzn/meridian/row';
import SearchField, { SearchSuggestion } from '@amzn/meridian/search-field';
import SearchTransporterByNameController from 'controllers/SearchTransporterByNameController';
import SearchTransporterByNameValueComp from './SearchTransporterByNameValueComp';
import Text from '@amzn/meridian/text';
import searchByTransporterNameStore from 'stores/searchByTransporterNameStore';
import transporterStore from '../../stores/transporterStore';
import trashTokens from '@amzn/meridian-tokens/base/icon/trash';

export interface SearchByNameProps {}

class SearchByNameComp extends React.Component<SearchByNameProps> {
  render() {
    const [searchSuggestions, searchKeys] = this.createSearchSuggestions();

    const placeHolder = 'Search by Transporter Name';
    const { searchByPartialStringInput, searchByNameValues } =
      searchByTransporterNameStore;
    const values = [];
    if (searchByNameValues.length > 1) {
      values.push(
        <Box
          backgroundColor="highlight"
          key="deleteAllSearchByNameValues"
          type="outline"
          spacingInset="none 200 none 200"
        >
          <Row spacing="100">
            <Text>Remove All</Text>
            <Button
              type="icon"
              size="small"
              onClick={() =>
                SearchTransporterByNameController.handleRemoveAll()
              }
            >
              <Icon tokens={trashTokens} />
            </Button>
          </Row>
        </Box>
      );
    }
    values.push(
      ...searchByNameValues.map((searchByNameValue) => (
        <SearchTransporterByNameValueComp
          key={searchByNameValue}
          value={searchByNameValue}
        />
      ))
    );

    return (
      <div className={'search-by-name'}>
        <Column spacing="200">
          <Row>
            <SearchField
              value={searchByPartialStringInput}
              width={'100%'}
              onChange={(value) =>
                SearchTransporterByNameController.handleSearchByPartialString(
                  value
                )
              }
              onSubmit={(value) => {
                if (searchKeys.length === 1) {
                  SearchTransporterByNameController.handleSearchByTransporterNameSelection(
                    searchKeys[0]
                  );
                }
              }}
              placeholder={`${placeHolder}...`}
              searchButton={true}
            >
              {searchSuggestions}
            </SearchField>
          </Row>
          <Row wrap="down" spacing="100">
            {values}
          </Row>
        </Column>
      </div>
    );
  }

  private createSearchSuggestions() {
    const { searchByPartialStringInput } = searchByTransporterNameStore;
    const searchSuggestions = [];
    const searchKeys: string[] = [];
    const transportersFilteredBySearchString =
      transporterStore.transporters.filter((transporter) => {
        return (
          transporter.name
            .toLowerCase()
            .indexOf(searchByPartialStringInput.toLowerCase()) > -1
        );
      });
    transportersFilteredBySearchString.forEach((transporter) => {
      const filterKey = FilterHelper.makeFilterString(
        PREFIX_TRANSPORTER_NAME,
        transporter.name
      );
      searchSuggestions.push(this.createSearchSuggestion(filterKey));
      searchKeys.push(filterKey);
    });
    return [searchSuggestions, searchKeys];
  }

  private createSearchSuggestion(code: string) {
    return (
      <SearchSuggestion
        key={`searchByName${code}`}
        onClick={() =>
          SearchTransporterByNameController.handleSearchByTransporterNameSelection(
            code
          )
        }
      >
        {code}
      </SearchSuggestion>
    );
  }
}

export default observer(SearchByNameComp);
