import { Site } from '@amzn/gsf-dispatcher-schema';
import SiteWarmingService from '../services/SiteWarmingService';

export const PING_INTERVAL = 60 * 1000;
export default class SiteWarmingController {
  private static currentSite: Site;
  private static currentPingInterval;

  /**
   * When the selected site changes any current site ping interval should be
   * stopped.  If the new selected site is not undefined which means the user
   * is authorized for the site, start a new ping interval.
   * @param selectedSite which may be undefined if we are not authorized for a site
   */
  static async handleSiteChange(selectedSite: Site) {
    SiteWarmingController.shutDownCurrentPingInterval(selectedSite);
    await SiteWarmingController.startUpNewPingInterval(selectedSite);
  }

  private static shutDownCurrentPingInterval(selectedSite: Site) {
    if (
      SiteWarmingController.didSiteChange(selectedSite) &&
      SiteWarmingController.currentPingInterval
    ) {
      clearInterval(SiteWarmingController.currentPingInterval);
      delete SiteWarmingController.currentPingInterval;
    }
  }

  private static async startUpNewPingInterval(selectedSite: Site) {
    if (SiteWarmingController.didSiteChange(selectedSite)) {
      if (selectedSite) {
        const { siteCode } = selectedSite;
        await SiteWarmingService.warmSite(siteCode);
        SiteWarmingController.currentPingInterval = setInterval(async () => {
          await SiteWarmingService.warmSite(siteCode);
        }, PING_INTERVAL);
        SiteWarmingController.currentSite = selectedSite;
      } else {
        delete SiteWarmingController.currentSite;
      }
    }
  }

  private static didSiteChange(selectedSite: Site): boolean {
    const newSiteCode = selectedSite?.siteCode;
    const existingSiteCode = SiteWarmingController.currentSite?.siteCode;
    return newSiteCode !== existingSiteCode;
  }
}
