import { observer } from 'mobx-react';
import AssignmentController from '../../controllers/AssignmentController';
import Button from '@amzn/meridian/button';
import Modal, { ModalFooter } from '@amzn/meridian/modal';
import React from 'react';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import assignmentStore from '../../stores/assignmentStore';

export interface AssignmentConfirmDialogProps {}

class AssignmentConfirmDialogComp extends React.Component<AssignmentConfirmDialogProps> {
  render() {
    const { confirmMessages } = assignmentStore;
    const messageComps = confirmMessages.map((m, index) => {
      return <li key={`assignment-confirm-msg-${index + 1}`}>{m}</li>;
    });
    return (
      <Modal
        title={`Assignment Confirmation`}
        open={confirmMessages.length > 0}
        scrollContainer="viewport"
      >
        <Text tag="ul">{messageComps}</Text>
        <ModalFooter>
          <Row alignmentHorizontal="end" widths="fit">
            <Button
              type="secondary"
              size="small"
              onClick={() => AssignmentController.cancelAssignment()}
              propagateClickEvent={false}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              size="small"
              onClick={() => AssignmentController.confirmAssignment()}
              propagateClickEvent={false}
            >
              Confirm
            </Button>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}

export default observer(AssignmentConfirmDialogComp);
