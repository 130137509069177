import { CUSTOM_SENDER } from '@amzn/katal-metrics-driver-aws-services';
import {
  LOG_ATTRIBUTE_USER_AGENT,
  LOG_METRICS,
  LOG_SITE_NAME,
} from './LogConstants';
import KatalMetricsContext from '@amzn/katal-metrics/lib/KatalMetricsContext';
import KatalMetricsPublisher from '@amzn/katal-metrics/lib/KatalMetricsPublisher';
import katalDriver from './katalDriver';

const metricsContext = new KatalMetricsContext.Builder()
  .withServiceName(CUSTOM_SENDER)
  .withSite(LOG_SITE_NAME)
  .build();

const rootPublisher = new KatalMetricsPublisher(
  katalDriver,
  undefined,
  metricsContext
);

export const metricsPublisher =
  rootPublisher.newChildActionPublisherForMethod(LOG_METRICS);
metricsPublisher.publishString(
  LOG_ATTRIBUTE_USER_AGENT,
  window.navigator.userAgent.toLowerCase()
);
