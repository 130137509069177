import * as _ from 'lodash';
export default class StateCopier {
  static copyState(source: object, destination: object): string[] {
    const attributeNames = _.union(_.keys(source), _.keys(destination));
    const changedAttributeNames: string[] = [];
    attributeNames.forEach((attributeName) => {
      const sourceValue = source[attributeName];
      const destinationValue = destination[attributeName];
      if (!_.isEqual(sourceValue, destinationValue)) {
        destination[attributeName] = sourceValue;
        changedAttributeNames.push(attributeName);
      }
    });
    return changedAttributeNames;
  }
}
