import { API } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import AuthHelper from '../auth/AuthHelper';
import Log from '../logging/Log';
import Performance from '../logging/Performance';
import Subscription from './Subscription';
import siteStore from '../stores/siteStore';

export default class Service {
  /**
   * If the user is not authorized or if a general error has occurred, the service query
   * will set the store state and return undefined
   * @param query
   * @param variables
   */
  static async query<T>(
    query: any,
    variables: any,
    logPerformance = false
  ): Promise<T> {
    const operationName: string = Service.getOperationName(query);
    try {
      const startTime = Date.now();
      const response = (await API.graphql({
        query,
        variables,
        authToken: await AuthHelper.getJwt(),
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as GraphQLResult;
      if (logPerformance) {
        Performance.log(operationName, startTime);
      }
      return response.data[operationName];
    } catch (e) {
      if (!Service.isUnauthorized(e)) {
        // wasn't an unauthorized problem, so handle general backend error
        Log.error(
          `Service error: ${e['message'] ? e['message'] : JSON.stringify(e)}`,
          operationName
        );
        throw e;
      }
      return undefined;
    }
  }

  static subscribe<T>(
    query: any,
    variables: any,
    entityReceiver: (T) => void
  ): Subscription<T> {
    return new Subscription<T>(query, variables, entityReceiver);
  }

  static getOperationName(document: any): string {
    const operation = document.definitions.filter(
      (d) => d.kind === 'OperationDefinition'
    )[0];
    return operation.name.value;
  }

  static isUnauthorized(response: any) {
    if (
      response['errors'] &&
      response['errors'] &&
      response['errors'][0] &&
      response['errors'][0]['errorType'] === 'Unauthorized'
    ) {
      siteStore.setUnauthorizedSite();
      return true;
    } else {
      return false;
    }
  }
}
