import { Order } from '@amzn/gsf-dispatcher-schema';
import AssignmentHelper from '../util/AssignmentHelper';
import OrderHelper from '../util/OrderHelper';
import orderStore from '../stores/orderStore';
import routeStore from '../stores/routeStore';

export default class OrderController {
  static orderClicked(order: Order, mouseEvent: React.MouseEvent<HTMLElement>) {
    mouseEvent.stopPropagation();
    const orderId = order.orderId;
    if (!AssignmentHelper.isSsdSite()) {
      if (orderStore.isOrderIdSelected(orderId)) {
        orderStore.removeSelectedOrderId(orderId);
      } else {
        orderStore.addSelectedOrderId(orderId);
      }
    }
  }

  static clearAllOrders() {
    orderStore.setSelectedOrderIds([]);
    routeStore.setSelectedRouteId(undefined);
  }

  static clearSelectedOrderIds(orderIds: string[]) {
    orderStore.removeSelectedOrderIds(orderIds);
  }

  static addSelectedOrderIds(orderIds: string[]) {
    orderStore.addSelectedOrderIds(orderIds);
  }

  static handleDestroyOrder(order: Order): void {
    orderStore.removeOrder(order);
  }

  static handleUpdateOrder(order: Order): void {
    OrderController.fixOrderData(order);
    if (order.orderId) {
      orderStore.updateOrder(order);
    }
  }

  /**
   * If an order can't be fixed, then set its orderId to undefined which will cause
   * it to be filtered out
   * @param order
   */
  static fixOrderData(order: Order): void {
    if (!OrderHelper.orderHasValidData(order)) {
      order.orderId = undefined;
    }
  }
}
