export const HTTP_PARAM_SITE_CODE = 'siteCode';

export const HTTP_PARAM_SERVICE_AREA_ID = 'serviceAreaId';
export const HTTP_PARAM_ORDER_IDS = 'orderIds';

export const HTTP_PARAM_ORDER_IDS_ALTERNATIVE = 'orderIDs';

export const HTTP_PARAMETER_REMOVE_LIST = [
  'showExceptionView',
  HTTP_PARAM_ORDER_IDS_ALTERNATIVE,
];
export const HTTP_PARAM_ROUTE_ID = 'routeId';

export const ENVIRONMENT_DEV = 'dev';
export const ENVIRONMENT_PROD = 'prod';
export const ENVIRONMENT_GAMMA = 'gamma';
export const ENVIRONMENT_BETA = 'beta';
