import searchByIdStore from '../stores/searchByIdStore';

export default class SearchByIdController {
  static handleSearchByIdSelection(searchById: string) {
    if (searchById.trim() !== '') {
      searchByIdStore.addSearchByIdValue(searchById);
      searchByIdStore.setSearchByPartialStringInput('');
    }
  }

  static handleMultipleSearchByIdSelection(searchByIds: string[]) {
    searchByIdStore.setSearchByIdValues(searchByIds);
  }

  static handleSearchByPartialString(partialString: string) {
    searchByIdStore.setSearchByPartialStringInput(partialString);
  }

  static handleRemoveSearchByIdValue(searchById: string) {
    searchByIdStore.removeSearchByIdValue(searchById);
  }

  static handleRemoveAll() {
    searchByIdStore.clearSearchByIdValues();
  }
}
