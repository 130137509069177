import App from './App';
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

/**
 * It is very important not to wrap the <App /> in a React.StrictMode wrapper since this causes a known bad side effect
 * where the react component's componentDidMount method may get null HTMLElements returned by document.findElementById
 * even though they should exist according to the react component lifecycle documentation.  It is sometimes needed
 * to utilize libraries which operate directly on the DOM such as open layers mapping overlays.  Using the React.Strict
 * wrapper will result in hours of wasted time trying to debug the react app which is far worse than any benefit it
 * may offer, so do NOT do it.
 *
 * DO NOT DO THE BELOW CODE!!!!!
 * root.render(
 *   <React.StrictMode>
 *     <App />
 *   </React.StrictMode>
 * );
 */
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
