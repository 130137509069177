import * as _ from 'lodash';
import UILogService from '../services/UILogService';

let retryData: any[] = [];

export const senderFunction = async (
  namespace: string,
  data: any[]
): Promise<void> => {
  const dataToSend: any[] = [];
  dataToSend.push(...retryData);
  retryData = [];
  dataToSend.push(...data);
  const dataBySiteCode = _.groupBy(dataToSend, (d) => d.siteCode);
  const siteCodes = _.keys(dataBySiteCode).filter((v) => v !== 'undefined');
  for (const siteCode of siteCodes) {
    const siteData = dataBySiteCode[siteCode];
    try {
      const logsForSite = siteData.map((d) => JSON.stringify(d));
      await UILogService.sendLogs(siteCode, [
        JSON.stringify('SEV2 DISABLE LOGS'),
      ]);
    } catch (e) {
      retryData.push(...siteData);
    }
  }
};
