import './MapTransporterStopComp.css';
import {
  Order,
  TransporterStop,
  TransporterStopType,
} from '@amzn/gsf-dispatcher-schema';
import { observer } from 'mobx-react';
import MapLocatableEntityComp from '../common/MapLocatableEntityComp';
import React from 'react';
import deliveryImage from '../../../assets/images/dropoff.svg';
import pickupImage from '../../../assets/images/pickup.svg';

interface MapTransporterStopProps {
  stop: TransporterStop;

  order: Order;
}

interface MapTransporterStopState {}

class MapTransporterStopComp extends MapLocatableEntityComp<
  MapTransporterStopProps,
  MapTransporterStopState
> {
  constructor(props: MapTransporterStopProps) {
    const { stop } = props;
    super(
      props,
      {},
      'order',
      stop.stopType === TransporterStopType.Pickup
        ? 'pickupLocation'
        : 'deliveryLocation'
    );
  }

  render() {
    const { stop } = this.props;

    return (
      // it is important to wrap the jsx with a parent div in order to prevent
      // a react error when restarting the development server
      <div>
        <img
          id={this.getId()}
          className="transporter-stop"
          src={
            stop.stopType === TransporterStopType.Pickup
              ? pickupImage
              : deliveryImage
          }
        />
      </div>
    );
  }
}

export default observer(MapTransporterStopComp);
