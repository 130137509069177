import * as _ from 'lodash';
import {
  ASSIGNMENT_ASSIGNED,
  ASSIGNMENT_UNASSIGNED,
  PREFIX_ASSIGNMENT,
  PREFIX_DELIVERY_WINDOW,
  PREFIX_ORDER_ID,
  PREFIX_PICKUP_ADDRESS,
  PREFIX_ROUTE_ID,
  PREFIX_ROUTE_LOCK,
  ROUTE_LOCK_LOCKED,
  ROUTE_STORE_STATUS_ROUTE_LOCKED,
} from '../util/FilterConstants';
import { Route } from '@amzn/gsf-dispatcher-schema';
import AddressHelper from '../util/AddressHelper';
import FilterHelper, {
  EvaluatorFunctionMap,
  FilterEvaluator,
} from '../util/FilterHelper';
import OrderHelper from '../util/OrderHelper';
import RouteHelper from '../util/RouteHelper';
import TimeHelper from '../util/TimeHelper';
import filterStore from '../stores/filterStore';

export default class RouteFilterController {
  static filterRoutes(allRoutes: Route[]): Route[] {
    return allRoutes.filter((r) =>
      RouteFilterController.doesRoutePassFilter(r)
    );
  }

  private static doesRoutePassFilter(route: Route): boolean {
    const { selectedOrderFilterCodes } = filterStore;

    const evaluators: (FilterEvaluator<Route> | EvaluatorFunctionMap)[] = [];

    const routeOrOrderEvaluator: EvaluatorFunctionMap = new Map();
    routeOrOrderEvaluator.set(
      PREFIX_ROUTE_ID,
      (route: Route, codeValue: string) => {
        return codeValue === route.routeId;
      }
    );
    routeOrOrderEvaluator.set(
      PREFIX_ORDER_ID,
      (route: Route, codeValue: string) => {
        return _.includes(
          route.orders.map((o) => o.orderId),
          codeValue
        );
      }
    );

    // route id filter
    evaluators.push(routeOrOrderEvaluator);

    // assignment filter
    evaluators.push({
      prefix: PREFIX_ASSIGNMENT,
      evaluatorFunction: (route: Route, codeValue: string) => {
        const isRouteAssigned = !!route.transporter?.transporterId;
        return (
          (codeValue === ASSIGNMENT_ASSIGNED && isRouteAssigned) ||
          (codeValue === ASSIGNMENT_UNASSIGNED && !isRouteAssigned)
        );
      },
    });

    // route lock filter
    evaluators.push({
      prefix: PREFIX_ROUTE_LOCK,
      evaluatorFunction: (route: Route, codeValue: string) => {
        const routeStatuses = route.routeStatuses || [];
        const isRouteLocked = _.includes(
          routeStatuses,
          ROUTE_STORE_STATUS_ROUTE_LOCKED
        );
        return codeValue === ROUTE_LOCK_LOCKED && isRouteLocked;
      },
    });

    // pickup address filter
    evaluators.push({
      prefix: PREFIX_PICKUP_ADDRESS,
      evaluatorFunction: (route: Route, codeValue: string) => {
        const orders = RouteHelper.getOrders([route]);
        const pickupAddresses = OrderHelper.uniquePickupAddresses(orders).map(
          (pickupAddress) => AddressHelper.getDisplayAddress(pickupAddress)
        );
        return _.includes(pickupAddresses, codeValue);
      },
    });

    // delivery window filter
    evaluators.push({
      prefix: PREFIX_DELIVERY_WINDOW,
      evaluatorFunction: (route: Route, codeValue: string) => {
        const orders = RouteHelper.getOrders([route]);
        const deliveryWindows = OrderHelper.uniqueDeliveryWindows(orders).map(
          (deliveryWindow) => TimeHelper.timeWindowAsString(deliveryWindow)
        );
        return _.includes(deliveryWindows, codeValue);
      },
    });

    return FilterHelper.passAllFilterGroups(
      route,
      selectedOrderFilterCodes,
      evaluators
    );
  }
}
