import * as _ from 'lodash';
import { Route } from '@amzn/gsf-dispatcher-schema';
import OrderHelper from '../util/OrderHelper';
import RouteHelper from '../util/RouteHelper';

export default class RouteSortController {
  static sortRoutes(unsorted: Route[]): Route[] {
    // sort by earliest delivery start time, then by route length desc (longer routes first), then by route name asc
    const sortedArray = _.clone(unsorted);

    return sortedArray.sort((r1, r2) => {
      const r1EarliestDeliveryWindowStartTime = Number(
        OrderHelper.getEarliestOrderDeliveryStartTime(
          RouteHelper.getOrders([r1])
        )
      );
      const r2EarliestDeliveryWindowStartTime = Number(
        OrderHelper.getEarliestOrderDeliveryStartTime(
          RouteHelper.getOrders([r2])
        )
      );
      // sort by earliest order delivery end time
      if (
        r1EarliestDeliveryWindowStartTime !== r2EarliestDeliveryWindowStartTime
      ) {
        return (
          r1EarliestDeliveryWindowStartTime - r2EarliestDeliveryWindowStartTime
        );
      }

      const r1Length = r1.plannedDurationSeconds;
      const r2Length = r2.plannedDurationSeconds;

      if (r1Length !== r2Length) {
        // sort by route length desc
        return r2Length - r1Length;
      }

      // sort by route name asc
      const r1Name = r1.routeName || '';
      const r2Name = r2.routeName || '';
      return r1Name.localeCompare(r2Name);
    });
  }
}
