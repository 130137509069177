import * as _ from 'lodash';
import {
  Address,
  Order,
  TimeWindow,
  WeightUnit,
} from '@amzn/gsf-dispatcher-schema';
import { HEAVY_ORDER_KG, HEAVY_ORDER_LBS } from './UnitConstants';
import { OrderStatus } from '../graphqlGenerated/graphql';
import AssignmentHelper from './AssignmentHelper';
import RouteHelper from './RouteHelper';
import TimeHelper, { MINUTE } from './TimeHelper';
import orderStore from '../stores/orderStore';
import siteStore from '../stores/siteStore';

export default class OrderHelper {
  static isOrderAssigned(order: Order): boolean {
    return !!order.transporter?.name;
  }

  static isOrderPickedUp(order: Order): boolean {
    return order.orderStatus == OrderStatus.OutForDelivery;
  }

  static orderHasValidData(o: Order): boolean {
    return (
      !!o.pickupLocation &&
      !!o.pickupLocation?.latitude &&
      !!o.pickupLocation?.longitude &&
      !!o.deliveryLocation &&
      !!o.deliveryLocation?.latitude &&
      !!o.deliveryLocation?.longitude
    );
  }

  static isOrderOrRouteAssigned(order: Order) {
    const isOrderAssigned = OrderHelper.isOrderAssigned(order);
    if (isOrderAssigned) {
      return true;
    }
    const { routeId } = order;
    if (routeId) {
      const route = RouteHelper.findRouteById(routeId);
      if (route) {
        return RouteHelper.isRouteAssigned(route);
      }
    }
    return false;
  }

  static isOrderOrRoutePickedUp(order: Order) {
    const isOrderPicked = OrderHelper.isOrderPickedUp(order);
    if (isOrderPicked) {
      return true;
    }
    const { routeId } = order;
    if (!!routeId) {
      const route = RouteHelper.findRouteById(routeId);
      if (route) {
        return RouteHelper.isRouteAssigned(route);
      }
    }
    return false;
  }

  static findOrderById(orderId: string): Order {
    const { orders } = orderStore;
    return orders.find((o) => o.orderId === orderId);
  }
  static uniquePickupAddresses(orders: Order[]): Address[] {
    const allPickupAddresses = orders.map((o) => o.pickupAddress);
    return _.uniqBy(allPickupAddresses, (a) => a.address1);
  }

  static is3rdPartyPickupAddress(address: Address): boolean {
    const { selectedSite } = siteStore;
    return address?.address1 !== selectedSite.pickupAddress?.address1;
  }

  static unique3rdPartyPickupAddresses(orders: Order[]): Address[] {
    const { selectedSite } = siteStore;
    return OrderHelper.uniquePickupAddresses(orders).filter((address) =>
      OrderHelper.is3rdPartyPickupAddress(address)
    );
  }

  static uniqueDeliveryWindows(orders: Order[]): TimeWindow[] {
    const allDeliveryWindows = orders.map((o) => o.deliveryWindow);
    return _.sortBy(
      _.uniqBy(allDeliveryWindows, (a) => `${a.startDate}:${a.endDate}`),
      ['startDate', 'endDate']
    );
  }

  static getPickupWindowDisplayValue(order: Order): string {
    const pickupWindow = TimeHelper.timeWindowAsString(order.pickupWindow);
    return `Pickup Window: ${pickupWindow}`;
  }

  static getDeliveryWindowDisplayValue(order: Order): string {
    const deliveryWindow = TimeHelper.timeWindowAsString(order.deliveryWindow);
    return `Delivery Window: ${deliveryWindow}`;
  }

  static isOrderHeavy(order: Order): boolean {
    const weightUnit = order.weightUnit;
    switch (weightUnit) {
      case WeightUnit.Kilograms: {
        return order.weight > HEAVY_ORDER_KG;
      }
      default:
        return order.weight > HEAVY_ORDER_LBS;
    }
  }

  static isOneHourDeliveryWindow(order: Order): boolean {
    return TimeHelper.isTimeSpan(order.deliveryWindow, { hours: 1 });
  }

  static getEarliestOrderDeliveryEndTime(orders: Order[]): string {
    return OrderHelper.getEarliestTime(
      orders,
      (order) => order.deliveryWindow?.endDate
    );
  }

  static getEarliestOrderDeliveryStartTime(orders: Order[]): string {
    return OrderHelper.getEarliestTime(
      orders,
      (order) => order.deliveryWindow?.startDate
    );
  }

  static getEarliestOrderPickupStartTime(orders: Order[]): string {
    return OrderHelper.getEarliestTime(
      orders,
      (order) => order.pickupWindow?.startDate
    );
  }

  static getLatestOrderDeliveryEndTime(orders: Order[]): string {
    return OrderHelper.getLatestTime(
      orders,
      (order) => order.deliveryWindow?.endDate
    );
  }

  static getLatestOrderPickupEndTime(orders: Order[]): string {
    return OrderHelper.getLatestTime(
      orders,
      (order) => order.pickupWindow?.endDate
    );
  }

  static calculateRemainingMinutesNeededForSelectedOrders(now: number) {
    const { selectedOrderIds } = orderStore;
    let maxTimeInMillis = Number.MIN_VALUE;
    selectedOrderIds.forEach((orderId) => {
      const order = AssignmentHelper.findOrderById(orderId);
      const deliveryWindowEndTime = Number(
        order?.deliveryWindow?.endDate || '0'
      );
      if (deliveryWindowEndTime > maxTimeInMillis) {
        maxTimeInMillis = deliveryWindowEndTime;
      }
    });
    if (maxTimeInMillis === Number.MIN_VALUE) {
      return 0;
    }
    return (maxTimeInMillis - now) / MINUTE;
  }

  private static getLatestTime(orders: Order[], timePicker: (Order) => string) {
    let latestTime = Number.MIN_VALUE;
    (orders || []).forEach((order) => {
      const time = timePicker(order);
      if (time) {
        const timeMillisecSinceEpoch = Number(time);
        if (timeMillisecSinceEpoch > latestTime) {
          latestTime = timeMillisecSinceEpoch;
        }
      }
    });
    if (latestTime === Number.MIN_VALUE) {
      return '';
    }
    return latestTime.toString();
  }

  private static getEarliestTime(
    orders: Order[],
    timePicker: (Order) => string
  ) {
    let earliestTime = Number.MAX_VALUE;
    (orders || []).forEach((order) => {
      const time = timePicker(order);
      if (time) {
        const timeMillisecSinceEpoch = Number(time);
        if (timeMillisecSinceEpoch < earliestTime) {
          earliestTime = timeMillisecSinceEpoch;
        }
      }
    });
    if (earliestTime === Number.MAX_VALUE) {
      return '';
    }
    return earliestTime.toString();
  }
}
