import * as _ from 'lodash';
import { action, makeObservable, observable } from 'mobx';

class SearchByTransporterNameStore {
  searchByPartialStringInput: string = '';
  searchByNameValues: string[] = [];

  constructor() {
    makeObservable(this, {
      searchByPartialStringInput: observable,
      searchByNameValues: observable,
      setSearchByPartialStringInput: action,
      setSearchByNameValues: action,
      addSearchByNameValue: action,
      removeSearchByNameValue: action,
      clearSearchByNameValues: action,
    });
  }

  setSearchByPartialStringInput(searchByPartialStringInput: string) {
    this.searchByPartialStringInput = searchByPartialStringInput;
  }

  setSearchByNameValues(searchByNameValues: string[]) {
    this.searchByNameValues = searchByNameValues;
  }

  addSearchByNameValue(searchByNameValue: string) {
    const { searchByNameValues } = this;
    if (!_.includes(searchByNameValues, searchByNameValue)) {
      const newSearchByNameValues = _.clone(searchByNameValues);
      newSearchByNameValues.push(searchByNameValue);
      this.searchByNameValues = newSearchByNameValues;
    }
  }

  removeSearchByNameValue(searchByNameValue: string) {
    const index = this.searchByNameValues.indexOf(searchByNameValue);
    if (index > -1) {
      const newSearchByNameValues = _.clone(this.searchByNameValues);
      newSearchByNameValues.splice(index, 1);
      this.searchByNameValues = newSearchByNameValues;
    }
  }

  clearSearchByNameValues() {
    this.searchByNameValues = [];
  }
}

export default new SearchByTransporterNameStore();
