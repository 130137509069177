import { Transporter } from '@amzn/gsf-dispatcher-schema';
import { observer } from 'mobx-react';
import Column from '@amzn/meridian/column';
import React from 'react';
import Row from '@amzn/meridian/row';
import StringHelper from 'util/StringHelper';
import Text from '@amzn/meridian/text';
import TransporterComp from './TransporterComp';
import transporterStore from '../../stores/transporterStore';

export interface TransporterListProps {
  transporters: Transporter[];
}

class TransporterListComp extends React.Component<TransporterListProps> {
  render() {
    const { transporters } = this.props;
    const allTransporters = transporterStore.transporters;
    const transporterComps = transporters.map((t, index) => (
      <TransporterComp
        key={`transporter-comp${t.transporterId}`}
        transporter={t}
      />
    ));
    return (
      <Column overflowY="auto">
        <Row spacingInset="100" alignmentHorizontal="center" spacing="300">
          <Text type="h300">
            {StringHelper.makeCountOfCountDisplay(
              transporters.length,
              allTransporters.length,
              'Transporter'
            )}
          </Text>
        </Row>
        <Column spacing="300">{transporterComps}</Column>
      </Column>
    );
  }
}

export default observer(TransporterListComp);
