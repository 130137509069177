import { BehaviorSubject, Observable } from 'rxjs';
import { reaction } from 'mobx';
import searchByIdStore from '../stores/searchByIdStore';

class SearchByIdReactor {
  private searchByIdValuesChangeSubject = new BehaviorSubject<boolean>(false);
  searchByIdValuesChange$: Observable<boolean> =
    this.searchByIdValuesChangeSubject.asObservable();

  constructor() {
    reaction(
      () => {
        return searchByIdStore.searchByIdValues;
      },
      (searchByIdValues: string[]) => {
        this.searchByIdValuesChangeSubject.next(true);
      }
    );
  }
}

export default new SearchByIdReactor();
