import * as _ from 'lodash';
import { Order, WeightUnit } from '@amzn/gsf-dispatcher-schema';
import {
  POUNDS_PER_KILOGRAM,
  UnitSystem,
  countryCodesUsingImperialUnits,
} from './UnitConstants';
import siteStore from 'stores/siteStore';

export default class UnitHelper {
  static convertMetersToDrivingUnit(distanceInMeters: number): string {
    const system = UnitHelper.getSystem();
    switch (system) {
      case UnitSystem.METRIC:
        return `${UnitHelper.round(distanceInMeters * 0.001, 2)} km`;
      default:
        return `${UnitHelper.round(distanceInMeters * 0.000621371, 2)} mi`;
    }
  }

  static getOrderWeight(order: Order): string {
    if (order.weight) {
      const system = UnitHelper.getSystem();
      return `${UnitHelper.round(
        UnitHelper.convertWeight(system, order),
        0
      )}${UnitHelper.getOrderWeightUnit(system)}`;
    }
    return '';
  }

  static getOrdersWeight(orders: Order[]): string {
    const system = UnitHelper.getSystem();
    let totalWeight = 0;
    orders.forEach((o) => {
      totalWeight += UnitHelper.convertWeight(system, o);
    });

    const orderWeightUnit = UnitHelper.getOrderWeightUnit(system);

    return `${UnitHelper.round(totalWeight, 0)}${orderWeightUnit}`;
  }

  private static getOrderWeightUnit(system: UnitSystem): string {
    switch (system) {
      case UnitSystem.IMPERIAL:
        return ' lbs';
      case UnitSystem.METRIC: {
        return ' kg';
      }
    }
  }

  private static round(value: number, numberDigits: number): string {
    return value.toFixed(numberDigits);
  }

  private static getSystem(): UnitSystem {
    const { selectedSite } = siteStore;
    if (
      _.includes(
        countryCodesUsingImperialUnits,
        selectedSite?.pickupAddress?.countryCode
      )
    ) {
      return UnitSystem.IMPERIAL;
    }
    return UnitSystem.METRIC;
  }

  private static convertWeight(system: UnitSystem, order: Order): number {
    const weight = order?.weight;
    if (weight) {
      switch (system) {
        case UnitSystem.IMPERIAL:
          if (order.weightUnit === WeightUnit.Kilograms) {
            return weight * POUNDS_PER_KILOGRAM;
          } else {
            return weight;
          }
        case UnitSystem.METRIC:
          if (order.weightUnit === WeightUnit.Kilograms) {
            return weight;
          } else {
            return weight / POUNDS_PER_KILOGRAM;
          }
      }
    }
    return 0;
  }
}
