import { observer } from 'mobx-react';
import MapOrderGroupComp from './MapOrderGroupComp';
import OrderGroup from '../../../map/model/OrderGroup';
import React from 'react';

interface MapOrdersProps {
  orderGroups: OrderGroup[];
}

class MapOrdersComp extends React.Component<MapOrdersProps> {
  render() {
    const { orderGroups } = this.props;

    return orderGroups.map((og) => {
      const key = `map-order-group${og.entities
        .map((o) => o.orderId)
        .join('~')}}`;
      return <MapOrderGroupComp key={key} orderGroup={og} />;
    });
  }
}

export default observer(MapOrdersComp);
