import * as _ from 'lodash';
import { Transporter } from '@amzn/gsf-dispatcher-schema';
import transporterStore from '../stores/transporterStore';

export default class TransporterSortController {
  static sortTransporters(unsorted: Transporter[]): Transporter[] {
    // sort with selected transporter at top and then by transporter rank
    const { transporterRank } = transporterStore;
    const { selectedTransporterId } = transporterStore;
    return _.sortBy(unsorted, (t) => {
      if (t.transporterId === selectedTransporterId) {
        return -1000;
      } else {
        return transporterRank.get(t.transporterId) || Number.MAX_VALUE;
      }
    });
  }
}
