import {
  GetTransportersForSiteDocument,
  GetTransportersForSiteQueryVariables,
  SubscribeToCreateTransporterDocument,
  SubscribeToDestroyTransporterDocument,
  SubscribeToUpdateTransporterDocument,
  TransportersPage,
} from '../graphqlGenerated/graphql';
import { Transporter } from '@amzn/gsf-dispatcher-schema';
import Performance from '../logging/Performance';
import Service from './Service';
import Subscription from './Subscription';

export const TRANSPORTER_QUERY_LIMIT = 250;

export default class TransporterService {
  static async getTransportersForSite(
    siteCode: string
  ): Promise<Transporter[]> {
    const operationName = 'getTransportersForSite';
    const startTime = Date.now();
    const transporters: Transporter[] = [];
    let nextToken = undefined;
    do {
      const variables: GetTransportersForSiteQueryVariables = {
        limit: TRANSPORTER_QUERY_LIMIT,
        nextToken,
        siteCode,
      };
      const transportersPage: TransportersPage = await Service.query(
        GetTransportersForSiteDocument,
        variables
      );
      const transportersForPage = transportersPage.transporters;
      if (transportersForPage) {
        transporters.push(...transportersForPage);
      }
      nextToken = transportersPage.nextToken;
    } while (nextToken);
    Performance.log(operationName, startTime);
    return transporters;
  }

  static subscribeToCreateTransporter(
    siteCode: string,
    entityReceiver: (Transporter) => void
  ): Subscription<Transporter> {
    return Service.subscribe(
      SubscribeToCreateTransporterDocument,
      { siteCode },
      entityReceiver
    );
  }

  static subscribeToDestroyTransporter(
    siteCode: string,
    entityReceiver: (Transporter) => void
  ): Subscription<Transporter> {
    return Service.subscribe(
      SubscribeToDestroyTransporterDocument,
      { siteCode },
      entityReceiver
    );
  }

  static subscribeToUpdateTransporter(
    siteCode: string,
    entityReceiver: (Transporter) => void
  ): Subscription<Transporter> {
    return Service.subscribe(
      SubscribeToUpdateTransporterDocument,
      { siteCode },
      entityReceiver
    );
  }
}
