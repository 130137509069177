import * as _ from 'lodash';
import {
  DELIVERY_WINDOW_END_TIME,
  PICKUP_WINDOW_END_TIME,
} from '../util/SortConstants';
import { Order } from '@amzn/gsf-dispatcher-schema';
import orderStore from '../stores/orderStore';
import sortStore from '../stores/sortStore';

export default class OrderSortController {
  static sortOrders(unsorted: Order[]): Order[] {
    const { selectedSortCode } = sortStore;

    switch (selectedSortCode) {
      case DELIVERY_WINDOW_END_TIME:
        return OrderSortController.sort(unsorted, (o) => {
          return o.deliveryWindow?.endDate
            ? Number(o.deliveryWindow.endDate)
            : Number.MAX_VALUE;
        });
      case PICKUP_WINDOW_END_TIME:
        return OrderSortController.sort(unsorted, (o) => {
          return o.pickupWindow?.endDate
            ? Number(o.pickupWindow.endDate)
            : Number.MAX_VALUE;
        });
      default:
        return OrderSortController.sort(unsorted, (o) => {
          return 1; // equal weight for all orders, but still put priority on selected orders
        });
    }
  }

  private static sort(
    unsortedOrders: Order[],
    orderValueProvider: (Order) => number
  ): Order[] {
    const { selectedOrderIds } = orderStore;
    // create two groups, the selected orders and the unselected orders
    const selectedOrders = _.sortBy(
      unsortedOrders.filter((o) => _.includes(selectedOrderIds, o.orderId)),
      (o) => orderValueProvider(o)
    );
    const unselectedOrders = _.sortBy(
      unsortedOrders.filter((o) => !_.includes(selectedOrderIds, o.orderId)),
      (o) => orderValueProvider(o)
    );
    return [...selectedOrders, ...unselectedOrders];
  }
}
