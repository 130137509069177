import {
  MutationWarmSiteArgs,
  WarmSiteDocument,
} from '../graphqlGenerated/graphql';
import Service from './Service';

export default class SiteWarmingService {
  static async warmSite(siteCode: string): Promise<boolean> {
    const variables: MutationWarmSiteArgs = {
      siteCode,
    };
    return Service.query(WarmSiteDocument, variables, true);
  }
}
