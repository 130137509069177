import { action, makeObservable, observable } from 'mobx';

class OperationStore {
  operationName: string;

  constructor() {
    makeObservable(this, {
      operationName: observable,
      setOperationName: action,
      clearOperationName: action,
    });
  }

  setOperationName(operationName: string) {
    this.operationName = operationName;
  }

  clearOperationName() {
    this.operationName = undefined;
  }
}

export default new OperationStore();
