import { AmazonLocationServiceMapStyle } from '@aws-amplify/geo';
import { AmplifyMapLibreRequest } from 'maplibre-gl-js-amplify';
import { Auth, Geo } from 'aws-amplify';
import { BehaviorSubject, Observable } from 'rxjs';
import { Coordinate } from 'ol/coordinate';
import {
  DEFAULT_MAP_CENTER,
  MAP_DEFAULT_RESOLUTION,
  PROJECTION_MAP,
} from './MapConstants';
import { Feature, Map, Overlay, View } from 'ol';
import { Geometry, LineString, Point } from 'ol/geom';
import { ScaleLine, defaults as defaultControls } from 'ol/control';
import { Source } from 'ol/source';
import { easeOut } from 'ol/easing.js';
import { fromLonLat, toLonLat } from 'ol/proj';
import Fill from 'ol/style/Fill';
import GeospatialHelper from './GeospatialHelper';
import HtmlHelper from '../util/HtmlHelper';
import Icon from 'ol/style/Icon';
import MapLibreLayer from '@geoblocks/ol-maplibre-layer';
import MapLocation from './model/MapLocation';
import MercatorMapLocation from './model/MercatorMapLocation';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import ViewExtent from './model/ViewExtent';
import arrowImage from '../assets/images/arrow.png';
import siteStore from '../stores/siteStore';

interface OverlayWork {
  overlay: Overlay;
  add: boolean;
}

interface FeatureWork {
  feature?: Feature;
  featureId?: string;

  geometry?: Geometry;
}

export default class MapObject {
  private static view: View;

  private static map: Map;

  private static mapResolutionChangeSubject = new BehaviorSubject<boolean>(
    false
  );

  private static vectorSource: VectorSource | undefined;

  private static lastMapResolution: number;
  private static lastMapCenter: MapLocation;

  /**
   * Clients can subscribe to this observable for map resolution change events
   */
  static mapResolutionChange$: Observable<boolean> =
    MapObject.mapResolutionChangeSubject.asObservable();

  private static mapCenterChangeSubject = new BehaviorSubject<boolean>(false);

  /**
   * Clients can subscribe to this observable for map center change events
   */
  static mapCenterChange$: Observable<boolean> =
    MapObject.mapCenterChangeSubject.asObservable();

  private static overlayWork: OverlayWork[] = [];

  private static featureWork: FeatureWork[] = [];

  public static performQueuedWork() {
    for (const featureWork of MapObject.featureWork) {
      const { featureId, feature, geometry } = featureWork;
      if (geometry) {
        MapObject.setFeatureGeometry(featureId, geometry);
      } else if (feature) {
        MapObject.addFeature(feature);
      } else {
        MapObject.removeFeatureWithId(featureId);
      }
    }

    for (const overlayWork of MapObject.overlayWork) {
      const { overlay, add } = overlayWork;
      if (add) {
        MapObject.addOverlay(overlay);
      } else {
        MapObject.removeOverlay(overlay);
      }
    }
  }

  public static addOverlay(overlay: Overlay) {
    if (MapObject.map) {
      MapObject.map.addOverlay(overlay);
    } else {
      MapObject.overlayWork.push({ overlay, add: true });
    }
  }

  public static removeOverlay(overlay: Overlay) {
    if (MapObject.map) {
      MapObject.map.removeOverlay(overlay);
    } else {
      MapObject.overlayWork.push({ overlay, add: false });
    }
  }

  public static addFeature(feature: Feature) {
    if (MapObject.vectorSource) {
      MapObject.vectorSource.addFeature(feature);
    } else {
      // queue work to perform after map is initialized
      MapObject.featureWork.push({ feature });
    }
  }

  public static removeFeatureWithId(featureId: string) {
    if (MapObject.vectorSource) {
      const feature = MapObject.vectorSource.getFeatureById(featureId);
      MapObject.vectorSource.removeFeature(feature);
    } else {
      // queue work to perform after map is initialized
      MapObject.featureWork.push({ featureId });
    }
  }

  public static setFeatureGeometry(featureId: string, geometry: Geometry) {
    if (MapObject.vectorSource) {
      const feature = MapObject.vectorSource.getFeatureById(featureId);
      if (feature) {
        feature.setGeometry(geometry);
      }
    } else {
      // queue work to perform after map is initialized
      MapObject.featureWork.push({ featureId, geometry });
    }
  }

  public static getMapCenter(): MapLocation {
    if (MapObject.view) {
      return MapObject.fromCoordinatesToMapLocation(MapObject.view.getCenter());
    } else {
      return DEFAULT_MAP_CENTER;
    }
  }

  public static fromCoordinatesToMercator(
    coordinates: Coordinate
  ): MercatorMapLocation {
    const mercatorMapLocation: MercatorMapLocation = {
      x: coordinates[0],
      y: coordinates[1],
    };
    return mercatorMapLocation;
  }

  public static fromCoordinatesToMapLocation(
    coordinates: Coordinate
  ): MapLocation {
    const [longitude, latitude] = toLonLat(coordinates, PROJECTION_MAP);
    const mapLocation: MapLocation = {
      latitude,
      longitude,
    };
    return mapLocation;
  }

  public static toCoordinatesFromMercator(
    mercatorMapLocation: MercatorMapLocation
  ): Coordinate {
    return [mercatorMapLocation.x, mercatorMapLocation.y];
  }

  public static toCoordinatesFromMapLocation(
    mapLocation: MapLocation
  ): Coordinate {
    return fromLonLat(
      [mapLocation.longitude, mapLocation.latitude],
      PROJECTION_MAP
    );
  }

  public static fromMercator(
    mercatorMapLocation: MercatorMapLocation
  ): MapLocation {
    return MapObject.fromCoordinatesToMapLocation([
      mercatorMapLocation.x,
      mercatorMapLocation.y,
    ]);
  }

  public static toMercator(mapLocation: MapLocation): MercatorMapLocation {
    return MapObject.fromCoordinatesToMercator(
      MapObject.toCoordinatesFromMapLocation(mapLocation)
    );
  }

  public static setMapCenter(mapLocation: MapLocation) {
    if (MapObject.view) {
      MapObject.view.animate({
        center: MapObject.toCoordinatesFromMapLocation(mapLocation),
        duration: 100,
        easing: easeOut,
      });
    }
  }

  public static getViewExtent(): ViewExtent {
    const mapElement = document.getElementById('map');
    if (mapElement) {
      const mapCenter = MapObject.getMapCenter();
      const mapComponentWidth = mapElement.offsetWidth;
      const mapComponentHeight = mapElement.offsetHeight;
      const mapResolution = MapObject.getMapResolution();
      const horizontalDistanceMeters = (mapResolution * mapComponentWidth) / 2;
      const verticalDistanceMeters = (mapResolution * mapComponentHeight) / 2;

      const westLocation = GeospatialHelper.getDestinationLocation(
        mapCenter,
        horizontalDistanceMeters,
        270
      );
      westLocation.latitude = mapCenter.latitude;
      const northLocation = GeospatialHelper.getDestinationLocation(
        mapCenter,
        verticalDistanceMeters,
        0
      );
      northLocation.longitude = mapCenter.longitude;
      return {
        boundingBoxCenter: mapCenter,
        topLeft: {
          latitude: northLocation.latitude,
          longitude: westLocation.longitude,
        },
        bottomRight: {
          latitude:
            mapCenter.latitude - (northLocation.latitude - mapCenter.latitude),
          longitude:
            mapCenter.longitude -
            (westLocation.longitude - mapCenter.longitude),
        },
      };
    }
  }

  public static getMapResolution(): number {
    if (MapObject.view) {
      return MapObject.view.getResolution();
    }
    return MAP_DEFAULT_RESOLUTION;
  }

  public static centerAndSetDefaultResolutionForSite() {
    if (MapObject.view) {
      MapObject.view.setResolution(MAP_DEFAULT_RESOLUTION);
    }
    const site = siteStore.selectedSite;
    MapObject.setMapCenter(site.pickupLocation);
  }

  public static setDefaultMapExtentForSite() {
    const defaultMapDistanceInMeters = 50000;
    MapObject.setMapResolution(
      defaultMapDistanceInMeters,
      defaultMapDistanceInMeters
    );
    const { selectedSite } = siteStore;
    MapObject.setMapCenter(selectedSite.pickupLocation);
  }

  public static restoreLastCenterAndResolution() {
    if (MapObject.lastMapResolution) {
      MapObject.view.setResolution(MapObject.lastMapResolution);
    }
    if (MapObject.lastMapCenter) {
      MapObject.setMapCenter(MapObject.lastMapCenter);
    }
  }

  public static setMapExtent(viewExtent: ViewExtent) {
    // remember the current map center and resolution in order to support restoring
    MapObject.lastMapCenter = MapObject.getMapCenter();
    MapObject.lastMapResolution = MapObject.getMapResolution();

    const { boundingBoxCenter, topLeft, bottomRight } = viewExtent;
    const boundsWidthInMeters = GeospatialHelper.getDistance(
      { latitude: bottomRight.latitude, longitude: topLeft.longitude },
      { latitude: bottomRight.latitude, longitude: bottomRight.longitude }
    );

    const boundsHeightInMeters = GeospatialHelper.getDistance(
      { latitude: topLeft.latitude, longitude: topLeft.longitude },
      { latitude: bottomRight.latitude, longitude: topLeft.longitude }
    );

    MapObject.setMapResolution(boundsWidthInMeters, boundsHeightInMeters);
    MapObject.setMapCenter(boundingBoxCenter);
  }

  public static async createOpenLayersMap() {
    await HtmlHelper.getHtmlElementById('map');
    const selectedSite = siteStore.selectedSite;
    const center = selectedSite
      ? selectedSite.pickupLocation
      : DEFAULT_MAP_CENTER;

    const view = new View({
      projection: PROJECTION_MAP,
      center: MapObject.toCoordinatesFromMapLocation(center),
      resolution: MAP_DEFAULT_RESOLUTION,
    });

    view.on('change:resolution', () => {
      MapObject.mapResolutionChangeSubject.next(true);
    });

    view.on('change:center', () => {
      MapObject.mapCenterChangeSubject.next(true);
    });

    this.map = new Map({
      target: 'map',
      controls: defaultControls(),
      view,
    });

    MapObject.view = view;

    const amplifyCredentialsFromAuthClass = await Auth.currentCredentials();
    const defaultMap = Geo.getDefaultMap() as AmazonLocationServiceMapStyle;
    const { transformRequest } = new AmplifyMapLibreRequest(
      amplifyCredentialsFromAuthClass,
      defaultMap.region
    );

    const mapLibreLayer = new MapLibreLayer({
      maplibreOptions: {
        style: Geo.getDefaultMap().mapName,
        transformRequest: transformRequest,
      },
      source: new Source({
        attributions: [
          // TODO try to read this attribution text dynamically
          '© 2022 HERE',
        ],
        attributionsCollapsible: false,
      }),
    });

    MapObject.map.addLayer(mapLibreLayer);

    MapObject.vectorSource = new VectorSource();

    const vectorLayer = new VectorLayer({
      visible: true,
      properties: {
        title: 'vector layer',
      },
      source: this.vectorSource,
      style: MapObject.styleFunction,
      zIndex: 1,
    });

    MapObject.map.addLayer(vectorLayer);

    MapObject.map.addControl(
      new ScaleLine({
        bar: true,
        // TODO change the units to metric for EU and FE
        units: 'us',
      })
    );
  }

  private static setMapResolution(widthMeters: number, heightMeters: number) {
    const mapElement = document.getElementById('map');
    if (mapElement) {
      const mapComponentWidth = mapElement.offsetWidth;
      const mapComponentHeight = mapElement.offsetHeight;
      const minimumMapSizeInMeters = 50;
      const boundsWidthInMeters = Math.max(widthMeters, minimumMapSizeInMeters);

      const boundsHeightInMeters = Math.max(
        heightMeters,
        minimumMapSizeInMeters
      );
      const desiredWidthResolution = boundsWidthInMeters / mapComponentWidth;
      const desiredHeightResolution = boundsHeightInMeters / mapComponentHeight;

      // multiply by a factor to give some padding
      const paddingFactor = 2;
      const desiredResolution = Math.ceil(
        Math.max(desiredWidthResolution, desiredHeightResolution) *
          paddingFactor
      );
      MapObject.view.setResolution(desiredResolution);
    } else {
      // TODO handle this
    }
  }

  private static styleFunction(featureAny: any) {
    const feature = featureAny as Feature;

    const color = '#25791d';

    const styles = [
      new Style({
        stroke: new Stroke({
          color,
          width: 2,
        }),
        fill: new Fill({
          color,
        }),
      }),
    ];

    const geometry = feature.getGeometry() as LineString;
    const midPoint = geometry.getFlatMidpoint();
    geometry.forEachSegment((start, end) => {
      const dx = end[0] - start[0];
      const dy = end[1] - start[1];
      const rotation = Math.atan2(dy, dx);
      styles.push(
        new Style({
          geometry: new Point(midPoint),
          image: new Icon({
            src: arrowImage,
            rotateWithView: true,
            rotation: -rotation,
            opacity: 1,
            scale: 0.03,
          }),
        })
      );
    });

    return styles;
  }
}
