import * as _ from 'lodash';
import { BehaviorSubject, Observable, auditTime, merge } from 'rxjs';
import { HTTP_PARAM_ORDER_IDS } from '../util/UrlConstants';
import { MAP_VIEW_ORDERS } from '../util/ViewConstants';
import { Order } from '@amzn/gsf-dispatcher-schema';
import { reaction } from 'mobx';
import GeospatialHelper from '../map/GeospatialHelper';
import MapObject from '../map/MapObject';
import OrderFilterController from '../controllers/OrderFilterController';
import RouteHelper from '../util/RouteHelper';
import UrlHelper from '../util/UrlHelper';
import filterReactor from './filterReactor';
import orderStore from 'stores/orderStore';
import routeReactor from './routeReactor';
import routeStore from '../stores/routeStore';
import viewReactor from './viewReactor';
import viewStore from '../stores/viewStore';

class OrderReactor {
  private ordersChangeSubject = new BehaviorSubject<boolean>(false);
  ordersChange$: Observable<boolean> = this.ordersChangeSubject.asObservable();

  private filteredOrdersChangeSubject = new BehaviorSubject<boolean>(false);
  filteredOrdersChange$: Observable<boolean> =
    this.filteredOrdersChangeSubject.asObservable();

  private orderAssignmentChangeSubject = new BehaviorSubject<boolean>(false);
  orderAssignmentChange$: Observable<boolean> =
    this.orderAssignmentChangeSubject.asObservable();

  private selectedOrderIdsChangeSubject = new BehaviorSubject<boolean>(false);
  selectedOrderIdsChange$: Observable<boolean> =
    this.selectedOrderIdsChangeSubject.asObservable();

  private highlightedOrderIdsChangeSubject = new BehaviorSubject<boolean>(
    false
  );
  highlightedOrderIdsChange$: Observable<boolean> =
    this.highlightedOrderIdsChangeSubject.asObservable();

  constructor() {
    reaction(
      () => {
        return orderStore.orders;
      },
      (orders: Order[]) => {
        this.ordersChangeSubject.next(true);
      }
    );

    reaction(
      () => {
        return orderStore.filteredOrders;
      },
      (filteredOrders: Order[]) => {
        this.filteredOrdersChangeSubject.next(true);
      }
    );

    reaction(
      () => {
        return orderStore.selectedOrderIds;
      },
      (orderIds: string[]) => {
        this.selectedOrderIdsChangeSubject.next(true);
      }
    );

    reaction(
      () => {
        return orderStore.highlightedOrderIds;
      },
      (orderIds: string[]) => {
        this.highlightedOrderIdsChangeSubject.next(true);
      }
    );

    reaction(
      () => {
        return orderStore.orders.map((o) => o.transporter);
      },
      (assignedTransporters) => {
        this.orderAssignmentChangeSubject.next(true);
      }
    );

    /**
     * listen to changes in the routes or orders and remove any selected order ids
     * if the order id no longer exists in the routes or the orders.  Note that we don't want
     * to remove highlighted order ids since it also changes the order ids in the URL which
     * is confusing to the user.  Note that changing sites will clear the highlighted
     * order ids though which should not surprise the user.
     * */
    const orderIdChangeObservable = merge(
      this.ordersChange$.pipe(),
      routeReactor.routesChange$.pipe()
    );
    orderIdChangeObservable.subscribe(async (event) => {
      const { orders, selectedOrderIds, highlightedOrderIds } = orderStore;
      const { routes } = routeStore;

      // check for any selected order ids which no longer exist
      const validOrderIds = orders.map((o) => o.orderId);
      validOrderIds.push(
        ...RouteHelper.getOrders(routes).map((o) => o.orderId)
      );
      const newSelectedOrderIds = _.intersection(
        selectedOrderIds,
        validOrderIds
      );
      orderStore.setSelectedOrderIds(newSelectedOrderIds);
    });

    /**
     * listen to changes in the orders, and the order filter selections, or assignment change and
     * perform order filter in a rate limited way
     * (max once per 200msec)
     */
    const rateLimitedObservableForOrderFilter = merge(
      this.ordersChange$.pipe(),
      filterReactor.selectedOrderFilterCodesChange$.pipe(),
      this.orderAssignmentChange$.pipe()
    ).pipe(auditTime(200));
    rateLimitedObservableForOrderFilter.subscribe(async (event) => {
      const { orders } = orderStore;
      const filteredOrders = OrderFilterController.filterOrders(orders);
      orderStore.setFilteredOrders(filteredOrders);
    });

    /**
     * listen to changes in the highlighted order ids and update the HTTP parameter
     */
    this.highlightedOrderIdsChange$.subscribe(() => {
      const { highlightedOrderIds, enableTwoWayHighlightedOrderSync } =
        orderStore;
      if (enableTwoWayHighlightedOrderSync) {
        highlightedOrderIds.sort();
        if (highlightedOrderIds.length === 0) {
          UrlHelper.removeParameter(HTTP_PARAM_ORDER_IDS);
        } else {
          UrlHelper.setParameterValue(
            HTTP_PARAM_ORDER_IDS,
            highlightedOrderIds.join(',')
          );
        }
      }
      // if the order is on a route, then expand the routes so the highlighted order
      // is shown to the user
      highlightedOrderIds.forEach((orderId) => {
        const order = RouteHelper.findOrderInRouteById(orderId);
        if (order && viewStore.routesCollapsed) {
          viewStore.setRoutesCollapsed(false);
        }
      });
    });

    /**
     * listen to the map resolution or center changes (since only the order groups in the map view extents are
     * shown) as well as the filtered order changes and rate limit the updates of the order groups to at most
     * once per 500 msec to keep the browser responsive during map zooming or panning with mouse wheel
     * */
    const rateLimitedObservableForOrders = merge(
      MapObject.mapResolutionChange$.pipe(),
      MapObject.mapCenterChange$.pipe(),
      this.filteredOrdersChange$.pipe(),
      viewReactor.mapViewChange$.pipe()
    ).pipe(auditTime(500));
    rateLimitedObservableForOrders.subscribe(async (event) => {
      const { mapView } = viewStore;
      if (mapView === MAP_VIEW_ORDERS) {
        const { filteredOrders } = orderStore;
        const orderGroups = await GeospatialHelper.groupOrders(filteredOrders);
        orderStore.setFilteredOrderGroups(orderGroups);
      } else {
        orderStore.setFilteredOrderGroups([]);
      }
    });
  }
}

export default new OrderReactor();
