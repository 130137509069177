import * as _ from 'lodash';
import {
  Order,
  Transporter,
  TransporterStopType,
} from '@amzn/gsf-dispatcher-schema';
import { observer } from 'mobx-react';
import MapLineSegmentComp from '../common/MapLineSegmentComp';
import MapTransporterGroupComp from './MapTransporterGroupComp';
import MapTransporterStopComp from '../common/MapTransporterStopComp';
import React from 'react';
import TransporterGroup from '../../../map/model/TransporterGroup';
import transporterStore from '../../../stores/transporterStore';

interface MapTransporterAndRemainingStopsProps {
  transporter: Transporter;
  renderStops: boolean;

  highlightTransporter: boolean;
}

class MapTransporterAndRemainingStopsComp extends React.Component<MapTransporterAndRemainingStopsProps> {
  render() {
    const { transporter, renderStops, highlightTransporter } = this.props;

    const content = [];

    const { selectedTransporterTransporterGroup } = transporterStore;
    const transporterIds = selectedTransporterTransporterGroup
      ? selectedTransporterTransporterGroup.entities.map(
          (t: Transporter) => t.transporterId
        )
      : [];

    if (
      selectedTransporterTransporterGroup &&
      _.includes(transporterIds, transporter.transporterId)
    ) {
      const transporterGroupKey = `map-transporter-transporter-group${transporterIds.join(
        '~'
      )}}-${selectedTransporterTransporterGroup.centroid.latitude},${
        selectedTransporterTransporterGroup.centroid.longitude
      }`;
      content.push(
        <MapTransporterGroupComp
          key={transporterGroupKey}
          transporterGroup={selectedTransporterTransporterGroup}
          highlighted={true}
        />
      );

      if (renderStops) {
        const orderMap = new Map<string, Order>();
        const remainingOrders = transporter.remainingOrders || [];
        for (const order of remainingOrders) {
          orderMap.set(order.orderId, order);
        }

        // filter out any remaining stop which has no order ids and a stop has a valid order id
        // since only stops associated to an order affect rendering
        const remainingStops = transporter.remainingStops
          ? transporter.remainingStops.filter(
              (rs) =>
                rs.orderIds &&
                rs.orderIds.length > 0 &&
                rs.orderIds.find((orderId) => orderMap.has(orderId))
            )
          : [];

        for (let i = 0; i < remainingStops.length; i++) {
          const thisStop = remainingStops[i];
          const previousStop = i === 0 ? undefined : remainingStops[i - 1];
          const thisStopValidOrderId = thisStop.orderIds.find((orderId) =>
            orderMap.has(orderId)
          );
          const thisOrder = orderMap.get(thisStopValidOrderId);
          let previousOrder = undefined;
          if (previousStop) {
            const previousStopValidOrderId = previousStop.orderIds.find(
              (orderId) => orderMap.has(orderId)
            );
            previousOrder = orderMap.get(previousStopValidOrderId);
          }

          const startLocationObject: TransporterGroup | Order =
            i === 0 ? selectedTransporterTransporterGroup : previousOrder;
          const startLocationObjectPropertyName: string =
            i === 0
              ? 'centroid'
              : previousStop.stopType === TransporterStopType.Pickup
              ? 'pickupLocation'
              : 'deliveryLocation';
          const endLocationObject: Order = thisOrder;
          const endLocationObjectPropertyName: string =
            thisStop.stopType === TransporterStopType.Pickup
              ? 'pickupLocation'
              : 'deliveryLocation';

          const lineSegmentStartKey =
            i == 0
              ? `transporterGroup-${transporterGroupKey}`
              : `order${previousOrder.orderId}${
                  remainingStops[i - 1].stopType === TransporterStopType.Pickup
                    ? 'pickup'
                    : 'delivery'
                }`;
          const lineSegmentEndKey = `order${thisOrder.orderId}${
            remainingStops[i].stopType === TransporterStopType.Pickup
              ? 'pickup'
              : 'delivery'
          }`;

          // push the directed line segment
          // it is important for the key to contain both endpoints in the key name so that react
          // will properly destroy/create new instances of the vector.
          const lineSegmentKey = `transporter${transporter.transporterId}:${lineSegmentStartKey}:${lineSegmentEndKey}`;
          content.push(
            <MapLineSegmentComp
              key={lineSegmentKey}
              startLocation={startLocationObject}
              startPropertyName={startLocationObjectPropertyName}
              endLocation={endLocationObject}
              endPropertyName={endLocationObjectPropertyName}
            />
          );

          const key = `transporter${transporter.transporterId}${lineSegmentEndKey}`;
          content.push(
            <MapTransporterStopComp
              key={key}
              stop={thisStop}
              order={thisOrder}
            />
          );
        }
      }
    }

    return <div>{content}</div>;
  }
}

export default observer(MapTransporterAndRemainingStopsComp);
