import Sleeper from './Sleeper';

export default class HtmlHelper {
  static async getHtmlElementById(id: string): Promise<HTMLElement> {
    return new Promise(async (resolve, reject) => {
      for (let i = 0; i < 1000; i++) {
        const htmlElement = document.getElementById(id);
        if (htmlElement) {
          resolve(htmlElement);
          break;
        } else {
          await Sleeper.sleep(10);
        }
      }
      resolve(undefined);
    });
  }
}
