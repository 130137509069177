import './MapSiteComp.css';
import { Site } from '@amzn/gsf-dispatcher-schema';
import { observer } from 'mobx-react';
import MapLocatableEntityComp from '../common/MapLocatableEntityComp';
import siteImage from '../../../assets/images/site.png';

interface MapSiteProps {
  site: Site;
}

interface MapSiteState {}

class MapSiteComp extends MapLocatableEntityComp<MapSiteProps, MapSiteState> {
  constructor(props: MapSiteProps) {
    super(props, {}, 'site', 'pickupLocation', 'map-site-locatable-entity');
  }

  render() {
    return (
      // it is important to wrap the jsx with a parent div in order to prevent
      // a react error when restarting the development server
      <div>
        <div id={this.getId()} className="map-site-container">
          <img className="map-site-image" src={siteImage} />
        </div>
      </div>
    );
  }
}

export default observer(MapSiteComp);
