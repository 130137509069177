import { observer } from 'mobx-react';
import Icon from '@amzn/meridian/icon';
import React from 'react';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import alertTokens from '@amzn/meridian-tokens/base/icon/alert';
import applicationStatusStore from '../../stores/applicationStatusStore';

export interface NetworkStatusProps {}

class NetworkStatusComp extends React.Component<NetworkStatusProps> {
  render() {
    const { networkUp } = applicationStatusStore;
    return (
      !networkUp && (
        <Row spacing="300">
          <Text color="error" className="nowrap">
            <Icon tokens={alertTokens} />
          </Text>
          <Text type="h100" color={'error'} className="nowrap">
            Network Connection !
          </Text>
        </Row>
      )
    );
  }
}

export default observer(NetworkStatusComp);
