import './App.css';
import '@aws-amplify/ui-react/styles.css';
import { Amplify } from 'aws-amplify';
import Alert from '@amzn/meridian/alert';
import Column from '@amzn/meridian/column';
import Loader from '@amzn/meridian/loader';
import React from 'react';
import Row from '@amzn/meridian/row';
import SiteAppComp from './components/siteApp/SiteAppComp';

import { getRegionBannerFromDomain } from './banners/InDomainRedirectBanner';
import { observer } from 'mobx-react';
import AuthHelper from './auth/AuthHelper';
import BannerComp, { BannerProps } from './components/common/BannerComp';
import MastHeadComp from './components/masthead/MastHeadComp';
import SiteController from './controllers/SiteController';
import amplifyConfig from './amplify-config';
import reactorLoader from 'reactors/reactorLoader';
import siteStore from './stores/siteStore';

// configure Amplify
Amplify.configure(amplifyConfig);

interface AppProps {}
class App extends React.Component<AppProps> {
  redirectBannerInfo: BannerProps;

  constructor(props: AppProps) {
    super(props);
    this.redirectBannerInfo = getRegionBannerFromDomain();
    reactorLoader.load();
  }

  async componentDidMount() {
    await AuthHelper.getJwt();
    await SiteController.loadAllSiteNames();
  }

  render() {
    const { selectedSiteName, authorizedForSelectedSiteName, loadingSite } =
      siteStore;
    const row = [];
    if (selectedSiteName) {
      if (authorizedForSelectedSiteName) {
        row.push(<SiteAppComp key="siteAppContent" />);
      } else if (loadingSite) {
        row.push(
          <Row
            key="siteLoader"
            widths="fill"
            alignmentHorizontal="center"
            alignmentVertical="center"
            height="100%"
          >
            <Column
              height="100%"
              alignmentHorizontal="center"
              alignmentVertical="center"
            >
              <Loader />
            </Column>
          </Row>
        );
      } else {
        row.push(
          <Row key="unauthorizedSite" widths="fill" minWidth="100%">
            <Column
              width="100%"
              height="100%"
              alignmentHorizontal="center"
              alignmentVertical="center"
            >
              <Alert type="warning" size="large">
                {`Not authorized for ${SiteController.getSearchable(
                  selectedSiteName
                )}`}
              </Alert>
            </Column>
          </Row>
        );
      }
    }

    return (
      <Column height="100%" alignmentVertical="top">
        <div id="header">
          <Row widths="fill" minWidth="100%">
            <MastHeadComp />
          </Row>
          <Row widths="fill" minWidth="100%">
            <BannerComp
              bannerLink={this.redirectBannerInfo.bannerLink}
              bannerEnable={this.redirectBannerInfo.bannerEnable}
            />
          </Row>
        </div>
        {row}
      </Column>
    );
  }
}
export default observer(App);
