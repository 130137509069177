import { observer } from 'mobx-react';
import Button from '@amzn/meridian/button';
import MapController from 'controllers/MapController';
import React from 'react';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';

interface MapControlsProps {}

class MapControlsComp extends React.Component<MapControlsProps> {
  render() {
    return (
      <Row>
        <Text>{'Map Controls:'}</Text>
        <Button
          onClick={() => this.handleMapCenter()}
          size={'small'}
          propagateClickEvent={false}
        >
          Center
        </Button>
      </Row>
    );
  }

  handleMapCenter() {
    MapController.handleMapCenter();
  }
}

export default observer(MapControlsComp);
