import './MapOrderGroupComp.css';
import * as _ from 'lodash';
import { observer } from 'mobx-react';
import Box from '@amzn/meridian/box';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import MapLocatableEntityComp from '../common/MapLocatableEntityComp';
import OrderComp from '../../order/OrderComp';
import OrderController from '../../../controllers/OrderController';
import OrderGroup from '../../../map/model/OrderGroup';
import OrderSortController from 'controllers/OrderSortController';
import Popover from '@amzn/meridian/popover';
import React, { RefObject } from 'react';
import Row from '@amzn/meridian/row';
import StringHelper from 'util/StringHelper';
import Text from '@amzn/meridian/text';
import orderGroupImage from '../../../assets/images/dropoff.svg';
import orderStore from '../../../stores/orderStore';
import plusCircleTokens from '@amzn/meridian-tokens/base/icon/plus-circle';
import trashTokens from '@amzn/meridian-tokens/base/icon/trash';

interface MapOrderGroupProps {
  orderGroup: OrderGroup;
}

interface MapOrderGroupState {
  popupOpened: boolean;
  lastCloseTime: number;
}

class MapOrderGroupComp extends MapLocatableEntityComp<
  MapOrderGroupProps,
  MapOrderGroupState
> {
  private readonly ref: RefObject<HTMLDivElement>;

  constructor(props: MapOrderGroupProps) {
    super(
      props,
      { popupOpened: false, lastCloseTime: 0 },
      'orderGroup',
      'centroid'
    );
    this.closePopup = this.closePopup.bind(this);
    this.clickOrderGroup = this.clickOrderGroup.bind(this);
    this.render = this.render.bind(this);
    this.ref = React.createRef();
  }

  render() {
    const { orderGroup } = this.props;
    const orders = OrderSortController.sortOrders(orderGroup.entities);
    const orderIdsInGroup = orders.map((o) => o.orderId);
    const orderComps = orders.map((o) => (
      <OrderComp key={`orderListItem${o.orderId}`} order={o} />
    ));
    const { popupOpened } = this.state;
    const selectedOrderIds = _.intersection(
      orderStore.selectedOrderIds,
      orderIdsInGroup
    );
    const selected = selectedOrderIds.length > 0;
    const selectedPercent = Math.floor(
      (100 * selectedOrderIds.length) / orders.length
    );
    // setting a CSS variable so that the percentage of selected orders can be rendered as a pie chart
    // on the order group cluster icon
    const selectedOrderPercentageStyle = {
      '--selectedPercentage': selectedPercent,
    } as React.CSSProperties;

    return (
      // it is important to wrap the jsx with a parent div in order to prevent
      // a react error when restarting the development server
      <div>
        <div
          id={this.getId()}
          className={
            selected
              ? 'map-order-group-container-selected'
              : 'map-order-group-container'
          }
          style={selected ? selectedOrderPercentageStyle : {}}
          onClick={() => this.clickOrderGroup(orderGroup)}
          ref={this.ref}
        >
          <img className={'map-order-group-image'} src={orderGroupImage} />
          <div className={'map-order-group-count-badge-label'}>
            {orders.length}
          </div>
        </div>
        <Popover
          anchorNode={this.ref.current}
          open={popupOpened}
          onClose={this.closePopup}
          position="end"
          alignment="center"
        >
          <Row spacingInset="100" alignmentHorizontal="center" spacing="400">
            <Text type="h300">
              {StringHelper.makeCountDisplay(orders.length, 'Order')}
            </Text>
            <Button
              size="small"
              type="icon"
              onClick={() =>
                OrderController.addSelectedOrderIds(orderIdsInGroup)
              }
              propagateClickEvent={false}
            >
              <Icon tokens={plusCircleTokens} />
            </Button>
            <Button
              size="small"
              type="icon"
              onClick={() =>
                OrderController.clearSelectedOrderIds(selectedOrderIds)
              }
              propagateClickEvent={false}
            >
              <Icon tokens={trashTokens} />
            </Button>
          </Row>
          <Box overflowY="scroll" maxHeight="20em">
            {orderComps}
          </Box>
        </Popover>
      </div>
    );
  }

  async clickOrderGroup(orderGroup: OrderGroup) {
    const { lastCloseTime } = this.state;
    // add a 250 msec delay so that user can click the order cluster to close the popup without it popping up again
    if (Date.now() - lastCloseTime >= 250) {
      this.setState({ popupOpened: true });
    }
  }

  closePopup() {
    this.setState({ popupOpened: false, lastCloseTime: Date.now() });
  }
}

export default observer(MapOrderGroupComp);
