import './DataAgeComp.css';
import { Subscription } from 'rxjs';
import { TextColor } from '@amzn/meridian/text/text';
import { observer } from 'mobx-react';
import Icon from '@amzn/meridian/icon';
import React from 'react';
import Text from '@amzn/meridian/text';
import TimeHelper from '../../util/TimeHelper';
import clockTokens from '@amzn/meridian-tokens/base/icon/clock';

export interface DataAgeProps {
  pastTime: string;
  description?: string;
  color?: TextColor;
  prefix?: string;
  suffix?: string;
}

class DataAgeComp extends React.Component<DataAgeProps> {
  private subscription: Subscription;

  componentDidMount() {
    this.subscription = TimeHelper.secondChange$.subscribe(() => {
      this.forceUpdate();
    });
  }

  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  render() {
    const { pastTime, color, prefix, suffix, description } = this.props;
    const dataAge = TimeHelper.getDataAge(pastTime);
    return (
      <div title={description}>
        <Text type="h100" color={color} className="nowrap">
          <Icon tokens={clockTokens} className={'data-age-icon'} />
          {`${prefix || ''}${dataAge}${suffix || ''}`}
        </Text>
      </div>
    );
  }
}

export default observer(DataAgeComp);
