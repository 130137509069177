import {
  MAP_VIEW_ORDERS,
  MAP_VIEW_ROUTES,
  MAP_VIEW_TRANSPORTERS,
} from '../../util/ViewConstants';
import { observer } from 'mobx-react';
import ButtonGroup, { ButtonOption } from '@amzn/meridian/button-group';
import React from 'react';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import ViewController from '../../controllers/ViewController';
import viewReactor from '../../reactors/viewReactor';
import viewStore from '../../stores/viewStore';

interface MapViewProps {}

interface MapViewState {
  mapView: string;
}

class MapViewComp extends React.Component<MapViewProps, MapViewState> {
  componentDidMount() {
    const { mapView } = viewStore;
    this.setState({ mapView });

    viewReactor.mapViewChange$.subscribe(async (event) => {
      const { mapView } = viewStore;
      this.setState({ mapView });
    });
  }
  constructor(props: MapViewProps) {
    super(props);
    this.handleValueChange = this.handleValueChange.bind(this);
  }

  render() {
    const mapView = this.state?.mapView;

    return (
      <Row>
        <Text>{'Map View:'}</Text>
        <ButtonGroup
          value={mapView}
          onChange={(newValue) => this.handleValueChange(newValue)}
          size="small"
        >
          <ButtonOption value={MAP_VIEW_TRANSPORTERS}>
            Transporters
          </ButtonOption>
          <ButtonOption value={MAP_VIEW_ROUTES}>Routes</ButtonOption>
          <ButtonOption value={MAP_VIEW_ORDERS}>Orders</ButtonOption>
        </ButtonGroup>
      </Row>
    );
  }

  handleValueChange(value: string) {
    this.setState({ mapView: value });
    ViewController.handleMapViewChange(value);
  }
}

export default observer(MapViewComp);
