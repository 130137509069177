import { SSD_PROGRAM_TYPE } from './BusinessConstants';
import { Site } from '@amzn/gsf-dispatcher-schema';
import SiteStore from '../stores/siteStore';

export const STALE_SITE_DATA_THRESHOLD_MINUTES = 5;

export default class SiteHelper {
  static getOldestUpdateTime(site: Site) {
    if (site) {
      const routeUpdateTime = site.routesUpdatedTimeMillis
        ? Number(site.routesUpdatedTimeMillis)
        : 0;
      const transporterUpdateTime = site.transportersUpdatedTimeMillis
        ? Number(site.transportersUpdatedTimeMillis)
        : 0;
      if (routeUpdateTime < transporterUpdateTime) {
        return site.routesUpdatedTimeMillis;
      }
      return site.transportersUpdatedTimeMillis;
    } else {
      return undefined;
    }
  }

  static isSiteDataStale(site: Site) {
    if (site) {
      const oldestUpdateTime = SiteHelper.getOldestUpdateTime(site);
      if (oldestUpdateTime) {
        const now = Date.now();
        const updatedTime = Number(oldestUpdateTime);
        return (
          now - updatedTime > STALE_SITE_DATA_THRESHOLD_MINUTES * 60 * 1000
        );
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  static isSsdSite(): boolean {
    const { selectedSite } = SiteStore;
    if (!!selectedSite) {
      return selectedSite.programTypes?.includes(SSD_PROGRAM_TYPE);
    } else {
      return false;
    }
  }
}
