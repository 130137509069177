import { BannerProps } from '../components/common/BannerComp';

export function getRegionBannerFromDomain(): BannerProps {
  const domain = window.location.host;
  if (domain.startsWith('prod-eu')) {
    return {
      bannerEnable: true,
      bannerLink:
        'Looking for IN or AE sites? Please visit https://prod-in.dis2-workbench.gsf.a2z.com/',
    };
  } else {
    return {
      bannerEnable: false,
      bannerLink: '',
    };
  }
}
