import { Order } from '@amzn/gsf-dispatcher-schema';
import { observer } from 'mobx-react';
import OrderComp from './OrderComp';
import React from 'react';

export interface OrderListProps {
  orders: Order[];
}

class OrderListComp extends React.Component<OrderListProps> {
  render() {
    const { orders } = this.props;
    const orderComps = orders.map((o) => (
      <OrderComp key={`order-comp${o.orderId}`} order={o} />
    ));
    return orderComps;
  }
}

export default observer(OrderListComp);
