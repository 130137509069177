import * as _ from 'lodash';
import { MAP_VIEW_TRANSPORTERS } from '../util/ViewConstants';
import {
  SUCCESS_MESSAGE_TIMEOUT,
  WARNING_MESSAGE_TIMEOUT,
} from '../util/TimeHelper';
import { Transporter } from '@amzn/gsf-dispatcher-schema';
import { TransporterStopType } from '../graphqlGenerated/graphql';
import ClipboardHelper from '../util/ClipboardHelper';
import GeospatialHelper from 'map/GeospatialHelper';
import MapObject from '../map/MapObject';
import MessagesController from './MessagesController';
import OrderController from './OrderController';
import TransporterHelper from '../util/TransporterHelper';
import transporterStore from '../stores/transporterStore';
import viewStore from '../stores/viewStore';

export default class TransporterController {
  static transporterClicked(transporter: Transporter) {
    const currentlySelectedTransporterId =
      transporterStore.selectedTransporterId;
    const transporterId = transporter.transporterId;
    if (currentlySelectedTransporterId === transporterId) {
      transporterStore.setSelectedTransporterId(undefined);
      if (TransporterController.interactWithMap()) {
        MapObject.restoreLastCenterAndResolution();
      }
    } else {
      transporterStore.setSelectedTransporterId(transporterId);
      viewStore.setMapView(MAP_VIEW_TRANSPORTERS);
    }
  }

  static setMapExtendsToSelectedTransporter() {
    if (TransporterController.interactWithMap()) {
      const selectedTransporterId = transporterStore.selectedTransporterId;
      if (selectedTransporterId) {
        const transporter = transporterStore.transporters.find(
          (t) => t.transporterId === selectedTransporterId
        );
        if (transporter) {
          MapObject.setMapExtent(
            GeospatialHelper.getExtentsForTransporterAndStops(transporter)
          );
        }
      }
    }
  }

  static handleDestroyTransporter(transporter: Transporter): void {
    transporterStore.removeTransporter(transporter);
  }

  static handleUpdateTransporter(transporter: Transporter): void {
    TransporterController.fixTransporterData(transporter);
    if (transporter.transporterId) {
      transporterStore.updateTransporter(transporter);
    }
  }

  /**
   * If a transporter can't be fixed, then set the transporterId to undefined and
   * it will be filtered out
   * @param transporter
   */
  static fixTransporterData(transporter: Transporter): void {
    if (!transporter.lastKnownLocation) {
      transporter.lastKnownLocation = {
        latitude: 0,
        longitude: 0,
      };
    }
    transporter.remainingOrders = transporter.remainingOrders || [];
    transporter.remainingOrders = transporter.remainingOrders.filter((o) => {
      OrderController.fixOrderData(o);
      return o.orderId;
    });
    const remainingOrderIds = transporter.remainingOrders.map((o) => o.orderId);

    transporter.remainingStops = transporter.remainingStops || [];
    transporter.remainingStops.forEach((stop) => {
      stop.orderIds = stop.orderIds || [];
      stop.orderIds = stop.orderIds.filter((orderId) =>
        _.includes(remainingOrderIds, orderId)
      );
    });
    transporter.remainingStops = transporter.remainingStops.filter(
      (stop) => stop.orderIds.length > 0
    );

    if (TransporterController.shouldUpdateRemainingStops(transporter)) {
      TransporterHelper.setRemainingStopsBasedOnRemainingOrders(transporter);
    }

    TransporterHelper.sortRemainingStops(transporter);
  }

  static handleTransporterLocationClick(transporter: Transporter): void {
    const transporterId = transporter?.transporterId;
    const transporterName = TransporterHelper.getTransporterName(transporter);
    const transporterFromStore =
      TransporterHelper.findTransporterById(transporterId);
    if (transporterFromStore) {
      transporterStore.setSelectedTransporterId(transporterId);
      viewStore.setMapView(MAP_VIEW_TRANSPORTERS);
    } else {
      MessagesController.addWarningMessage(
        `'${transporterName}' is not online`,
        WARNING_MESSAGE_TIMEOUT
      );
    }
  }

  static handleTransporterPhoneClick(transporter: Transporter): void {
    const transporterName = transporter?.name;
    const transporterPhoneNumber = transporter?.phoneNumber;
    if (transporterPhoneNumber) {
      ClipboardHelper.copyToClipboard(transporterPhoneNumber);
      MessagesController.addSuccessMessage(
        `Copied '${transporterPhoneNumber}' for '${transporterName}' to clipboard`,
        SUCCESS_MESSAGE_TIMEOUT
      );
    } else {
      MessagesController.addWarningMessage(
        `'${transporterName}' unknown phone #`,
        WARNING_MESSAGE_TIMEOUT
      );
    }
  }

  private static interactWithMap() {
    const { mapView } = viewStore;
    return mapView === MAP_VIEW_TRANSPORTERS;
  }

  /**
   * If there is at least one stop but no delivery stops, then this is an indication that the TRAS remaining
   * stop data is not reflective of the transporters remaining orders.  TRAS will only populate the pickup stops
   * in the remaining stops and none of the delivery stops until the transporter has departed the first pickup.
   * The UI users would like to see the remaining stops populated based on transporter remaining orders field because
   * they use that as a check on the driver's assigned itinerary.
   *
   * @param transporter
   * @private
   */
  private static shouldUpdateRemainingStops(transporter: Transporter): boolean {
    const stops = transporter.remainingStops;
    const deliveryStops = stops.filter(
      (stop) => stop.stopType === TransporterStopType.Delivery
    );
    return stops.length > 0 && deliveryStops.length === 0;
  }
}
