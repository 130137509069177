import { LOG_ATTRIBUTE_USER_NAME } from '../logging/LogConstants';
import { action, makeObservable, observable } from 'mobx';
import { metricsPublisher } from 'logging/KatalMetricPublishers';

class AuthStore {
  preferredUsername: string = '';

  constructor() {
    makeObservable(this, {
      preferredUsername: observable,
      setPreferredUsername: action,
    });
  }

  setPreferredUsername(preferredUsername: string) {
    this.preferredUsername = preferredUsername;
    // set static dimension values
    metricsPublisher.publishString(LOG_ATTRIBUTE_USER_NAME, preferredUsername);
  }
}

export default new AuthStore();
