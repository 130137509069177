import {
  ENV_BETA,
  ENV_GAMMA_EU,
  ENV_GAMMA_FE,
  ENV_GAMMA_IN,
  ENV_GAMMA_NA,
  ENV_PROD_EU,
  ENV_PROD_FE,
  ENV_PROD_IN,
  ENV_PROD_NA,
  REGION_EU,
  REGION_FE,
  REGION_NA,
} from './EnvironmentConstants';
import UrlHelper from './UrlHelper';

interface EnvironmentConfig {
  region: string;
  appSyncUrl: string;
  logisticsHostName: string;
}

interface CognitoConfig {
  region: string;
  domain: string;
  clientId: string;
  userPoolId: string;
  identityPoolId: string;
  userPoolWebClientId: string;
}

const LOGISTICS_HOST_NAME_NA = 'logistics.amazon.com';
const LOGISTICS_HOST_NAME_EU = 'logistics.amazon.co.uk';
const LOGISTICS_HOST_NAME_FE = 'logistics.amazon.com.sg';

const prodCognitoConfig: CognitoConfig = {
  region: REGION_NA,
  domain: 'prod-dis2-workbench.auth.us-east-1.amazoncognito.com',
  clientId: '3i8jcc44oioqat9j9a7tsnm65b',
  userPoolId: 'us-east-1_W6Md69FGs',
  identityPoolId: 'us-east-1:90b0066c-c774-4196-888f-9b04d6bb9c75',
  userPoolWebClientId: '3i8jcc44oioqat9j9a7tsnm65b',
};

const nonProdCognitoConfig: CognitoConfig = {
  region: REGION_NA,
  domain: 'nonprod-gsf-dis2-workbench.auth.us-east-1.amazoncognito.com',
  clientId: '7gnqvccl7aa7rec4f2n3ci04ac',
  userPoolId: 'us-east-1_ZvSkwkedO',
  identityPoolId: 'us-east-1:5c093d84-9b90-4e49-8197-6466df3fb4b9',
  userPoolWebClientId: '7gnqvccl7aa7rec4f2n3ci04ac',
};

const environmentConfigMap: Map<string, EnvironmentConfig> = new Map();
environmentConfigMap.set('dev', {
  region: REGION_NA,
  logisticsHostName: LOGISTICS_HOST_NAME_NA,
  appSyncUrl:
    // pointing to gamma NA backend
    'https://nn2jflwsmfc2lay5fc3ooyd4qq.appsync-api.us-east-1.amazonaws.com/graphql',
});
environmentConfigMap.set(ENV_BETA, {
  region: REGION_NA,
  logisticsHostName: LOGISTICS_HOST_NAME_NA,
  appSyncUrl:
    'https://efzoiooocfcclbg5ejb2lxhpjm.appsync-api.us-east-1.amazonaws.com/graphql',
});
environmentConfigMap.set(ENV_GAMMA_NA, {
  region: REGION_NA,
  logisticsHostName: LOGISTICS_HOST_NAME_NA,
  appSyncUrl:
    'https://nn2jflwsmfc2lay5fc3ooyd4qq.appsync-api.us-east-1.amazonaws.com/graphql',
});
environmentConfigMap.set(ENV_GAMMA_EU, {
  region: REGION_EU,
  logisticsHostName: LOGISTICS_HOST_NAME_EU,
  appSyncUrl:
    'https://x6fhgubnqveupaqdpjiz2k2msu.appsync-api.eu-west-1.amazonaws.com/graphql',
});
environmentConfigMap.set(ENV_GAMMA_FE, {
  region: REGION_FE,
  logisticsHostName: LOGISTICS_HOST_NAME_FE,
  appSyncUrl:
    'https://r655sk3qljbirnd5ksongzqfyi.appsync-api.us-west-2.amazonaws.com/graphql',
});
environmentConfigMap.set(ENV_GAMMA_IN, {
  region: REGION_EU,
  logisticsHostName: LOGISTICS_HOST_NAME_EU,
  appSyncUrl:
    'https://f3kijawrinad3egtkvl3bnfqae.appsync-api.eu-west-1.amazonaws.com/graphql',
});
environmentConfigMap.set(ENV_PROD_NA, {
  region: REGION_NA,
  logisticsHostName: LOGISTICS_HOST_NAME_NA,
  appSyncUrl:
    'https://oon57srxoffxxit5uywlgldwyi.appsync-api.us-east-1.amazonaws.com/graphql',
});
environmentConfigMap.set(ENV_PROD_EU, {
  region: REGION_EU,
  logisticsHostName: LOGISTICS_HOST_NAME_EU,
  appSyncUrl:
    'https://qrxlkw3ytfd5ziiv3jubziieru.appsync-api.eu-west-1.amazonaws.com/graphql',
});
environmentConfigMap.set(ENV_PROD_FE, {
  region: REGION_FE,
  logisticsHostName: LOGISTICS_HOST_NAME_FE,
  appSyncUrl:
    'https://r2r4ohxrv5aingclnleovbxjdi.appsync-api.us-west-2.amazonaws.com/graphql',
});
environmentConfigMap.set(ENV_PROD_IN, {
  region: REGION_EU,
  logisticsHostName: LOGISTICS_HOST_NAME_EU,
  appSyncUrl:
    'https://d35jp4n3hzaale4qi4tkwwhj6a.appsync-api.eu-west-1.amazonaws.com/graphql',
});

export default class EnvConfigHelper {
  static getCognitoConfig(): CognitoConfig {
    const useProductionCognito = UrlHelper.useProductionCognito();
    return useProductionCognito ? prodCognitoConfig : nonProdCognitoConfig;
  }

  static getEnvironmentConfig(): EnvironmentConfig {
    const environmentName = UrlHelper.getEnvironmentName();
    return environmentConfigMap.get(environmentName);
  }
}
