import * as _ from 'lodash';
import { BehaviorSubject, Observable, auditTime, merge } from 'rxjs';
import { MAP_VIEW_TRANSPORTERS } from '../util/ViewConstants';
import { Transporter } from '@amzn/gsf-dispatcher-schema';
import { reaction } from 'mobx';
import GeospatialHelper from 'map/GeospatialHelper';
import MapObject from 'map/MapObject';
import TimeHelper from '../util/TimeHelper';
import TransporterFilterController from 'controllers/TransporterFilterController';
import TransporterRankController from '../controllers/TransporterRankController';
import TransporterSortController from 'controllers/TransporterSortController';
import filterReactor from './filterReactor';
import orderReactor from './orderReactor';
import siteStore from '../stores/siteStore';
import transporterStore from 'stores/transporterStore';
import viewReactor from './viewReactor';
import viewStore from '../stores/viewStore';

class TransporterReactor {
  private transportersChangeSubject = new BehaviorSubject<boolean>(false);
  transportersChange$: Observable<boolean> =
    this.transportersChangeSubject.asObservable();

  private filteredTransportersChangeSubject = new BehaviorSubject<boolean>(
    false
  );
  filteredTransportersChange$: Observable<boolean> =
    this.filteredTransportersChangeSubject.asObservable();

  private transporterRankChangeSubject = new BehaviorSubject<boolean>(false);
  transporterRankChange$: Observable<boolean> =
    this.transporterRankChangeSubject.asObservable();

  private filteredTransportersRemainingStopsChangeSubject =
    new BehaviorSubject<boolean>(false);
  filteredTransportersRemainingStopsChange$: Observable<boolean> =
    this.filteredTransportersRemainingStopsChangeSubject.asObservable();

  private filteredTransportersLocationChangeSubject =
    new BehaviorSubject<boolean>(false);
  filteredTransportersLocationChange$: Observable<boolean> =
    this.filteredTransportersLocationChangeSubject.asObservable();

  private selectedTransporterIdChangeSubject = new BehaviorSubject<boolean>(
    false
  );
  selectedTransporterIdChange$: Observable<boolean> =
    this.selectedTransporterIdChangeSubject.asObservable();

  constructor() {
    reaction(
      () => {
        return transporterStore.transporters;
      },
      (transporters: Transporter[]) => {
        this.transportersChangeSubject.next(true);
      }
    );

    reaction(
      () => {
        return transporterStore.selectedTransporterId;
      },
      (selectedTransporterId) => {
        this.selectedTransporterIdChangeSubject.next(true);
      }
    );

    reaction(
      () => {
        return transporterStore.filteredTransporters;
      },
      (filteredTransporter: Transporter[]) => {
        this.filteredTransportersChangeSubject.next(true);
      }
    );

    reaction(
      () => {
        const { transporterRank } = transporterStore;
        const keys = Array.from(transporterRank.keys());
        return keys.map((key) => [key, transporterRank.get(key)]);
      },
      (transporters) => {
        this.transporterRankChangeSubject.next(true);
      }
    );

    reaction(
      () => {
        return transporterStore.filteredTransporters.map(
          (t) => t.remainingStops
        );
      },
      (transporters) => {
        this.filteredTransportersRemainingStopsChangeSubject.next(true);
      }
    );

    reaction(
      () => {
        return transporterStore.filteredTransporters.map(
          (t) => t.lastKnownLocation
        );
      },
      (transportersLocation) => {
        this.filteredTransportersLocationChangeSubject.next(true);
      }
    );

    /**
     * listen to changes in the transporters or the transporter filter selections and
     * perform transporter filter in a rate limited way
     * (max once per 200msec)
     */
    const rateLimitedObservableForTransporterFilter = merge(
      this.transportersChange$.pipe(),
      filterReactor.selectedTransporterFilterCodesChange$.pipe()
    ).pipe(auditTime(200));
    rateLimitedObservableForTransporterFilter.subscribe(async (event) => {
      if (event) {
        const { transporters } = transporterStore;
        const filteredTransporters: Transporter[] = [];
        filteredTransporters.push(
          ...TransporterFilterController.filterTransporters(transporters)
        );
        transporterStore.setFilteredTransporters(filteredTransporters);
      }
    });

    /**
     * listen to changes in the transporters and remove the selected transporter id
     * if the transporter id no longer exists in the transporters
     * */
    this.transportersChange$.subscribe(async (event) => {
      const { transporters, selectedTransporterId } = transporterStore;

      // check if selected transporter id no longer exist
      const validTransporterIds = transporters.map((t) => t.transporterId);
      if (!_.includes(validTransporterIds, selectedTransporterId)) {
        transporterStore.setSelectedTransporterId(undefined);
      }
    });

    /**
     * Listen to changes which go into the transporter rank algorithm and run ranking algo if any of the inputs change
     */
    const rateLimitedObservableForTransporterRank = merge(
      this.transportersChange$.pipe(),
      this.filteredTransportersRemainingStopsChange$.pipe(),
      this.filteredTransportersLocationChange$.pipe(),
      orderReactor.selectedOrderIdsChange$.pipe(),
      TimeHelper.minuteChange$.pipe()
    ).pipe(auditTime(1000));
    rateLimitedObservableForTransporterRank.subscribe(async (event) => {
      const { transporters } = transporterStore;
      const rankedTransporters =
        TransporterRankController.rankTransporters(transporters);
      transporterStore.setTransporterRank(rankedTransporters);
    });

    /**
     * Listen to changes in how transporters are sorted and resort if inputs change
     */
    const rateLimitedObservableForTransporterSort = merge(
      this.transporterRankChange$.pipe(),
      this.filteredTransportersChange$.pipe(),
      this.selectedTransporterIdChange$.pipe()
    ).pipe(auditTime(500));
    rateLimitedObservableForTransporterSort.subscribe(async (event) => {
      const { filteredTransporters } = transporterStore;
      const sortedTransporters =
        TransporterSortController.sortTransporters(filteredTransporters);
      transporterStore.setSortedTransporters(sortedTransporters);
    });

    /**
     * listen to the map resolution or center changes (since only the transporter groups in the map view extents are
     * shown) as well as the filtered transporter changes and map view change and rate limit the updates of the
     * transporter groups to at most once per 500 msec to keep the browser responsive during map panning or zooming
     * with mouse wheel
     * */
    const rateLimitedObservableForTransporters = merge(
      MapObject.mapResolutionChange$.pipe(),
      MapObject.mapCenterChange$.pipe(),
      this.filteredTransportersChange$.pipe(),
      this.filteredTransportersLocationChange$.pipe(),
      viewReactor.mapViewChange$.pipe()
    ).pipe(auditTime(500));
    rateLimitedObservableForTransporters.subscribe(async (event) => {
      const { mapView } = viewStore;
      const { selectedSite } = siteStore;
      if (selectedSite && mapView === MAP_VIEW_TRANSPORTERS) {
        const { filteredTransporters } = transporterStore;
        const transporterGroups = await GeospatialHelper.groupTransporters(
          filteredTransporters
        );
        transporterStore.setFilteredTransporterGroups(transporterGroups);
      } else {
        transporterStore.setFilteredTransporterGroups([]);
      }
    });
  }
}

export default new TransporterReactor();
