import {
  FEATURE_ATTRIBUTE_FEATURE_TYPE,
  FEATURE_TYPE_DIRECTED_LINE_SEGMENT,
  PROJECTION_GPS,
  PROJECTION_MAP,
} from '../../../map/MapConstants';
import { Feature } from 'ol';
import { Geometry, LineString } from 'ol/geom';
import { IReactionDisposer, reaction } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import MapLocation from '../../../map/model/MapLocation';
import MapObject from '../../../map/MapObject';
import React from 'react';

interface MapLineSegmentProps {
  startLocation: any;

  startPropertyName: string;
  endLocation: any;

  endPropertyName: string;
}

class MapLineSegmentComp extends React.Component<MapLineSegmentProps> {
  private mobxReactionDisposer: IReactionDisposer;

  private id = uuidv4();

  getId() {
    return this.id;
  }

  /**
   * The interaction with open layers maps needs to occur in the componentDidMount since the code
   * expects the html element with the id equal to 'transporter:<transporterId>' to exist in the dom
   * DO NOT EVER make the componentDidMount and componentWillUnmount methods async or it will cause
   * bugs in the number of features in the map since the sequence of add and removes could get out of
   * order and cause an incorrect number of open layers features to be rendered on the map.
   */
  componentDidMount() {
    const { startLocation, startPropertyName, endLocation, endPropertyName } =
      this.props;

    this.mobxReactionDisposer = reaction(
      () => {
        return [startLocation[startPropertyName], endLocation[endPropertyName]];
      },
      async (locations) => {
        const [startingLocation, endingLocation] = locations;
        MapObject.setFeatureGeometry(
          this.getId(),
          this.createGeometry(startingLocation, endingLocation)
        );
      }
    );

    const geometry = this.createGeometry(
      startLocation[startPropertyName],
      endLocation[endPropertyName]
    );
    if (geometry) {
      const feature = new Feature({
        geometry,
      });
      feature.setId(this.getId());
      feature.set(
        FEATURE_ATTRIBUTE_FEATURE_TYPE,
        FEATURE_TYPE_DIRECTED_LINE_SEGMENT
      );

      MapObject.addFeature(feature);
    }
  }

  componentWillUnmount() {
    MapObject.removeFeatureWithId(this.getId());
    if (this.mobxReactionDisposer) {
      this.mobxReactionDisposer();
    }
  }

  render() {
    // we need a render method so react doesn't complain
    return <div />;
  }

  private createGeometry(
    startingLocation: MapLocation | undefined | null,
    endingLocation: MapLocation | undefined | null
  ): Geometry | undefined {
    if (startingLocation && endingLocation) {
      const line = new LineString([
        [startingLocation.longitude, startingLocation.latitude],
      ]);
      line.appendCoordinate([
        endingLocation.longitude,
        endingLocation.latitude,
      ]);

      line.transform(PROJECTION_GPS, PROJECTION_MAP);
      return line;
    }
    return undefined;
  }
}

export default MapLineSegmentComp;
