import { MAP_VIEW_TRANSPORTERS } from '../../../util/ViewConstants';
import { Transporter } from '@amzn/gsf-dispatcher-schema';
import { observer } from 'mobx-react';
import MapTransporterAndRemainingStopsComp from './MapTransporterAndRemainingStopsComp';
import MapTransporterGroupComp from './MapTransporterGroupComp';
import React from 'react';
import TransporterGroup from '../../../map/model/TransporterGroup';
import transporterStore from '../../../stores/transporterStore';
import viewStore from '../../../stores/viewStore';

interface MapTransportersProps {
  transporterGroups: TransporterGroup[];
  transporters: Transporter[];
}

class MapTransportersComp extends React.Component<MapTransportersProps> {
  render() {
    const { transporterGroups } = this.props;
    const { selectedTransporterId, transporters } = transporterStore;
    const { mapView } = viewStore;

    const content = [];

    if (mapView === MAP_VIEW_TRANSPORTERS) {
      if (selectedTransporterId) {
        const selectedTransporter = transporters.find(
          (t) => t.transporterId === selectedTransporterId
        );
        if (selectedTransporter) {
          const key = `map-transporter${selectedTransporter.transporterId}`;
          content.push(
            <MapTransporterAndRemainingStopsComp
              key={key}
              transporter={selectedTransporter}
              renderStops={true}
              highlightTransporter={true}
            />
          );
        }
      } else {
        transporterGroups.forEach((tg) => {
          const key = `map-transporter-group${tg.entities
            .map((t) => t.transporterId)
            .join('~')}}-${tg.centroid.latitude},${tg.centroid.longitude}`;
          content.push(
            <MapTransporterGroupComp
              key={key}
              highlighted={false}
              transporterGroup={tg}
            />
          );
        });
      }
    }

    return content;
  }
}

export default observer(MapTransportersComp);
