import { observer } from 'mobx-react';
import Alert from '@amzn/meridian/alert';
import React from 'react';

export interface BannerProps {
  bannerLink: string;
  bannerEnable: boolean;
}
interface BannerState {
  popupOpened: boolean;
}

class BannerComp extends React.Component<BannerProps, BannerState> {
  constructor(props: BannerProps) {
    super(props);
  }

  componentDidMount() {
    this.setState({ popupOpened: this.props.bannerEnable });
  }

  closeBanner() {
    this.setState({ popupOpened: false });
  }

  render() {
    if (this.state?.popupOpened === true) {
      return (
        <div>
          <Alert type="informational" onClose={() => this.closeBanner()}>
            {this.props.bannerLink}
          </Alert>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default observer(BannerComp);
