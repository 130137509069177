import * as _ from 'lodash';
import {
  Address,
  Order,
  TransportationProviderType,
  Transporter,
  TransporterSchedulingType,
} from '@amzn/gsf-dispatcher-schema';
import {
  DSP_PROVIDER_TYPE,
  FLEX_INSTANT_PROVIDER_TYPE,
  FLEX_PROVIDER_TYPE,
} from './BusinessConstants';
import {
  GpsLocation,
  TransporterStop,
  TransporterStopType,
} from '../graphqlGenerated/graphql';
import { MINUTE } from './TimeHelper';
import AddressHelper from './AddressHelper';
import GeospatialHelper from '../map/GeospatialHelper';
import OrderHelper from './OrderHelper';
import orderStore from '../stores/orderStore';
import siteStore from '../stores/siteStore';
import transporterStore from '../stores/transporterStore';

const TRANSPORTER_REMAINING_TIME_MINUTES_DEFAULT = 30;

export default class TransporterHelper {
  static isAvailableTransporter(transporter: Transporter): boolean {
    const now = Date.now();
    const { selectedSite } = siteStore;
    const { selectedOrderIds } = orderStore;
    return (
      selectedOrderIds.length > 0 &&
      TransporterHelper.feasibleTransporter(transporter, now) &&
      TransporterHelper.noRemainingStops(transporter) &&
      GeospatialHelper.isNearbyTransporter(transporter, selectedSite)
    );
  }

  static feasibleTransporter(transporter: Transporter, now: number): boolean {
    const remainingTimeOnBlock =
      TransporterHelper.calculateRemainingBlockTimeInMinutes(transporter, now);
    const enoughTimeOnBlock =
      remainingTimeOnBlock >= TRANSPORTER_REMAINING_TIME_MINUTES_DEFAULT;

    const selectedWorkTime =
      OrderHelper.calculateRemainingMinutesNeededForSelectedOrders(now);
    const enoughTimeForSelectedWork = remainingTimeOnBlock >= selectedWorkTime;
    return enoughTimeOnBlock && enoughTimeForSelectedWork;
  }

  static noRemainingStops(transporter: Transporter): boolean {
    const remainingStops = transporter.remainingStops || [];
    return remainingStops.length === 0;
  }

  static sortRemainingStops(transporter: Transporter): void {
    const stops = transporter.remainingStops || [];
    if (!transporter.lastKnownLocation || stops.length === 0) {
      return;
    }

    const sortedStops: TransporterStop[] = [];
    let from = transporter.lastKnownLocation;
    while (stops.length > 0) {
      let closestIndex = -1;
      let closestDistance = Number.MAX_VALUE;
      for (let stopIndex = 0; stopIndex < stops.length; stopIndex++) {
        const stop = stops[stopIndex];
        const stopLocation = TransporterHelper.getStopLocation(
          stop,
          transporter
        );

        const distanceToStop = stopLocation
          ? GeospatialHelper.getDistance(from, stopLocation)
          : Number.MAX_VALUE;
        if (distanceToStop < closestDistance) {
          closestIndex = stopIndex;
          closestDistance = distanceToStop;
        }
      }

      if (closestIndex < 0) {
        // the remaining stops have no location, so just add the remaining stops
        // in order to the sorted stops and bail out
        sortedStops.push(...stops);
        transporter.remainingStops = sortedStops;
        return;
      }

      const closestStop = stops[closestIndex];
      sortedStops.push(closestStop);
      stops.splice(closestIndex, 1);
      from = TransporterHelper.getStopLocation(closestStop, transporter);
    }
    transporter.remainingStops = sortedStops;
  }

  static calculateRemainingBlockTimeInMinutes(
    transporter: Transporter,
    now: number = 0
  ): number {
    if (now === 0) {
      now = Date.now();
    }
    const sessionEndTimeString =
      transporter.transporterSession?.expectedSessionEndTime;
    if (sessionEndTimeString) {
      return (Number(sessionEndTimeString) - now) / MINUTE;
    }
    return Number.MIN_VALUE;
  }

  static remainingStopDistance(transporter: Transporter): number {
    let remainingDistanceMeters = 0;
    const transporterCurrentLocation = transporter.lastKnownLocation;
    const remainingStops = transporter.remainingStops || [];
    for (let i = 0; i < remainingStops.length; i++) {
      const stop = remainingStops[i];
      const stopLocation = TransporterHelper.getStopLocation(stop, transporter);
      if (stopLocation) {
        if (i === 0) {
          remainingDistanceMeters += GeospatialHelper.getDistance(
            transporterCurrentLocation,
            stopLocation
          );
        } else {
          const previousStop = remainingStops[i - 1];
          const previousStopLocation = TransporterHelper.getStopLocation(
            previousStop,
            transporter
          );
          if (previousStopLocation) {
            remainingDistanceMeters += GeospatialHelper.getDistance(
              previousStopLocation,
              stopLocation
            );
          }
        }
      }
    }

    return remainingDistanceMeters;
  }
  static findTransporterById(transporterId: string): Transporter {
    const { transporters } = transporterStore;
    return transporters.find((t) => t.transporterId === transporterId);
  }

  static getTransporterName(transporter: Transporter): string {
    const name = transporter?.name;
    if (name) {
      return _.startCase(_.toLower(name));
    }
    return undefined;
  }

  static getTransporterType(transporter: Transporter): string {
    if (transporter.providerType === TransportationProviderType.Dsp) {
      return DSP_PROVIDER_TYPE;
    }
    const isFlexInstantOfferSession =
      transporter?.transporterSession?.schedulingType ===
      TransporterSchedulingType.Instant;
    return isFlexInstantOfferSession
      ? FLEX_INSTANT_PROVIDER_TYPE
      : FLEX_PROVIDER_TYPE;
  }

  static getUniqueTransporterCompanyNames(
    transporters: Transporter[]
  ): string[] {
    return _.uniq(
      transporters.map((t) => t?.companyName).filter((name) => !!name)
    ).sort();
  }

  static getUniqueTransporterProviderTypes(
    transporters: Transporter[]
  ): string[] {
    return _.uniq(
      transporters
        .map((t) => this.getTransporterType(t))
        .filter((providerType) => !!providerType)
    ).sort();
  }

  /**
   * replace the existing transporter remaining stops with stops based on the transporter's
   * remaining orders.  Build a map of pickup address to order ids as well as delivery address
   * to order ids by iterating over the remaining orders which will ensure that there are no
   * duplicate pickups or deliveries for the same address.  Then use the map keys to create
   * a pickup stop for each pickup map key and a delivery stop for each delivery map key.
   *
   * Note that this function will not sort the newly created remaining stops!!
   * @param transporter
   */
  static setRemainingStopsBasedOnRemainingOrders(
    transporter: Transporter
  ): void {
    const orders = transporter.remainingOrders;

    const pickUpAddressMap: Map<string, string[]> = new Map();
    const deliveryAddressMap: Map<string, string[]> = new Map();
    orders.forEach((order) => {
      TransporterHelper.addOrderIdToMap(
        pickUpAddressMap,
        order.pickupAddress,
        order
      );
      TransporterHelper.addOrderIdToMap(
        deliveryAddressMap,
        order.deliveryAddress,
        order
      );
    });

    // create a stop for each pickup address
    const newTransporterStops: TransporterStop[] = [];
    const pickupAddressKeys = Array.from(pickUpAddressMap.keys());
    pickupAddressKeys.forEach((key) => {
      const orderIds = pickUpAddressMap.get(key);
      const pickupStop: TransporterStop = {
        stopType: TransporterStopType.Pickup,
        orderIds,
      };
      newTransporterStops.push(pickupStop);
    });

    // create a stop for each delivery address
    const deliveryAddressKeys = Array.from(deliveryAddressMap.keys());
    deliveryAddressKeys.forEach((key) => {
      const orderIds = deliveryAddressMap.get(key);
      const deliveryStop: TransporterStop = {
        stopType: TransporterStopType.Delivery,
        orderIds,
      };
      newTransporterStops.push(deliveryStop);
    });

    // replace the remaining stop
    transporter.remainingStops = newTransporterStops;
  }

  private static addOrderIdToMap(
    map: Map<string, string[]>,
    address: Address,
    order: Order
  ) {
    const key = AddressHelper.getDisplayAddress(address);
    if (map.has(key)) {
      map.get(key).push(order.orderId);
    } else {
      map.set(key, [order.orderId]);
    }
  }

  private static getStopLocation(
    stop: TransporterStop,
    transporter: Transporter
  ): GpsLocation {
    const orderIds = stop.orderIds || [];
    if (orderIds.length === 0) {
      return undefined;
    }
    const firstOrderId = orderIds[0];
    const orders = transporter.remainingOrders || [];
    const order = orders.find((o) => o.orderId === firstOrderId);
    if (order) {
      if (stop.stopType === TransporterStopType.Pickup) {
        return order.pickupLocation;
      } else {
        return order.deliveryLocation;
      }
    }
    return undefined;
  }

  static getServiceTypeId(providerReservationId: string): string {
    if (!providerReservationId) {
      return '';
    }
    const pattern = /amzn1\.flex\.st\.v1\.([^.]+)/;
    const match = providerReservationId.match(pattern);
    if (match && match.length > 1) {
      return match[0];
    }
    return '';
  }
}
