import { observer } from 'mobx-react';
import React from 'react';
import Row from '@amzn/meridian/row';
import SearchField, {
  SearchSuggestion,
  SearchSuggestionFooter,
} from '@amzn/meridian/search-field';
import SiteController from '../../controllers/SiteController';
import Text from '@amzn/meridian/text';
import siteStore from '../../stores/siteStore';

export interface SiteSearchProps {}

class SiteSearchComp extends React.Component<SiteSearchProps> {
  render() {
    const { siteSearchString } = siteStore;

    const searchSuggestions = [];

    const { sitesLoaded } = siteStore;

    if (sitesLoaded) {
      siteStore.filteredSiteNames.forEach((siteName) => {
        const searchSuggestion = (
          <SearchSuggestion
            key={siteName.siteCode}
            onClick={() => SiteController.handleSelectSiteName(siteName)}
          >
            {SiteController.getSearchable(siteName)}
          </SearchSuggestion>
        );
        searchSuggestions.push(searchSuggestion);
      });
    }

    const placeHolder = sitesLoaded ? 'Search for site' : 'Loading sites';
    const numberFilteredSites = siteStore.filteredSiteNames.length;
    const numberSites = siteStore.allSiteNames.length;

    return (
      <div className={'search-bar'}>
        <Row>
          <SearchField
            value={siteSearchString}
            width={'27em'}
            onChange={(value) =>
              SiteController.handleSiteNameSearchStringChange(value)
            }
            placeholder={`${placeHolder}...`}
            onSubmit={() =>
              SiteController.handleSiteNameSearchEnterKeyPressed()
            }
            onBlur={() => SiteController.handleSiteNameSearchLoseFocus()}
            searchButton={true}
          >
            {searchSuggestions}
            <SearchSuggestionFooter>
              <Row alignmentHorizontal="justify">
                <Text>{`${numberFilteredSites} of ${numberSites} sites${
                  numberFilteredSites === 1 ? ' (Press enter)' : ''
                }`}</Text>
              </Row>
            </SearchSuggestionFooter>
          </SearchField>
        </Row>
      </div>
    );
  }
}

export default observer(SiteSearchComp);
