import * as _ from 'lodash';
import { action, makeObservable, observable } from 'mobx';

class SearchByIdStore {
  searchByPartialStringInput: string = '';
  searchByIdValues: string[] = [];

  constructor() {
    makeObservable(this, {
      searchByPartialStringInput: observable,
      searchByIdValues: observable,
      setSearchByPartialStringInput: action,
      setSearchByIdValues: action,
      addSearchByIdValue: action,
      removeSearchByIdValue: action,
      clearSearchByIdValues: action,
    });
  }

  setSearchByPartialStringInput(searchByPartialStringInput: string) {
    this.searchByPartialStringInput = searchByPartialStringInput;
  }

  setSearchByIdValues(searchByIdValues: string[]) {
    this.searchByIdValues = searchByIdValues;
  }

  addSearchByIdValue(searchByIdValue: string) {
    const { searchByIdValues } = this;
    if (!_.includes(searchByIdValues, searchByIdValue)) {
      const newSearchByIdValues = _.clone(searchByIdValues);
      newSearchByIdValues.push(searchByIdValue);
      this.searchByIdValues = newSearchByIdValues;
    }
  }

  removeSearchByIdValue(searchByIdValue: string) {
    const index = this.searchByIdValues.indexOf(searchByIdValue);
    if (index > -1) {
      const newSearchByIdValues = _.clone(this.searchByIdValues);
      newSearchByIdValues.splice(index, 1);
      this.searchByIdValues = newSearchByIdValues;
    }
  }

  clearSearchByIdValues() {
    this.searchByIdValues = [];
  }
}

export default new SearchByIdStore();
