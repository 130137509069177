export default class StringHelper {
  static makeCountDisplay(count: number, unit: string): string {
    return `${count} ${unit}${count !== 1 ? 's' : ''}`;
  }

  static makeCountOfCountDisplay(
    count: number,
    ofCount: number,
    unit: string
  ): string {
    return `${count} of ${ofCount} ${unit}${ofCount !== 1 ? 's' : ''}`;
  }
}
