import { observer } from 'mobx-react';
import Box from '@amzn/meridian/box';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import React from 'react';
import Row from '@amzn/meridian/row';
import SearchTransporterByNameController from 'controllers/SearchTransporterByNameController';
import Text from '@amzn/meridian/text';
import trashTokens from '@amzn/meridian-tokens/base/icon/trash';

export interface SearchByNameValueProps {
  value: string;
}

class SearchTransporterByNameValueComp extends React.Component<SearchByNameValueProps> {
  render() {
    const { value } = this.props;
    const index = value.indexOf(' ');
    const displayText = index > 0 ? value.substring(index) : value;

    return (
      <div title={value}>
        <Box type="outline" spacingInset="none 200 none 200">
          <Row spacing="100">
            <Text>{displayText}</Text>
            <Button
              type="icon"
              size="small"
              onClick={() =>
                SearchTransporterByNameController.handleRemoveSearchByTransporterNameValue(
                  value
                )
              }
            >
              <Icon tokens={trashTokens} />
            </Button>
          </Row>
        </Box>
      </div>
    );
  }
}

export default observer(SearchTransporterByNameValueComp);
