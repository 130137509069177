import * as _ from 'lodash';
import {
  GetAllSiteNamesDocument,
  GetAllSiteNamesQueryVariables,
  GetSiteDocument,
  GetSiteQueryVariables,
  SiteName,
  SiteNamesPage,
  SubscribeToUpdateSiteDocument,
} from '../graphqlGenerated/graphql';
import { Site } from '@amzn/gsf-dispatcher-schema';
import Performance from '../logging/Performance';
import Service from '../services/Service';
import Subscription from './Subscription';

export const SITE_NAME_QUERY_LIMIT = 1000;

const validSitesStartingWithV = ['VBBA'];

const amazonLogisticsSiteNames = [
  'Amazon Logistics', // EU
  'Amazon Logisticts', // EU mispelling
  'Amazon.com', // US and FE
  'Amazon.ca', // Canada
  'Amazon.sg', // FE
];

export default class SiteService {
  static async getSite(siteCode: string): Promise<Site | undefined> {
    const variables: GetSiteQueryVariables = {
      siteCode,
    };
    return Service.query(GetSiteDocument, variables, true);
  }

  static async getAllSiteNames(): Promise<SiteName[]> {
    const operationName = 'getAllSiteNames';
    const startTime = Date.now();
    const sites: SiteName[] = [];
    let nextToken = undefined;
    do {
      const variables: GetAllSiteNamesQueryVariables = {
        nextToken,
        limit: SITE_NAME_QUERY_LIMIT,
      };
      const siteNamesPage: SiteNamesPage = await Service.query(
        GetAllSiteNamesDocument,
        variables
      );
      const sitesForPage = siteNamesPage.sites;
      if (sitesForPage) {
        sites.push(...sitesForPage);
      }
      nextToken = siteNamesPage.nextToken;
    } while (nextToken);
    // TODO exclude sites in a more robust way using business from RAS
    const values = sites.filter((s) => {
      const { siteName } = s;
      return !!siteName && SiteService.isValidSite(s);
    });
    Performance.log(operationName, startTime);
    return values;
  }

  static subscribeToUpdateSite(
    siteCode: string,
    entityReceiver: (Site) => void
  ): Subscription<Site> {
    return Service.subscribe(
      SubscribeToUpdateSiteDocument,
      { siteCode },
      entityReceiver
    );
  }

  private static isValidSite(s: SiteName): boolean {
    return !SiteService.isAmazonLogisticsSite(s);
  }

  private static isAmazonLogisticsSite(s: SiteName): boolean {
    const { siteName } = s;
    return _.some(
      amazonLogisticsSiteNames,
      (amazonLogisticsSiteName) =>
        siteName.toLowerCase().indexOf(amazonLogisticsSiteName.toLowerCase()) >
        -1
    );
  }
}
