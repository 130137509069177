import { ERROR_MESSAGE_TIMEOUT } from '../util/TimeHelper';
import AssignmentHelper from '../util/AssignmentHelper';
import ErrorHelper from '../util/ErrorHelper';
import Log from '../logging/Log';
import MessagesController from './MessagesController';
import OperationController from './OperationController';
import OrderUnassignmentController from './OrderUnassignmentController';
import Performance from '../logging/Performance';
import RouteUnassignmentController from './RouteUnassignmentController';
import assignmentStore from '../stores/assignmentStore';

export default class UnassignmentController {
  static async startUnassignment(): Promise<void> {
    if (AssignmentHelper.isSsdSite()) {
      await RouteUnassignmentController.startUnassignment();
    } else {
      await OrderUnassignmentController.startUnassignment();
    }
  }

  static async cancelUnassignment(): Promise<void> {
    const operationName = AssignmentHelper.getUnassignmentOperationName();
    Log.info('Cancel un-assignment', operationName);
    assignmentStore.clearConfirmationMessages();
  }

  static async confirmUnassignment(): Promise<void> {
    const startTime = Date.now();
    assignmentStore.clearConfirmationMessages();
    const operationName = AssignmentHelper.getUnassignmentOperationName();

    try {
      if (AssignmentHelper.isSsdSite()) {
        await RouteUnassignmentController.confirmUnassignment();
      } else {
        await OrderUnassignmentController.confirmUnassignment();
      }
    } catch (error) {
      const msg = `General failure during un-assignment: ${ErrorHelper.getErrorMessage(
        error
      )}`;
      OperationController.completeOperation();
      MessagesController.addAlertMessage(msg, ERROR_MESSAGE_TIMEOUT);
      Log.error(msg, operationName);
    }

    Performance.log(operationName, startTime);
  }
}
