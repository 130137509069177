import { IReactionDisposer, reaction } from 'mobx';
import { Overlay } from 'ol';
import { PROJECTION_MAP } from '../../../map/MapConstants';
import { fromLonLat } from 'ol/proj';
import { v4 as uuidv4 } from 'uuid';
import MapLocation from '../../../map/model/MapLocation';
import MapObject from '../../../map/MapObject';
import React from 'react';
import _ from 'lodash';

class MapLocatableEntityComp<T, S> extends React.Component<T, S> {
  private mapOverlay: Overlay | undefined;

  private mobxReactionDisposer: IReactionDisposer;
  private id = uuidv4();
  private readonly locatableEntityPropertyName: string;
  private className: string;

  private readonly locationPropertyName: string;

  /**
   *
   * @param props
   * @param locatableEntityPropertyName the path on the object
   * @param locationPropertyName
   */
  constructor(
    props: T,
    state: S,
    locatableEntityPropertyName: string,
    locationPropertyName: string = 'location',
    className: string = ''
  ) {
    super(props);
    this.state = state;
    this.locatableEntityPropertyName = locatableEntityPropertyName;
    this.locationPropertyName = locationPropertyName;
    this.className = className;
  }

  getId() {
    return this.id;
  }

  /**
   * The interaction with open layers maps needs to occur in the componentDidMount since the code
   * expects the html element with the id equal to the components id (uuid) to exist in the dom.
   * DO NOT EVER make the componentDidMount and componentWillUnmount methods async or it will cause
   * bugs in the number of overlays in the map since the sequence of add and removes could get out of
   * order and cause an incorrect number of open layers overlays to be rendered on the map.
   */
  componentDidMount() {
    const locatableEntity = _.at(this.props, [
      this.locatableEntityPropertyName,
    ])[0];
    const location = locatableEntity[this.locationPropertyName] as MapLocation;

    // add a mobx reaction to the transporter location change and set the overlay position to the new location
    this.mobxReactionDisposer = reaction(
      () => locatableEntity[this.locationPropertyName],
      (location) => {
        // set the overlay to the object's position since it has changed
        if (location && this.mapOverlay) {
          const { latitude, longitude } = location;
          this.mapOverlay.setPosition(
            fromLonLat([longitude, latitude], PROJECTION_MAP)
          );
        }
      }
    );

    if (location) {
      const { latitude, longitude } = location;
      const htmlElement = document.getElementById(this.id);
      this.mapOverlay = new Overlay({
        id: this.id,
        element: htmlElement,
        positioning: 'center-center',
        position: fromLonLat([longitude, latitude], PROJECTION_MAP),
        className: this.className,
      });
      MapObject.addOverlay(this.mapOverlay);
    }
  }

  componentWillUnmount() {
    MapObject.removeOverlay(this.mapOverlay);
    if (this.mobxReactionDisposer) {
      this.mobxReactionDisposer();
    }
  }
}

export default MapLocatableEntityComp;
