import { Order, Route, TransporterStopType } from '@amzn/gsf-dispatcher-schema';
import { observer } from 'mobx-react';
import MapLineSegmentComp from '../common/MapLineSegmentComp';
import MapOrderGroupComp from '../order/MapOrderGroupComp';
import MapTransporterStopComp from '../common/MapTransporterStopComp';
import OrderGroup from '../../../map/model/OrderGroup';
import React from 'react';
import routeStore from '../../../stores/routeStore';

interface MapRouteProps {
  route: Route;
}

class MapRouteComp extends React.Component<MapRouteProps> {
  render() {
    const { route } = this.props;

    const content = [];

    const key = `map-route${route.routeId}`;

    const orderMap = new Map<string, Order>();
    const orders = route.orders || [];
    for (const order of orders) {
      orderMap.set(order.orderId, order);
    }

    const orderGroupMap = new Map<string, OrderGroup>();
    const { selectedRouteOrderGroups } = routeStore;
    for (const orderGroup of selectedRouteOrderGroups) {
      for (const order of orderGroup.entities) {
        orderGroupMap.set(order.orderId, orderGroup);
      }
    }

    // filter out any route stop which has no order ids since only stops associated to an order affect rendering
    const routeStops = route.routeStops
      ? route.routeStops.filter(
          (rs) =>
            rs.orderIds &&
            rs.orderIds.length > 0 &&
            orderMap.has(rs.orderIds[0]) &&
            orderGroupMap.has(rs.orderIds[0])
        )
      : [];

    const deliveryOrderIdsRendered: string[] = [];
    for (let i = 1; i < routeStops.length; i++) {
      const thisStop = routeStops[i];
      const previousStop = routeStops[i - 1];
      const thisOrder = orderMap.get(thisStop.orderIds[0]);
      const thisOrderGroup = orderGroupMap.get(thisStop.orderIds[0]);
      const previousOrder = orderMap.get(previousStop.orderIds[0]);
      const previousOrderGroup = orderGroupMap.get(previousStop.orderIds[0]);

      const startLocationObject: Order | OrderGroup =
        previousStop.stopType === TransporterStopType.Pickup
          ? previousOrder
          : previousOrderGroup;
      const startLocationObjectPropertyName: string =
        previousStop.stopType === TransporterStopType.Pickup
          ? 'pickupLocation'
          : 'centroid';
      const endLocationObject: Order | OrderGroup =
        thisStop.stopType === TransporterStopType.Pickup
          ? thisOrder
          : thisOrderGroup;
      const endLocationObjectPropertyName: string =
        thisStop.stopType === TransporterStopType.Pickup
          ? 'pickupLocation'
          : 'centroid';

      const lineSegmentStartKey = `order${
        previousStop.stopType === TransporterStopType.Pickup
          ? previousOrder.orderId
          : `${previousOrder.orderId}-${previousOrderGroup.entities
              .map((o) => o.orderId)
              .join('~')}`
      }${
        routeStops[i - 1].stopType === TransporterStopType.Pickup
          ? 'pickup'
          : 'delivery'
      }`;
      const lineSegmentEndKey = `order${
        thisStop.stopType === TransporterStopType.Pickup
          ? thisOrder.orderId
          : `${thisOrder.orderId}-${thisOrderGroup.entities
              .map((o) => o.orderId)
              .join('~')}`
      }${
        routeStops[i].stopType === TransporterStopType.Pickup
          ? 'pickup'
          : 'delivery'
      }`;

      // push the directed line segment
      // it is important for the key to contain both endpoints in the key name so that react
      // will properly destroy/create new instances of the vector.
      const lineSegmentKey = `route${route.routeId}:${lineSegmentStartKey}:${lineSegmentEndKey}`;
      content.push(
        <MapLineSegmentComp
          key={lineSegmentKey}
          startLocation={startLocationObject}
          startPropertyName={startLocationObjectPropertyName}
          endLocation={endLocationObject}
          endPropertyName={endLocationObjectPropertyName}
        />
      );

      if (i === 1) {
        const key = `route${route.routeId}${lineSegmentStartKey}`;
        if (previousStop.stopType === TransporterStopType.Pickup) {
          content.push(
            <MapTransporterStopComp
              key={key}
              stop={previousStop}
              order={previousOrder}
            />
          );
        } else {
          content.push(
            <MapOrderGroupComp key={key} orderGroup={previousOrderGroup} />
          );
        }
      }

      const key = `route${route.routeId}${lineSegmentEndKey}`;
      if (thisStop.stopType === TransporterStopType.Pickup) {
        content.push(
          <MapTransporterStopComp key={key} stop={thisStop} order={thisOrder} />
        );
      } else {
        content.push(
          <MapOrderGroupComp key={key} orderGroup={thisOrderGroup} />
        );
      }
    }

    return <div>{content}</div>;
  }
}

export default observer(MapRouteComp);
