import { observer } from 'mobx-react';
import Loader from '@amzn/meridian/loader';
import Modal from '@amzn/meridian/modal';
import React from 'react';
import Row from '@amzn/meridian/row';
import operationStore from '../../stores/operationStore';

export interface SiteMaskProps {}

class SiteMaskComp extends React.Component<SiteMaskProps> {
  render() {
    const { operationName } = operationStore;
    return (
      operationName && (
        <Modal
          title={`${operationName}...`}
          open={!!operationName}
          scrollContainer="viewport"
        >
          <Row alignmentHorizontal="center">
            <Loader />
          </Row>
        </Modal>
      )
    );
  }
}

export default observer(SiteMaskComp);
