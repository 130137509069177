import { KatalMetricEntity } from '@amzn/katal-metrics-driver-aws-services';
import { metricsPublisher } from './KatalMetricPublishers';

export default class Performance {
  static log(
    operation: string,
    startTime: number,
    endTime: number = undefined
  ) {
    try {
      if (!endTime) {
        endTime = Date.now();
      }
      const durationMillis = endTime - startTime;
      const performance: KatalMetricEntity = new KatalMetricEntity({
        type: 'Performance',
        operation,
        durationMillis,
      });
      metricsPublisher.publish(performance);
    } catch (e) {
      // do nothing
    }
  }
}
