import { BehaviorSubject, Observable, auditTime, merge } from 'rxjs';
import { reaction } from 'mobx';
import OrderSortController from '../controllers/OrderSortController';
import RouteSortController from '../controllers/RouteSortController';
import orderReactor from './orderReactor';
import orderStore from '../stores/orderStore';
import routeReactor from './routeReactor';
import routeStore from '../stores/routeStore';
import sortStore from 'stores/sortStore';

class SortReactor {
  private selectedSortCodeChangeSubject = new BehaviorSubject<boolean>(false);

  selectedSortCodeChange$: Observable<boolean> =
    this.selectedSortCodeChangeSubject.asObservable();
  constructor() {
    reaction(
      () => {
        return sortStore.selectedSortCode;
      },
      (sortType: string) => {
        this.selectedSortCodeChangeSubject.next(true);
      }
    );

    /**
     * listen to changes in the filtered orders, the sort method, or selected order ids and
     * perform order resort in a rate limited way
     * (max once per 500msec)
     */
    const rateLimitedObservableForOrderSort = merge(
      orderReactor.filteredOrdersChange$.pipe(),
      this.selectedSortCodeChange$.pipe(),
      orderReactor.selectedOrderIdsChange$.pipe(),
      orderReactor.orderAssignmentChange$.pipe()
    ).pipe(auditTime(500));
    rateLimitedObservableForOrderSort.subscribe(async (event) => {
      const { filteredOrders } = orderStore;
      const sortedOrders = OrderSortController.sortOrders(filteredOrders);
      orderStore.setSortedOrders(sortedOrders);
    });

    /**
     * listen to changes in the filtered route or the sort method and
     * perform route resort in a rate limited way
     * (max once per 500msec)
     */
    const rateLimitedObservableForRouteSort = merge(
      routeReactor.filteredRoutesChange$.pipe(),
      this.selectedSortCodeChange$.pipe(),
      routeReactor.routeAssignmentChange$.pipe(),
      orderReactor.orderAssignmentChange$.pipe()
    ).pipe(auditTime(500));
    rateLimitedObservableForRouteSort.subscribe(async (event) => {
      const { filteredRoutes } = routeStore;
      const sortedRoutes = RouteSortController.sortRoutes(filteredRoutes);
      routeStore.setSortedRoutes(sortedRoutes);
    });
  }
}

export default new SortReactor();
