export const FILTER_DELIMITER = ': ';

export const PREFIX_ASSIGNMENT = 'Assignment';

export const PREFIX_PICKUP_ADDRESS = 'Pickup Address';
export const PREFIX_DELIVERY_WINDOW = 'Delivery Window';

export const PREFIX_COMPANY_NAME = 'Company';
export const PREFIX_PROVIDER_TYPE = 'Type';
export const PREFIX_TRANSPORTER_NAME = 'Name';

export const PREFIX_TRANSPORTER_WITHIN_DISTANCE = 'Within Distance (m)';
export const TRANSPORTER_WITHIN_DISTANCE_200 = '200';

export const PREFIX_NUMBER_OF_STOPS = 'Stops';
export const STOPS_0 = '0';

export const PREFIX_TIME_LEFT_ON_BLOCK = 'Remaining time on block (min)';
export const TIME_LEFT_ON_BLOCK_30 = '30+';

export const PREFIX_ORDER_ID = 'Order Id';
export const PREFIX_ROUTE_ID = 'Route Id';

export const ASSIGNMENT_ASSIGNED = 'Assigned';
export const ASSIGNMENT_UNASSIGNED = 'Unassigned';

export const PREFIX_ROUTE_LOCK = 'Route Lock';

export const ROUTE_LOCK_LOCKED = 'Locked';

export const ROUTE_STORE_STATUS_ROUTE_LOCKED = 'ROUTE_LOCKED';

export interface FilterOption {
  code: string;
}
