import { AlertType } from '@amzn/meridian/alert/alert';
import { MessageType } from '../../messages/model/MessageType';
import { observer } from 'mobx-react';
import Alert from '@amzn/meridian/alert';
import MessagesController from '../../controllers/MessagesController';
import React from 'react';
import Toaster from '@amzn/meridian/toaster';
import messagesStore from '../../stores/messagesStore';

export interface MessagesProps {}

class MessagesComp extends React.Component<MessagesProps> {
  render() {
    const { messages } = messagesStore;
    return (
      <Toaster toasts={messages} onCloseToast={this.onCloseMessage}>
        {(toast) => (
          <Alert
            type={this.getAlertType(toast.type)}
            toast={true}
            onClose={toast.onClose}
          >
            {toast.message}
          </Alert>
        )}
      </Toaster>
    );
  }

  onCloseMessage(messageId: string) {
    MessagesController.removeMessageWithId(messageId);
  }

  getAlertType(messageType: MessageType): AlertType {
    switch (messageType) {
      case MessageType.Success:
        return 'success';
      case MessageType.Informational:
        return 'informational';
      case MessageType.Warning:
        return 'warning';
      default:
        return 'error';
    }
  }
}

export default observer(MessagesComp);
