import {
  CUSTOM_SENDER,
  KatalMetricsDriverAws,
  KatalMetricsDriverAwsOptions,
} from '@amzn/katal-metrics-driver-aws-services';
import { LOG_BUFFER_TIME_SECONDS } from './LogConstants';
import { senderFunction } from './AppSyncSender';

const myConfig: KatalMetricsDriverAwsOptions = {
  serviceOptions: [
    {
      serviceName: CUSTOM_SENDER,
      customMetricSender: {
        sendMetrics: senderFunction,
      },
      bufferTimeSeconds: LOG_BUFFER_TIME_SECONDS,
    },
  ],
};
export default KatalMetricsDriverAws.getInstance(myConfig);
