import { MAP_VIEW_ROUTES } from '../../../util/ViewConstants';
import { Route } from '@amzn/gsf-dispatcher-schema';
import { observer } from 'mobx-react';
import MapRouteComp from './MapRouteComp';
import React from 'react';
import routeStore from '../../../stores/routeStore';
import viewStore from '../../../stores/viewStore';

interface MapRoutesProps {
  routes: Route[];
}

class MapRoutesComp extends React.Component<MapRoutesProps> {
  render() {
    const { selectedRouteId, routes } = routeStore;
    const { mapView } = viewStore;

    const content = [];

    if (mapView === MAP_VIEW_ROUTES) {
      if (selectedRouteId) {
        const selectedRoute = routes.find((r) => r.routeId === selectedRouteId);
        if (selectedRoute) {
          const key = `map-route${selectedRoute.routeId}`;
          content.push(<MapRouteComp key={key} route={selectedRoute} />);
        }
      }
    }

    return content;
  }
}

export default observer(MapRoutesComp);
