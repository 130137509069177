import { ERROR_MESSAGE_TIMEOUT } from '../util/TimeHelper';
import AssignmentHelper from '../util/AssignmentHelper';
import ErrorHelper from '../util/ErrorHelper';
import Log from '../logging/Log';
import MessagesController from './MessagesController';
import OperationController from './OperationController';
import OrderAssignmentController from './OrderAssignmentController';
import Performance from '../logging/Performance';
import RouteAssignmentController from './RouteAssignmentController';
import assignmentStore from '../stores/assignmentStore';

export default class AssignmentController {
  static async startAssignment(): Promise<void> {
    if (AssignmentHelper.isSsdSite()) {
      await RouteAssignmentController.startAssignment();
    } else {
      await OrderAssignmentController.startAssignment();
    }
  }

  static async cancelAssignment(): Promise<void> {
    const operationName = AssignmentHelper.getAssignmentOperationName();
    Log.info('Cancel assignment', operationName);
    assignmentStore.clearConfirmationMessages();
  }

  static async confirmAssignment(): Promise<void> {
    const startTime = Date.now();
    assignmentStore.clearConfirmationMessages();
    const operationName = AssignmentHelper.getAssignmentOperationName();

    try {
      if (AssignmentHelper.isSsdSite()) {
        await RouteAssignmentController.confirmAssignment();
      } else {
        await OrderAssignmentController.confirmAssignment();
      }
    } catch (error) {
      const msg = `General failure during assignment: ${ErrorHelper.getErrorMessage(
        error
      )}`;
      OperationController.completeOperation();
      MessagesController.addAlertMessage(msg, ERROR_MESSAGE_TIMEOUT);
      Log.error(msg, operationName);
    }

    Performance.log(operationName, startTime);
  }
}
