import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { CognitoUser } from 'amazon-cognito-identity-js';
import MessagesController from '../controllers/MessagesController';
import TimeHelper, { ERROR_MESSAGE_TIMEOUT } from '../util/TimeHelper';
import UrlHelper from '../util/UrlHelper';
import authStore from '../stores/authStore';
import jwtDecode from 'jwt-decode';

export const JWT_CLAIM_PREFERRED_USER_NAME = 'preferred_username';

export default class AuthHelper {
  static existingPromise: Promise<string> | null;

  static async signOut(): Promise<void> {
    await Auth.signOut();
  }

  static async getJwt(): Promise<string> {
    if (AuthHelper.existingPromise) {
      return AuthHelper.existingPromise;
    } else {
      AuthHelper.existingPromise = new Promise(async (resolve, reject) => {
        try {
          await TimeHelper.waitTillPredicateIsTrue(
            () => !AuthHelper.isAuthCodeInParameters(),
            true
          );
          const user = (await Auth.currentAuthenticatedUser()) as CognitoUser;
          // if an exception wasn't thrown during the Auth.currentAuthenticatedUser, then
          // the id token jwt will be populated
          AuthHelper.existingPromise = null;
          const jwt = user.getSignInUserSession()?.getIdToken().getJwtToken();
          if (jwt) {
            AuthHelper.setUserState(jwt);
            resolve(jwt);
          } else {
            MessagesController.addAlertMessage(
              'Unable to get the user login token',
              ERROR_MESSAGE_TIMEOUT
            );
            reject(new Error('Unable to get the user login token'));
          }
        } catch (e) {
          // this will cause a redirect to Cognito login, so don't need to worry about resolving the promise
          // or setting the existing promise to null
          await Auth.federatedSignIn({
            provider: CognitoHostedUIIdentityProvider.Cognito,
          });
        }
      });
      return AuthHelper.existingPromise;
    }
  }

  private static setUserState(jwt: string) {
    const parsedJwt = jwtDecode(jwt);
    const preferredUsername = parsedJwt[JWT_CLAIM_PREFERRED_USER_NAME];
    authStore.setPreferredUsername(preferredUsername);
  }

  private static isAuthCodeInParameters(): boolean {
    return !!UrlHelper.getParameterValue('code');
  }
}
