import {
  MutationUiLogsArgs,
  UiLogsDocument,
} from '../graphqlGenerated/graphql';
import Service from './Service';

export default class UILogService {
  static async sendLogs(siteCode: string, logs: string[]): Promise<number> {
    const variables: MutationUiLogsArgs = {
      siteCode,
      logs,
    };
    return Service.query(UiLogsDocument, variables);
  }
}
