import { BehaviorSubject, Observable } from 'rxjs';
import { CONNECTION_STATE_CHANGE, ConnectionState } from '@aws-amplify/pubsub';
import { Hub } from 'aws-amplify';
import { NetworkStatusHelper } from '../util/NetworkStatusHelper';
import Log from '../logging/Log';
import siteStore from '../stores/siteStore';

class NetworkReactor {
  /**
   * An array of network status with the oldest status at index 0
   * @private
   */
  private connectionHistory: ConnectionState[] = [];
  private lastSiteCode: string = undefined;

  private networkDownSubject = new BehaviorSubject<boolean>(false);
  networkDown$: Observable<boolean> = this.networkDownSubject.asObservable();

  private networkUpSubject = new BehaviorSubject<boolean>(false);
  networkUp$: Observable<boolean> = this.networkUpSubject.asObservable();

  private dataRefreshNeededSubject = new BehaviorSubject<boolean>(false);
  dataRefreshNeeded$: Observable<boolean> =
    this.dataRefreshNeededSubject.asObservable();

  constructor() {
    Hub.listen('api', (data: any) => {
      const { payload } = data;
      if (payload.event === CONNECTION_STATE_CHANGE) {
        const newConnectionState = payload.data
          .connectionState as ConnectionState;
        this.handleConnectionStateChange(newConnectionState);
      }
    });
  }

  private handleConnectionStateChange(newConnectionState: ConnectionState) {
    const { selectedSite } = siteStore;
    // if the site is changing, then set the network state stack back to empty array
    const newSiteCode = selectedSite?.siteCode;
    if (this.lastSiteCode !== newSiteCode) {
      this.lastSiteCode = newSiteCode;
      this.connectionHistory = [];
      Log.info(
        `networkReactor: site change connection history trimmed to: [${this.connectionHistory}]`
      );
    }
    this.connectionHistory.push(newConnectionState);
    Log.info(`networkReactor: connection history: [${this.connectionHistory}]`);

    // send a network down notification event
    if (NetworkStatusHelper.isNetworkDown(this.connectionHistory)) {
      this.networkDownSubject.next(true);
    }

    if (NetworkStatusHelper.isNetworkUp(this.connectionHistory)) {
      this.networkUpSubject.next(true);
    }

    // send a data refresh needed event
    if (NetworkStatusHelper.shouldResetData(this.connectionHistory)) {
      // trim connection history to successful connection history to prevent
      // the history from growing indefinitely
      this.connectionHistory = [
        ConnectionState.Connecting,
        ConnectionState.Connected,
      ];
      Log.info(
        `networkReactor: connection history trimmed to: [${this.connectionHistory}]`
      );
      this.dataRefreshNeededSubject.next(true);
    }
  }
}

export default new NetworkReactor();
