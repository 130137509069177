import {
  AssignOrdersToTransporterDocument,
  AssignmentResponse,
  GetOrdersForSiteDocument,
  GetOrdersForSiteQueryVariables,
  MutationAssignOrdersToTransporterArgs,
  OrderLevelAssignmentInput,
  OrdersPage,
  SubscribeToCreateOrderDocument,
  SubscribeToDestroyOrderDocument,
  SubscribeToUpdateOrderDocument,
  UnassignOrdersFromTransporterDocument,
} from '../graphqlGenerated/graphql';
import { Order } from '@amzn/gsf-dispatcher-schema';
import Performance from '../logging/Performance';
import Service from './Service';
import Subscription from './Subscription';

export const ORDER_QUERY_LIMIT = 250;

export default class OrderService {
  static async getOrdersForSite(siteCode: string): Promise<Order[]> {
    const operationName = 'getOrdersForSite';
    const startTime = Date.now();
    const orders: Order[] = [];
    let nextToken = undefined;
    do {
      const variables: GetOrdersForSiteQueryVariables = {
        limit: ORDER_QUERY_LIMIT,
        nextToken,
        siteCode,
      };
      const ordersPage: OrdersPage = await Service.query(
        GetOrdersForSiteDocument,
        variables
      );
      const ordersForPage = ordersPage.orders;
      if (ordersForPage) {
        orders.push(...ordersForPage);
      }
      nextToken = ordersPage.nextToken;
    } while (nextToken);
    Performance.log(operationName, startTime);
    return orders;
  }

  static subscribeToCreateOrder(
    siteCode: string,
    entityReceiver: (Order) => void
  ): Subscription<Order> {
    return Service.subscribe(
      SubscribeToCreateOrderDocument,
      { siteCode },
      entityReceiver
    );
  }

  static subscribeToDestroyOrder(
    siteCode: string,
    entityReceiver: (Order) => void
  ): Subscription<Order> {
    return Service.subscribe(
      SubscribeToDestroyOrderDocument,
      { siteCode },
      entityReceiver
    );
  }

  static subscribeToUpdateOrder(
    siteCode: string,
    entityReceiver: (Order) => void
  ): Subscription<Order> {
    return Service.subscribe(
      SubscribeToUpdateOrderDocument,
      { siteCode },
      entityReceiver
    );
  }

  static async assignOrdersToTransporter(
    siteCode: string,
    orders: OrderLevelAssignmentInput[],
    transporterId: string
  ): Promise<AssignmentResponse> {
    const variables: MutationAssignOrdersToTransporterArgs = {
      siteCode,
      orders,
      transporterId,
    };
    return Service.query(AssignOrdersToTransporterDocument, variables);
  }

  static async unassignOrdersFromTransporter(
    siteCode: string,
    orders: OrderLevelAssignmentInput[]
  ): Promise<AssignmentResponse> {
    const variables: MutationAssignOrdersToTransporterArgs = {
      transporterId: 'FAKE_TRANSPORTER_FOR_UNASSIGN',
      siteCode: siteCode,
      orders: orders,
    };
    return Service.query(UnassignOrdersFromTransporterDocument, variables);
  }
}
