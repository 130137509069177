export enum UnitSystem {
  IMPERIAL,
  METRIC,
}

export const HEAVY_ORDER_KG = 22.6796;
export const HEAVY_ORDER_LBS = 50;

export const POUNDS_PER_KILOGRAM = 2.20462;

export const countryCodesUsingImperialUnits = ['US'];
