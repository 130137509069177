import * as _ from 'lodash';
import { Transporter } from '@amzn/gsf-dispatcher-schema';
import { action, makeObservable, observable } from 'mobx';
import Logger from '../logging/Log';
import StateCopier from '../util/StateCopier';
import TransporterGroup from 'map/model/TransporterGroup';

class TransporterStore {
  transporters: Transporter[] = [];

  filteredTransporters: Transporter[] = [];

  sortedTransporters: Transporter[] = [];

  // map of transporter id to rank (1 being highest rank) with all transporters ranked
  transporterRank: Map<string, number> = new Map();

  selectedTransporterId: string;

  filteredTransporterGroups: TransporterGroup[] = [];

  selectedTransporterTransporterGroup: TransporterGroup;

  constructor() {
    makeObservable(this, {
      transporters: observable,
      transporterRank: observable,
      filteredTransporters: observable,
      sortedTransporters: observable,
      filteredTransporterGroups: observable,
      selectedTransporterTransporterGroup: observable,
      selectedTransporterId: observable,
      setTransporters: action,
      setTransporterRank: action,
      setFilteredTransporters: action,
      setSortedTransporters: action,
      setFilteredTransporterGroups: action,
      setSelectedTransporterTransporterGroup: action,
      setSelectedTransporterId: action,
      removeTransporter: action,
      updateTransporter: action,
    });
  }

  setTransporters(transporters: Transporter[]) {
    this.transporters = transporters;
  }

  setTransporterRank(transporterRank: Map<string, number>) {
    this.transporterRank = transporterRank;
  }

  setFilteredTransporters(transporters: Transporter[]) {
    this.filteredTransporters = transporters;
  }

  setSortedTransporters(transporters: Transporter[]) {
    this.sortedTransporters = transporters;
  }

  setFilteredTransporterGroups(transporterGroups: TransporterGroup[]) {
    this.filteredTransporterGroups = transporterGroups;
  }

  setSelectedTransporterTransporterGroup(transporterGroup: TransporterGroup) {
    this.selectedTransporterTransporterGroup = transporterGroup;
  }

  setSelectedTransporterId(transporterId: string) {
    this.selectedTransporterId = transporterId;
  }

  removeTransporter(transporter: Transporter) {
    const existingTransporter = this.findExistingTransporter(
      transporter.transporterId
    );

    if (existingTransporter) {
      const existingTransporters = _.clone(this.transporters);
      const transporterIndex =
        existingTransporters.indexOf(existingTransporter);
      existingTransporters.splice(transporterIndex, 1);
      Logger.debug(
        `transporterStore.removeTransporter: removing existing transporter (${
          existingTransporters.length
        }) ${JSON.stringify(existingTransporter)}`
      );
      this.setTransporters(existingTransporters);
    }
  }

  updateTransporter(transporter: Transporter) {
    const existingTransporter = this.findExistingTransporter(
      transporter.transporterId
    );
    if (existingTransporter) {
      const changedAttributes = StateCopier.copyState(
        transporter,
        existingTransporter
      );
      if (changedAttributes.length > 0) {
        Logger.debug(
          `transporterStore.updateTransporter: updated existing transporter [${changedAttributes}]: ${JSON.stringify(
            existingTransporter
          )}`
        );
      }
    } else {
      const existingTransporters = _.clone(this.transporters);
      existingTransporters.push(transporter);
      Logger.debug(
        `transporterStore.updateTransporter: adding new transporter (${
          existingTransporters.length
        }) ${JSON.stringify(transporter)}`
      );
      this.setTransporters(existingTransporters);
    }
  }

  private findExistingTransporter(
    transporterId: string
  ): Transporter | undefined {
    return this.transporters.find((t) => t.transporterId === transporterId);
  }
}
export default new TransporterStore();
