export const PICKUP_WINDOW_END_TIME = 'PICKUP_WINDOW_END_TIME';
export const DELIVERY_WINDOW_END_TIME = 'DELIVERY_WINDOW_END_TIME';
export interface SortOption {
  code: string;
  displayValue: string;
}

export const SORT_OPTION_PICKUP_WINDOW_END_TIME: SortOption = {
  code: PICKUP_WINDOW_END_TIME,
  displayValue: 'Pickup Window',
};

export const SORT_OPTION_DELIVERY_WINDOW_END_TIME: SortOption = {
  code: DELIVERY_WINDOW_END_TIME,
  displayValue: 'Delivery Window',
};

export const SORT_OPTIONS: SortOption[] = [
  SORT_OPTION_PICKUP_WINDOW_END_TIME,
  SORT_OPTION_DELIVERY_WINDOW_END_TIME,
];
