import { BehaviorSubject, Observable } from 'rxjs';
import { reaction } from 'mobx';
import routeStore from '../stores/routeStore';
import transporterStore from '../stores/transporterStore';

class SelectionReactor {
  private selectedTransporterIdChangeSubject = new BehaviorSubject<boolean>(
    false
  );
  selectedTransporterIdChange$: Observable<boolean> =
    this.selectedTransporterIdChangeSubject.asObservable();

  private selectedRouteIdChangeSubject = new BehaviorSubject<boolean>(false);
  selectedRouteIdChange$: Observable<boolean> =
    this.selectedRouteIdChangeSubject.asObservable();
  constructor() {
    reaction(
      () => {
        return transporterStore.selectedTransporterId;
      },
      (selectedTransporterId: string) => {
        this.selectedTransporterIdChangeSubject.next(true);
      }
    );

    reaction(
      () => {
        return routeStore.selectedRouteId;
      },
      (selectedRouteId: string) => {
        this.selectedRouteIdChangeSubject.next(true);
      }
    );
  }
}

export default new SelectionReactor();
