import * as _ from 'lodash';
import {
  ORDER_VIEW_ROUTES,
  ORDER_VIEW_UNPLANNED_ORDERS,
} from '../../util/ViewConstants';
import { Order, Route } from '@amzn/gsf-dispatcher-schema';
import { observer } from 'mobx-react';
import Column from '@amzn/meridian/column';
import OrderHelper from '../../util/OrderHelper';
import OrderListComp from '../order/OrderListComp';
import React from 'react';
import RouteHelper from '../../util/RouteHelper';
import RouteListComp from '../route/RouteListComp';
import SiteHelper from '../../util/SiteHelper';
import viewStore from '../../stores/viewStore';

export interface RoutesAndOrdersProps {
  routes: Route[];
  orders: Order[];
}

class RoutesAndOrdersComp extends React.Component<RoutesAndOrdersProps> {
  render() {
    const { routes, orders } = this.props;
    const { orderViews, groupByAssignmentStatus } = viewStore;
    const showRoutes = _.includes(orderViews, ORDER_VIEW_ROUTES);
    const showOrders =
      !SiteHelper.isSsdSite() &&
      _.includes(orderViews, ORDER_VIEW_UNPLANNED_ORDERS);
    const assignedRoutes = routes.filter((r) =>
      RouteHelper.isRouteAssignedOrAllOrdersAssigned(r)
    );
    const unAssignedRoutes = routes.filter(
      (r) => !RouteHelper.isRouteAssignedOrAllOrdersAssigned(r)
    );
    const assignedOrders = orders.filter((o) => OrderHelper.isOrderAssigned(o));
    const unAssignedOrders = orders.filter(
      (o) => !OrderHelper.isOrderAssigned(o)
    );
    return groupByAssignmentStatus ? (
      <Column overflowY="auto" spacing="400">
        {showRoutes && <RouteListComp routes={unAssignedRoutes} />}
        {showOrders && <OrderListComp orders={unAssignedOrders} />}
        {showRoutes && <RouteListComp routes={assignedRoutes} />}
        {showOrders && <OrderListComp orders={assignedOrders} />}
      </Column>
    ) : (
      <Column overflowY="auto" spacing="400">
        {showRoutes && <RouteListComp routes={routes} />}
        {showOrders && <OrderListComp orders={orders} />}
      </Column>
    );
  }
}

export default observer(RoutesAndOrdersComp);
