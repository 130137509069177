import { REGION_NA } from './util/EnvironmentConstants';
import EnvConfigHelper from './util/EnvConfigHelper';

const cognitoConfig = EnvConfigHelper.getCognitoConfig();

const oauth = {
  domain: cognitoConfig.domain,
  scope: ['openid', 'profile'],
  redirectSignIn: window.location.protocol + '//' + window.location.host,
  redirectSignOut: window.location.protocol + '//' + window.location.host,
  clientId: cognitoConfig.clientId,
  responseType: 'code',
};

const AuthConfiguration = {
  // Amazon Cognito Region
  region: cognitoConfig.region,

  // Amazon Cognito User Pool ID
  userPoolId: cognitoConfig.userPoolId,

  // Amazon Cognito Identity Pool ID
  identityPoolId: cognitoConfig.identityPoolId,

  // Amazon Cognito Web Client ID (26-char alphanumeric string, App client secret needs to be disabled)
  userPoolWebClientId: cognitoConfig.userPoolWebClientId,

  mandatorySignIn: true,
  oauth,
};

const environmentConfig = EnvConfigHelper.getEnvironmentConfig();

const awsAmplifyConfig = {
  // see https://docs.amplify.aws/lib/client-configuration/configuring-amplify-categories/q/platform/js/

  // AWS Cognito config
  Auth: AuthConfiguration,

  // we will use the same map name and region in both non-prod and prod where the AWS Location Service map
  // is created in the same AWS account as the Cognito user pool/identity pool
  geo: {
    AmazonLocationService: {
      maps: {
        items: {
          GsfDispatcherMap: {
            style: 'HERE Explore',
          },
        },
        default: 'GsfDispatcherMap',
      },
      region: REGION_NA,
    },
  },

  // app sync config
  aws_appsync_region: environmentConfig.region,
  aws_appsync_graphqlEndpoint: environmentConfig.appSyncUrl,
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
};

export default awsAmplifyConfig;
