import { Address, Order, TimeWindow } from '@amzn/gsf-dispatcher-schema';
import {
  FilterOption,
  PREFIX_DELIVERY_WINDOW,
  PREFIX_PICKUP_ADDRESS,
} from '../../util/FilterConstants';
import AddressHelper from '../../util/AddressHelper';
import DataFilterController from '../../controllers/DataFilterController';
import FilterHelper from '../../util/FilterHelper';
import OrderHelper from '../../util/OrderHelper';
import React from 'react';
import RouteHelper from '../../util/RouteHelper';
import Row from '@amzn/meridian/row';
import Select, { SelectOption } from '@amzn/meridian/select';
import TimeHelper from '../../util/TimeHelper';
import filterReactor from '../../reactors/filterReactor';
import filterStore from '../../stores/filterStore';
import orderStore from '../../stores/orderStore';
import routeStore from '../../stores/routeStore';
import siteStore from '../../stores/siteStore';

export interface OrderFilterProps {}

export interface OrderFilterState {
  value: string[];
}

export default class OrderFilterComp extends React.Component<
  OrderFilterProps,
  OrderFilterState
> {
  componentDidMount() {
    this.setState({ value: [] });

    filterReactor.selectedOrderFilterCodesChange$.subscribe(async (event) => {
      const { selectedOrderFilterCodes } = filterStore;
      this.setState({ value: selectedOrderFilterCodes });
    });
  }

  constructor(props: OrderFilterProps) {
    super(props);
  }
  render() {
    const filterOptions = FilterHelper.getStaticOrderFilterOptions();

    const { selectedSite } = siteStore;

    const unique3rdPartyPickupAddresses =
      this.getUnique3rdPartyPickupAddresses();
    filterOptions.push(
      this.makePickupAddressOption(selectedSite.pickupAddress)
    );
    unique3rdPartyPickupAddresses.forEach((a) =>
      filterOptions.push(this.makePickupAddressOption(a))
    );
    const uniqueDeliveryWindows = this.getUniqueDeliveryWindows();
    uniqueDeliveryWindows.forEach((w) =>
      filterOptions.push(this.makeDeliveryWindowOption(w))
    );

    const selectOptions = filterOptions.map((option) => (
      <SelectOption
        key={`orderSortOption${option.code}`}
        label={option.code}
        value={option.code}
      />
    ));

    return (
      <Row spacingInset="100">
        <Select
          label="Filter by"
          value={this.state?.value}
          onChange={(newValues) => this.handleValueChange(newValues)}
          width="100%"
        >
          {selectOptions}
        </Select>
      </Row>
    );
  }

  private handleValueChange(newValues: string[]) {
    this.setState({ value: newValues });
    DataFilterController.handleRouteFilterValueChange(newValues);
  }

  private makePickupAddressOption(address: Address): FilterOption {
    return {
      code: FilterHelper.makeFilterString(
        PREFIX_PICKUP_ADDRESS,
        AddressHelper.getDisplayAddress(address)
      ),
    };
  }

  private makeDeliveryWindowOption(deliveryWindow: TimeWindow): FilterOption {
    return {
      code: FilterHelper.makeFilterString(
        PREFIX_DELIVERY_WINDOW,
        TimeHelper.timeWindowAsString(deliveryWindow)
      ),
    };
  }

  private getUniqueDeliveryWindows() {
    return OrderHelper.uniqueDeliveryWindows(this.getOrders());
  }

  private getUnique3rdPartyPickupAddresses() {
    return OrderHelper.unique3rdPartyPickupAddresses(this.getOrders());
  }

  private getOrders() {
    const bothUnplannedAndPlannedOrders: Order[] = [];
    const { routes } = routeStore;
    bothUnplannedAndPlannedOrders.push(...RouteHelper.getOrders(routes));
    bothUnplannedAndPlannedOrders.push(...orderStore.orders);
    return bothUnplannedAndPlannedOrders;
  }
}
