import { Message } from '../messages/model/Message';
import { MessageType } from '../messages/model/MessageType';
import { v4 as uuidv4 } from 'uuid';
import messagesStore from '../stores/messagesStore';

export default class MessagesController {
  static removeMessageWithId(messageId: string) {
    messagesStore.removeMessageWithId(messageId);
  }

  static addAlertMessage(messageText: string, timeout: number = undefined) {
    MessagesController.addMessageWithType(
      MessageType.Error,
      messageText,
      timeout
    );
  }

  static addWarningMessage(messageText: string, timeout: number = undefined) {
    MessagesController.addMessageWithType(
      MessageType.Warning,
      messageText,
      timeout
    );
  }

  static addInformationalMessage(
    messageText: string,
    timeout: number = undefined
  ) {
    MessagesController.addMessageWithType(
      MessageType.Informational,
      messageText,
      timeout
    );
  }

  static addSuccessMessage(messageText: string, timeout: number = undefined) {
    MessagesController.addMessageWithType(
      MessageType.Success,
      messageText,
      timeout
    );
  }

  private static addMessageWithType(
    messageType: MessageType,
    messageText: string,
    timeout: number = undefined
  ) {
    const message: Message = {
      id: uuidv4(),
      type: messageType,
      message: messageText,
      timeout,
    };
    messagesStore.addMessage(message);
  }
}
