import { Site } from '@amzn/gsf-dispatcher-schema';
import { SiteName } from '../graphqlGenerated/graphql';
import { action, makeObservable, observable } from 'mobx';
import Log from '../logging/Log';
import StateCopier from '../util/StateCopier';

class SiteStore {
  allSiteNames: SiteName[] = [];
  filteredSiteNames: SiteName[] = [];
  selectedSiteName: SiteName;
  selectedSite: Site;
  authorizedForSelectedSiteName: boolean = false;
  sitesLoaded: boolean = false;
  siteSearchString: string = '';
  loadingSite: boolean = false;
  loadingSiteData: boolean = false;

  constructor() {
    makeObservable(this, {
      allSiteNames: observable,
      initializeAllSiteNames: action,
      selectedSiteName: observable,
      siteSearchString: observable,
      filteredSiteNames: observable,
      setFilteredSiteNamesAndSearchString: action,
      sitesLoaded: observable,
      selectedSite: observable,
      authorizedForSelectedSiteName: observable,
      loadingSite: observable,
      loadingSiteData: observable,
      setAuthorizedSite: action,
      setUnauthorizedSite: action,
      setLoadingSite: action,
      setLoadingSiteData: action,
      updateSite: action,
    });
  }

  initializeAllSiteNames(allSiteNames: SiteName[]) {
    this.allSiteNames = allSiteNames;
    this.filteredSiteNames = allSiteNames;
    this.selectedSite = undefined;
    this.selectedSiteName = undefined;
    this.siteSearchString = '';
    this.sitesLoaded = true;
    this.loadingSite = false;
    this.authorizedForSelectedSiteName = false;
  }

  setLoadingSite(siteName: SiteName) {
    this.selectedSiteName = siteName;
    this.loadingSite = true;
  }

  setFilteredSiteNamesAndSearchString(
    searchString: string,
    siteNames: SiteName[]
  ) {
    this.siteSearchString = searchString;
    this.filteredSiteNames = siteNames;
  }

  setAuthorizedSite(site: Site) {
    this.selectedSite = site;
    this.authorizedForSelectedSiteName = true;
    this.loadingSite = false;
  }

  setUnauthorizedSite() {
    this.selectedSite = undefined;
    this.authorizedForSelectedSiteName = false;
    this.loadingSite = false;
  }

  setLoadingSiteData(value: boolean) {
    this.loadingSiteData = value;
  }

  updateSite(site: Site) {
    const existingSite = this.selectedSite;
    if (existingSite && existingSite.siteCode === site.siteCode) {
      const changedAttributes = StateCopier.copyState(site, existingSite);
      if (changedAttributes.length > 0) {
        Log.debug(
          `siteStore.updateSite: updated existing site [${changedAttributes}]: ${JSON.stringify(
            existingSite
          )}`
        );
      }
    }
  }
}
export default new SiteStore();
