import { Site } from '@amzn/gsf-dispatcher-schema';
import { SiteName } from '../graphqlGenerated/graphql';
import MapObject from '../map/MapObject';
import SiteDataController from './SiteDataController';
import SiteService from '../services/SiteService';
import ViewController from './ViewController';
import siteStore from '../stores/siteStore';

export default class SiteController {
  static async loadAllSiteNames() {
    const allSiteNames: SiteName[] = await SiteService.getAllSiteNames();
    siteStore.initializeAllSiteNames(allSiteNames);
  }

  static handleUpdateSite(site: Site): void {
    siteStore.updateSite(site);
  }

  static handleSiteNameSearchStringChange(newSiteSearchString: string) {
    const { allSiteNames } = siteStore;
    const filteredSiteNames = allSiteNames.filter((site) => {
      const searchable = SiteController.getSearchable(site).toLowerCase();
      return searchable.indexOf(newSiteSearchString.toLowerCase()) > -1;
    });
    siteStore.setFilteredSiteNamesAndSearchString(
      newSiteSearchString,
      filteredSiteNames
    );
  }

  static async handleSiteNameSearchEnterKeyPressed() {
    const { filteredSiteNames } = siteStore;
    if (filteredSiteNames.length === 1) {
      return SiteController.handleSelectSiteName(filteredSiteNames[0]);
    }
  }

  static getSearchable(siteName: SiteName): string {
    return `${siteName.siteName} (${siteName.siteCode})`;
  }

  static async handleSelectSiteName(siteName: SiteName) {
    siteStore.setLoadingSiteData(true);
    ViewController.resetRoutesCollapsed();
    siteStore.setFilteredSiteNamesAndSearchString(
      SiteController.getSearchable(siteName),
      [siteName]
    );
    siteStore.setLoadingSite(siteName);
    const selectedSite = await SiteService.getSite(siteName.siteCode);

    if (selectedSite) {
      const { siteCode } = selectedSite;
      siteStore.setAuthorizedSite(selectedSite);
      MapObject.centerAndSetDefaultResolutionForSite();

      await SiteDataController.handleSiteChange(siteCode);
      siteStore.setLoadingSiteData(false);
    } else {
      siteStore.setUnauthorizedSite();
    }
  }

  static handleSiteNameSearchLoseFocus() {
    const { selectedSiteName } = siteStore;
    if (selectedSiteName) {
      siteStore.setFilteredSiteNamesAndSearchString(
        SiteController.getSearchable(selectedSiteName),
        [selectedSiteName]
      );
    }
  }
}
