import './TransporterComp.css';
import { LARGE_PASSENGER_VEHICLE_IDENTIFIER } from '../../util/BusinessConstants';
import { NOT_AVAILABLE } from '../../AppConstants';
import { TransportationMode, Transporter } from '@amzn/gsf-dispatcher-schema';
import { observer } from 'mobx-react';
import AssignmentController from '../../controllers/AssignmentController';
import AssignmentHelper from '../../util/AssignmentHelper';
import Box from '@amzn/meridian/box';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import OrderHelper from '../../util/OrderHelper';
import React from 'react';
import RemainingStopComp from './RemainingStopComp';
import RemainingTimeComp from 'components/common/RemainingTimeComp';
import Row from '@amzn/meridian/row';
import StringHelper from 'util/StringHelper';
import Text from '@amzn/meridian/text';
import TransporterController from '../../controllers/TransporterController';
import TransporterHelper from '../../util/TransporterHelper';
import bikeImage from '../../assets/images/transportationMode/bike.svg';
import motorcycleImage from '../../assets/images/transportationMode/motorcycle.svg';
import remainingTimeImage from '../../assets/images/block_icon.svg';
import transporterStore from '../../stores/transporterStore';
import truckImage from '../../assets/images/transportationMode/truck.svg';
import walkerImage from '../../assets/images/transportationMode/walker.svg';

export interface TransporterProps {
  transporter: Transporter;
}

class TransporterComp extends React.Component<TransporterProps> {
  render() {
    const { transporter } = this.props;
    const { selectedTransporterId, transporterRank } = transporterStore;
    const rank = transporterRank.get(transporter.transporterId) || '?';
    const isSelectedTransporter =
      selectedTransporterId === transporter.transporterId;
    const availableTransporter =
      TransporterHelper.isAvailableTransporter(transporter);
    const remainingStopCount = transporter.remainingStops.length;
    const remainingPackageCount = this.getRemainingPackageCount(transporter);
    const endOfBlockTime =
      transporter.transporterSession?.expectedSessionEndTime;
    const providerReservationId =
      transporter?.transporterSession?.providerReservationId;
    const earliestRemainingOrderDeliveryEndTime =
      OrderHelper.getEarliestOrderDeliveryEndTime(transporter.remainingOrders);
    const transporterType = TransporterHelper.getTransporterType(transporter);
    const transporterCompanyName = transporter.companyName;
    const remainingStopContent =
      isSelectedTransporter && transporter.remainingStops
        ? transporter.remainingStops.map((stop) => {
            return (
              <RemainingStopComp
                key={`transporterStop${transporter.transporterId}${stop.stopType}${stop.orderIds}`}
                transporter={transporter}
                stop={stop}
              />
            );
          })
        : [];
    return (
      <div
        onClick={() => TransporterController.transporterClicked(transporter)}
      >
        <Box
          type="outline"
          spacingInset="200"
          className={isSelectedTransporter ? 'div-selected' : ''}
        >
          <Row wrap="down" widths={['2em', 'fill', '6em']}>
            <Column>
              <Row>
                <img
                  className="transportation-mode-image"
                  src={this.getTransportationModeImage(transporter)}
                />
              </Row>
              <Row>
                <Box
                  className={
                    availableTransporter
                      ? 'transporter-rank-container-available'
                      : 'transporter-rank-container-busy'
                  }
                >
                  <Row alignmentHorizontal="center">
                    <div>{rank}</div>
                  </Row>
                </Box>
              </Row>
            </Column>
            <Column spacing={'100'}>
              <Row>
                <Text type="h100">
                  {TransporterHelper.getTransporterName(transporter)}
                </Text>
              </Row>
              <Row>
                <Text>{transporter.phoneNumber}</Text>
              </Row>
              <Row>
                <Text>
                  {StringHelper.makeCountDisplay(remainingStopCount, 'Stop')}
                </Text>
              </Row>
              {remainingStopCount > 0 && (
                <Row>
                  <Text>
                    {StringHelper.makeCountDisplay(
                      remainingPackageCount,
                      'Package'
                    )}
                  </Text>
                  <RemainingTimeComp
                    endTime={earliestRemainingOrderDeliveryEndTime}
                  />
                </Row>
              )}
              <Row>
                <Row className="nowrap" spacing="200">
                  <Text type="h100">Type:</Text>
                  <Text>{transporterType}</Text>
                </Row>
              </Row>
              <Row spacing="200">
                <Text>{transporterCompanyName}</Text>
              </Row>
            </Column>
            <Column spacing="400" alignmentVertical="justify">
              {this.showLargePassengerVehicleText(providerReservationId) && (
                <Row alignmentHorizontal="center">
                  <Text
                    className="large-passenger-vehicle-text-color"
                    type="h200"
                  >
                    LPV
                  </Text>
                </Row>
              )}
              <Row spacing="200">
                <img
                  className="remaining-time-image"
                  src={remainingTimeImage}
                />
                {endOfBlockTime ? (
                  <RemainingTimeComp
                    endTime={endOfBlockTime}
                    outOfTimeString="off duty"
                    alertThreshold={'0:20 hr'}
                  />
                ) : (
                  <Text>{NOT_AVAILABLE}</Text>
                )}
              </Row>
              {isSelectedTransporter &&
                AssignmentHelper.isAssignmentAllowedForSsd() &&
                AssignmentHelper.getSelectedIds().length > 0 && (
                  <Row>
                    <Button
                      size="small"
                      type="primary"
                      onClick={() => AssignmentController.startAssignment()}
                      propagateClickEvent={false}
                    >
                      Assign
                    </Button>
                  </Row>
                )}
            </Column>
          </Row>

          <Column spacing="200">{remainingStopContent}</Column>
        </Box>
      </div>
    );
  }

  private getTransportationModeImage(transporter: Transporter) {
    const transportationMode =
      transporter.transporterSession?.transportationMode;
    switch (transportationMode) {
      case TransportationMode.Walking:
        return walkerImage;
      case TransportationMode.Biking:
        return bikeImage;
      case TransportationMode.Motorcycle:
        return motorcycleImage;
      default:
        return truckImage;
    }
  }

  private getRemainingPackageCount(transporter: Transporter): number {
    let packageCount = 0;
    if (transporter.remainingOrders) {
      transporter.remainingOrders.forEach((order) => {
        packageCount += order.packageCount;
      });
    }
    return packageCount;
  }

  private showLargePassengerVehicleText(
    providerReservationId: string
  ): boolean {
    const serviceTypeId = TransporterHelper.getServiceTypeId(
      providerReservationId
    );
    return serviceTypeId.endsWith(LARGE_PASSENGER_VEHICLE_IDENTIFIER);
  }
}

export default observer(TransporterComp);
