import * as _ from 'lodash';
import { Order } from '@amzn/gsf-dispatcher-schema';
import { action, makeObservable, observable } from 'mobx';
import Logger from '../logging/Log';
import OrderGroup from '../map/model/OrderGroup';
import StateCopier from '../util/StateCopier';

const HIGHLIGHTED_IDS_ATTRIBUTE = 'highlightedOrderIds';
const SELECTED_IDS_ATTRIBUTE = 'selectedOrderIds';

class OrderStore {
  orders: Order[] = [];

  filteredOrders: Order[] = [];

  sortedOrders: Order[] = [];

  selectedOrderIds: string[] = [];

  highlightedOrderIds: string[] = [];

  enableTwoWayHighlightedOrderSync: boolean = false;

  filteredOrderGroups: OrderGroup[] = [];

  constructor() {
    makeObservable(this, {
      orders: observable,
      filteredOrders: observable,
      sortedOrders: observable,
      filteredOrderGroups: observable,
      selectedOrderIds: observable,
      highlightedOrderIds: observable,
      enableTwoWayHighlightedOrderSync: observable,
      setOrders: action,
      setFilteredOrders: action,
      setSortedOrders: action,
      setFilteredOrderGroups: action,
      setSelectedOrderIds: action,
      setHighlightedOrderIds: action,
      setEnableTwoWayHighlightedOrderSync: action,
      clearSelectedOrderIds: action,
      clearHighlightedOrderIds: action,
      addSelectedOrderId: action,
      addHighlightedOrderId: action,
      addSelectedOrderIds: action,
      addHighlightedOrderIds: action,
      removeSelectedOrderId: action,
      removeHighlightedOrderId: action,
      removeSelectedOrderIds: action,
      removeHighlightedOrderIds: action,
      removeOrder: action,
      updateOrder: action,
    });
  }

  setOrders(orders: Order[]) {
    this.orders = orders;
  }

  setFilteredOrders(orders: Order[]) {
    this.filteredOrders = orders;
  }

  setSortedOrders(orders: Order[]) {
    this.sortedOrders = orders;
  }

  setFilteredOrderGroups(orderGroups: OrderGroup[]) {
    this.filteredOrderGroups = orderGroups;
  }

  isOrderIdSelected(orderId: string): boolean {
    return this.selectedOrderIds.indexOf(orderId) > -1;
  }

  isOrderIdHighlighted(orderId: string): boolean {
    return this.highlightedOrderIds.indexOf(orderId) > -1;
  }

  setSelectedOrderIds(orderIds: string[]) {
    this.selectedOrderIds = orderIds;
  }

  setEnableTwoWayHighlightedOrderSync(value: boolean) {
    this.enableTwoWayHighlightedOrderSync = value;
  }

  setHighlightedOrderIds(orderIds: string[]) {
    this.highlightedOrderIds = orderIds;
  }

  clearSelectedOrderIds() {
    this.selectedOrderIds = [];
  }

  clearHighlightedOrderIds() {
    this.highlightedOrderIds = [];
  }

  addSelectedOrderIds(orderIds: string[]) {
    orderIds.forEach((orderId) => this.addSelectedOrderId(orderId));
  }

  addHighlightedOrderIds(orderIds: string[]) {
    orderIds.forEach((orderId) => this.addHighlightedOrderId(orderId));
  }

  addSelectedOrderId(orderId: string) {
    this.addOrderIdToCollection(SELECTED_IDS_ATTRIBUTE, orderId);
  }

  addHighlightedOrderId(orderId: string) {
    this.addOrderIdToCollection(HIGHLIGHTED_IDS_ATTRIBUTE, orderId);
  }

  removeSelectedOrderIds(orderIds: string[]) {
    orderIds.forEach((orderId) => this.removeSelectedOrderId(orderId));
  }

  removeHighlightedOrderIds(orderIds: string[]) {
    orderIds.forEach((orderId) => this.removeHighlightedOrderId(orderId));
  }

  removeSelectedOrderId(orderId: string) {
    this.removeOrderIdFromCollection(SELECTED_IDS_ATTRIBUTE, orderId);
  }

  removeHighlightedOrderId(orderId: string) {
    this.removeOrderIdFromCollection(HIGHLIGHTED_IDS_ATTRIBUTE, orderId);
  }

  removeOrder(order: Order) {
    const existingOrder = this.findExistingOrder(order.orderId);

    if (existingOrder) {
      const existingOrders = _.clone(this.orders);
      const orderIndex = existingOrders.indexOf(existingOrder);
      existingOrders.splice(orderIndex, 1);
      Logger.debug(
        `orderStore.removeOrder: removing existing order (${
          existingOrders.length
        }) ${JSON.stringify(existingOrder)}`
      );
      this.setOrders(existingOrders);
    }
  }

  updateOrder(order: Order) {
    const existingOrder = this.findExistingOrder(order.orderId);
    if (existingOrder) {
      const changedAttributes = StateCopier.copyState(order, existingOrder);
      if (changedAttributes.length > 0) {
        Logger.debug(
          `orderStore.updateOrder: updated existing order [${changedAttributes}]: ${JSON.stringify(
            existingOrder
          )}`
        );
      }
    } else {
      const existingOrders = _.clone(this.orders);
      existingOrders.push(order);
      Logger.debug(
        `orderStore.updateOrder: adding new order (${
          existingOrders.length
        }) ${JSON.stringify(order)}`
      );
      this.setOrders(existingOrders);
    }
  }

  private findExistingOrder(orderId: string): Order | undefined {
    return this.orders.find((o) => o.orderId === orderId);
  }

  private addOrderIdToCollection(collectionName: string, orderId: string) {
    const orderIdArray = this[collectionName];
    if (!_.includes(orderIdArray, orderId)) {
      const newOrderIds = _.clone(orderIdArray);
      newOrderIds.push(orderId);
      this[collectionName] = newOrderIds;
    }
  }

  private removeOrderIdFromCollection(collectionName: string, orderId: string) {
    const index = this[collectionName].indexOf(orderId);
    if (index > -1) {
      const newOrderIds = _.clone(this[collectionName]);
      newOrderIds.splice(index, 1);
      this[collectionName] = newOrderIds;
    }
  }
}
export default new OrderStore();
