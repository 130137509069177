import * as _ from 'lodash';
import { GeolibInputCoordinates } from 'geolib/es/types';
import { GpsLocation } from '../graphqlGenerated/graphql';
import {
  Order,
  Route,
  Site,
  Transporter,
  TransporterStopType,
} from '@amzn/gsf-dispatcher-schema';
import {
  computeDestinationPoint,
  getBounds,
  getCenter,
  getCenterOfBounds,
  getDistance,
  isPointWithinRadius,
} from 'geolib';
import EntityGroup from './model/EntityGroup';
import MapLocation from './model/MapLocation';
import MapObject from './MapObject';
import MercatorMapLocation from './model/MercatorMapLocation';
import OrderGroup from './model/OrderGroup';
import RouteHelper from '../util/RouteHelper';
import TransporterGroup from './model/TransporterGroup';
import TransporterHelper from '../util/TransporterHelper';
import ViewExtent from './model/ViewExtent';
import siteStore from 'stores/siteStore';

interface GridCell {
  x: number;
  y: number;
}

interface EntityGroupFormationInfo<EntityType> {
  entityGroupMapByGridCellKey: Map<string, EntityGroup<EntityType>>;
  sortedGridCellKeys: string[];
}

export default class GeospatialHelper {
  public static sameLocation(p1: MapLocation, p2: MapLocation): boolean {
    // true if distance is less than 50 meters
    return GeospatialHelper.getDistance(p1, p2) < 50;
  }

  // returns the distance in meters between two points
  public static getDistance(start: MapLocation, end: MapLocation): number {
    const from: GeolibInputCoordinates = {
      latitude: start.latitude.toString(),
      longitude: start.longitude.toString(),
    };
    const to: GeolibInputCoordinates = {
      latitude: end.latitude.toString(),
      longitude: end.longitude.toString(),
    };
    return getDistance(from, to);
  }

  public static getExtentsForTransporterAndStops(
    transporter: Transporter
  ): ViewExtent {
    const { selectedSite } = siteStore;
    const ordersMap = new Map<string, Order>();
    const remainingOrders = transporter.remainingOrders || [];
    for (const order of remainingOrders) {
      ordersMap.set(order.orderId, order);
    }
    const remainingStops = transporter.remainingStops || [];
    const points = remainingStops
      .filter((s) => s.orderIds && s.orderIds.length > 0)
      .map((stop) => {
        const order = ordersMap.get(stop.orderIds[0]);
        return stop.stopType === TransporterStopType.Pickup
          ? order.pickupLocation
          : order.deliveryLocation;
      });
    points.push(transporter.lastKnownLocation);
    points.push(selectedSite.pickupLocation);

    return GeospatialHelper.getExtents(points);
  }

  public static getExtentsForRoute(route: Route): ViewExtent {
    const { selectedSite } = siteStore;
    const ordersMap = new Map<string, Order>();
    const orders = route.orders || [];
    for (const order of orders) {
      ordersMap.set(order.orderId, order);
    }
    const routeStops = route.routeStops || [];
    const points = routeStops
      .filter((s) => s.orderIds && s.orderIds.length > 0)
      .map((stop) => {
        const order = ordersMap.get(stop.orderIds[0]);
        return stop.stopType === TransporterStopType.Pickup
          ? order.pickupLocation
          : order.deliveryLocation;
      });
    points.push(selectedSite.pickupLocation);

    const transporterIds = Array.from(
      RouteHelper.getTransporterMap(route).keys()
    );
    transporterIds.forEach((transporterId) => {
      const transporter = TransporterHelper.findTransporterById(transporterId);
      if (transporter) {
        points.push(transporter.lastKnownLocation);
      }
    });

    return GeospatialHelper.getExtents(points);
  }

  public static getExtents(points: MapLocation[]): ViewExtent {
    const boundingBoxCenter: MapLocation = getCenterOfBounds(points);
    const bounds = getBounds(points);
    const topLeft: MapLocation = {
      latitude: bounds.maxLat,
      longitude: bounds.minLng,
    };
    const bottomRight: MapLocation = {
      latitude: bounds.minLat,
      longitude: bounds.maxLng,
    };
    return {
      boundingBoxCenter,
      topLeft,
      bottomRight,
    };
  }

  public static isNearbyTransporter(
    transporter: Transporter,
    site: Site
  ): boolean {
    // 1 mile (1609m) is the threshold for being nearby
    const thresholdMeters = 1609;
    const distance = GeospatialHelper.getDistance(
      transporter.lastKnownLocation,
      site.pickupLocation
    );
    return distance < thresholdMeters;
  }
  public static getDestinationLocation(
    initial: MapLocation,
    distanceMeters: number,
    bearingInDegrees: number
  ): MapLocation {
    return computeDestinationPoint(initial, distanceMeters, bearingInDegrees);
  }

  public static async groupOrders(
    orders: Order[],
    filterOrdersOutsideMapExtents = true
  ): Promise<OrderGroup[]> {
    return GeospatialHelper.groupEntities(
      orders,
      (o) => o.orderId,
      (o) => o.deliveryLocation,
      filterOrdersOutsideMapExtents
    );
  }

  public static async groupTransporters(
    transporters: Transporter[]
  ): Promise<TransporterGroup[]> {
    return GeospatialHelper.groupEntities(
      transporters,
      (t) => t.transporterId,
      (t) => t.lastKnownLocation
    );
  }

  private static async groupEntities<EntityType>(
    entities: EntityType[],
    idMapper: (EntityType) => string,
    locationMapper: (EntityType) => MapLocation,
    filterEntitiesOutsideMapExtents = true
  ): Promise<EntityGroup<EntityType>[]> {
    /** the idea is to make a multiple passes condensing of entity group display icons until they do not overlap
     on the screen.  In order to increase performance, the entities/entity groups are placed into a map grid cell
     bucket the size of an icon diameter.  All entities/entity groups in that bucket are placed in an entity group.
     The key of the grid cell is two integer values with the format x,y (e.g. -3,4) where the values of x and y
     are the coordinates normalized to grid cell units.  The map center grid is coordinate 0,0 with x values
     increasing in the easterly direction and y values increasing in the northerly direction.  For example the
     grid cell coordinate of -4,5 would be northwest of the map center and the center of that grid cell would
     be 4.5 icon diameters to the west and 5.5 icon diameters to the north. Collisions (overlap) in entity groups
     is done by sorting the entity/entity group grid cell keys in order from top down and left to right like you
     would read a page of a book.  The sorting of the keys is done so that each entity group can be checked for
     collisions with any entity group neighbors to the east, southeast, south, and southwest.  At most 4 collisons
     will need to be checked for each entity group.  The fact that only 4 neighboring grid cell are checked
     for collisions instead of all other entity groups allows the algorithm performance to be improved
     from O(n^2) to approaching but not quite O(n). It is not quite O(n) due to the fact that multiple passes
     are required until no collisions have occurred on the pass.  In practice this is about 3 passes.
     All collisions will result in the combining of those entity groups into a larger combined entity group.

     The entity circle is 24 px in diameter, so use this times a padding factor
     to compute the distance in meters between entity groups and collapse them if needed.  The entity group centroid
     is computed by taking into account all entities in the entity group.

     The entities are filtered to only those within the current map's view extent as a first step in the algo
     in order to reduce the size of n.
     **/

    const mapViewExtent = MapObject.getViewExtent();
    // performing a sort by entityId in order to obtain more stable clustering
    const entitiesInsideView = _.sortBy(
      entities.filter((e) =>
        filterEntitiesOutsideMapExtents
          ? GeospatialHelper.insideExtents(mapViewExtent, locationMapper(e))
          : true
      ),
      (e) => idMapper(e)
    );
    const mapCenterMercator =
      GeospatialHelper.calculateMapCenter(mapViewExtent);
    const iconDiameterInMeters =
      GeospatialHelper.calculateCircleOverlapDistanceInMeters();

    // the move location/move threshold etc is being done to avoid hiding the entity group
    // behind the site icon
    const thresholdMoveDistance =
      GeospatialHelper.calculateThresholdDistance(iconDiameterInMeters);
    const { selectedSite } = siteStore;
    const siteLocation = selectedSite.pickupLocation;
    const moveToLocation = GeospatialHelper.determineMoveLocation(
      siteLocation,
      thresholdMoveDistance
    );

    const entityGroupFormationInfo =
      GeospatialHelper.gatherEntityGroupFormationInfo(
        entitiesInsideView,
        locationMapper,
        (o) => [locationMapper(o)],
        (entity) => [entity],
        mapCenterMercator,
        iconDiameterInMeters,
        thresholdMoveDistance,
        moveToLocation,
        siteLocation
      );

    let newEntityGroups = GeospatialHelper.combineNearbyCollidingGroups(
      entityGroupFormationInfo.sortedGridCellKeys,
      entityGroupFormationInfo.entityGroupMapByGridCellKey,
      iconDiameterInMeters,
      locationMapper
    );

    if (
      newEntityGroups.length !==
      entityGroupFormationInfo.sortedGridCellKeys.length
    ) {
      while (true) {
        // need another iteration of combining entity groups
        const entityGroupFormationInfo =
          GeospatialHelper.gatherEntityGroupFormationInfo(
            newEntityGroups,
            (o) => o.centroid,
            (o) => o.entities.map((e) => locationMapper(e)),
            (o) => o.entities,
            mapCenterMercator,
            iconDiameterInMeters,
            thresholdMoveDistance,
            moveToLocation,
            siteLocation
          );

        newEntityGroups = GeospatialHelper.combineNearbyCollidingGroups(
          entityGroupFormationInfo.sortedGridCellKeys,
          entityGroupFormationInfo.entityGroupMapByGridCellKey,
          iconDiameterInMeters,
          locationMapper
        );

        if (
          newEntityGroups.length ===
          entityGroupFormationInfo.sortedGridCellKeys.length
        ) {
          break;
        }
      }
    }

    return newEntityGroups;
  }

  /**
   * This function will form an array of sorted grid keys and a map which has the array of
   * things in the grid cell.  The things have the generic type of GridCellGroupType and could either
   * be the EntityType (e.g. orders or transporters) or could be EntityGroup's.
   *
   * The function takes the things, calculates their grid key based on the location of the thing, then
   * determines how many of the things are in the same grid key and groups them together into a single
   * grid cell by forming a new EntityGroup with all the things inside that EntityGroup.
   * @param gridCellGroupObjects
   * @param gridCellObjectToLocationMapper this function will take a thing and provide its location
   * @param gridCellGroupToLocationsMapper this function is used when forming a new EntityGroup of all things
   * in the grid cell by providing the location of each thing
   * @param gridCellGroupToEntitiesMapper this function is used when forming a new EntityGroup of all things
   * in the grid cell by providing the entities of each thing
   * @param mapCenterMercator
   * @param iconDiameterInMeters
   * @param thresholdMoveDistance
   * @param moveToLocation
   * @param siteLocation
   * @private
   */
  private static gatherEntityGroupFormationInfo<GridCellGroupType, EntityType>(
    gridCellGroupObjects: GridCellGroupType[],
    gridCellObjectToLocationMapper: (GridCellGroupType) => MapLocation,
    gridCellGroupToLocationsMapper: (GridCellGroupType) => MapLocation[],
    gridCellGroupToEntitiesMapper: (GridCellGroupType) => EntityType[],
    mapCenterMercator: MercatorMapLocation,
    iconDiameterInMeters: number,
    thresholdMoveDistance: number,
    moveToLocation: MapLocation,
    siteLocation: MapLocation
  ): EntityGroupFormationInfo<EntityType> {
    const objectsGroupedByGridCellKey = _.groupBy(
      gridCellGroupObjects,
      (gridCellGroupObject) => {
        const gridCell = GeospatialHelper.calculateGridCellForLocation(
          mapCenterMercator,
          gridCellObjectToLocationMapper(gridCellGroupObject),
          iconDiameterInMeters
        );
        return GeospatialHelper.formGridCellKey(gridCell.x, gridCell.y);
      }
    );

    const sortedGridCellKeys = _.keys(objectsGroupedByGridCellKey);
    GeospatialHelper.sortGridCellKeys(sortedGridCellKeys);

    const entityGroupMapByGridCellKey: Map<
      string,
      EntityGroup<EntityType>
    > = new Map();

    const entityGroups = sortedGridCellKeys.map((gridCellKey) => {
      const objectsInCell = objectsGroupedByGridCellKey[gridCellKey];
      const points = objectsInCell.flatMap((o) =>
        gridCellGroupToLocationsMapper(o)
      );
      const centroid = getCenter(points) as MapLocation;
      const entityGroup: EntityGroup<EntityType> = {
        centroid,
        entities: objectsInCell.flatMap((objectInCell) =>
          gridCellGroupToEntitiesMapper(objectInCell)
        ),
      };
      entityGroupMapByGridCellKey.set(gridCellKey, entityGroup);
      return entityGroup;
    });

    GeospatialHelper.moveEntityGroupsIfTooClose(
      entityGroups,
      siteLocation,
      thresholdMoveDistance,
      moveToLocation
    );

    const response: EntityGroupFormationInfo<EntityType> = {
      entityGroupMapByGridCellKey,
      sortedGridCellKeys,
    };

    return response;
  }

  private static sortGridCellKeys(keys: string[]): void {
    keys.sort((key1, key2) => {
      const [x1, y1] = GeospatialHelper.splitGridCellKey(key1);
      const [x2, y2] = GeospatialHelper.splitGridCellKey(key2);
      if (y1 === y2) {
        return x1 - x2;
      }
      return y2 - y1;
    });
  }

  private static combineNearbyCollidingGroups<EntityType>(
    keys: string[],
    map: Map<string, EntityGroup<EntityType>>,
    iconDiameterInMeters: number,
    locationMapper: (EntityType) => MapLocation
  ): EntityGroup<EntityType>[] {
    const newEntityGroups: EntityGroup<EntityType>[] = [];
    const processedKeys: string[] = [];
    keys.forEach((key) => {
      if (!_.includes(processedKeys, key)) {
        const thisEntity = map.get(key);
        const [x, y] = GeospatialHelper.splitGridCellKey(key);
        const valuesToCheck: number[][] = [
          [x + 1, y],
          [x + 1, y - 1],
          [x, y - 1],
          [x - 1, y - 1],
        ];
        const collidingKeys: string[] = [];
        valuesToCheck.forEach(([otherX, otherY]) => {
          const otherGridCellKey = GeospatialHelper.formGridCellKey(
            otherX,
            otherY
          );
          if (
            !_.includes(processedKeys, otherGridCellKey) &&
            GeospatialHelper.doesCollideWithThisEntity(
              thisEntity,
              otherX,
              otherY,
              map,
              iconDiameterInMeters
            )
          ) {
            collidingKeys.push(otherGridCellKey);
          }
        });
        if (collidingKeys.length > 0) {
          collidingKeys.push(key);
          const combinedEntityGroup = GeospatialHelper.combineAllEntityGroups(
            collidingKeys,
            map,
            locationMapper
          );
          newEntityGroups.push(combinedEntityGroup);
          processedKeys.push(...collidingKeys);
        } else {
          processedKeys.push(key);
          newEntityGroups.push(thisEntity);
        }
      }
    });
    return newEntityGroups;
  }

  private static doesCollideWithThisEntity<EntityType>(
    thisEntity: EntityGroup<EntityType>,
    x: number,
    y: number,
    map: Map<string, EntityGroup<EntityType>>,
    iconDiameterInMeters: number
  ): boolean {
    const otherEntity = map.get(GeospatialHelper.formGridCellKey(x, y));
    if (otherEntity) {
      const bufferFactor = 1.1;
      return isPointWithinRadius(
        thisEntity.centroid,
        otherEntity.centroid,
        iconDiameterInMeters * bufferFactor
      );
    }
    return false;
  }

  private static splitGridCellKey(key: string): number[] {
    const parts = key.split(',');
    return [Number(parts[0]), Number(parts[1])];
  }

  private static formGridCellKey(x: number, y: number): string {
    return `${x},${y}`;
  }

  private static calculateMapCenter(
    mapViewExtent: ViewExtent
  ): MercatorMapLocation {
    const topLeftMercator = MapObject.toMercator(mapViewExtent.topLeft);
    const bottomRightMercator = MapObject.toMercator(mapViewExtent.bottomRight);
    const initialX = (topLeftMercator.x + bottomRightMercator.x) / 2;
    const initialY = (topLeftMercator.y + bottomRightMercator.y) / 2;
    const mapCenterMercator: MercatorMapLocation = {
      x: initialX,
      y: initialY,
    };
    return mapCenterMercator;
  }

  /**
   * The origin of the coordinate system is the center of the map
   * @param mapCenter
   * @param entityLocation
   * @param iconDiameterInMeters
   * @private
   */
  private static calculateGridCellForLocation(
    mapCenter: MercatorMapLocation,
    location: GpsLocation,
    iconDiameterInMeters: number
  ): GridCell {
    const entityMercator = MapObject.toMercator(location);
    const deltaX = entityMercator.x - mapCenter.x;
    const deltaY = entityMercator.y - mapCenter.y;
    const numberXGridPositions = GeospatialHelper.roundToGridPositionInteger(
      deltaX / iconDiameterInMeters
    );
    const numberYGridPositions = GeospatialHelper.roundToGridPositionInteger(
      deltaY / iconDiameterInMeters
    );
    const gridCell: GridCell = {
      x: numberXGridPositions,
      y: numberYGridPositions,
    };
    return gridCell;
  }

  private static roundToGridPositionInteger(gridNumber: number): number {
    if (gridNumber <= 0.5 && gridNumber > -0.5) {
      return 0;
    }
    if (gridNumber > 0) {
      return Math.floor(gridNumber + 0.5);
    }
    if (gridNumber < 0) {
      return Math.ceil(gridNumber - 0.5);
    }
  }

  private static calculateCircleOverlapDistanceInMeters() {
    const paddingFactor = 0.9;
    const diameterInPixels = 24 * paddingFactor;
    const mapResolution = MapObject.getMapResolution();
    return mapResolution * diameterInPixels;
  }

  private static calculateThresholdDistance(iconDiameterInMeters: number) {
    return iconDiameterInMeters * 0.5;
  }

  private static determineMoveLocation(
    siteLocation: MapLocation,
    distance: number
  ) {
    return GeospatialHelper.getDestinationLocation(siteLocation, distance, 45);
  }

  private static moveEntityGroupsIfTooClose<EntityType>(
    entityGroups: EntityGroup<EntityType>[],
    siteLocation: MapLocation,
    thresholdDistanceInMeters: number,
    moveToLocation: MapLocation
  ) {
    entityGroups.forEach((eg) => {
      const distanceFromSite = GeospatialHelper.getDistance(
        siteLocation,
        eg.centroid
      );
      if (distanceFromSite < thresholdDistanceInMeters) {
        eg.centroid = moveToLocation;
      }
    });
  }

  private static combineAllEntityGroups<EntityType>(
    collidingKeys: string[],
    map: Map<string, EntityGroup<EntityType>>,
    locationMapper: (EntityType) => MapLocation
  ): EntityGroup<EntityType> {
    const entities: EntityType[] = [];
    collidingKeys.forEach((key) => {
      const entityGroup = map.get(key);
      entities.push(...entityGroup.entities);
    });
    const points = entities.map((e) => locationMapper(e));
    const centroid = getCenter(points) as MapLocation;
    const combinedGroup: EntityGroup<EntityType> = {
      entities,
      centroid,
    };
    return combinedGroup;
  }

  private static insideExtents(
    viewExtent: ViewExtent,
    mapLocation: MapLocation
  ) {
    if (
      viewExtent &&
      viewExtent.topLeft &&
      viewExtent.bottomRight &&
      mapLocation
    ) {
      const locationLat = mapLocation.latitude;
      const locationLon = mapLocation.longitude;
      const maxLat = viewExtent.topLeft.latitude;
      const minLat = viewExtent.bottomRight.latitude;
      const maxLon = viewExtent.bottomRight.longitude;
      const minLon = viewExtent.topLeft.longitude;
      return (
        locationLat >= minLat &&
        locationLat <= maxLat &&
        locationLon >= minLon &&
        locationLon <= maxLon
      );
    } else {
      return true;
    }
  }
}
