import * as _ from 'lodash';
import { BehaviorSubject, Observable, auditTime, merge } from 'rxjs';
import { Route } from '@amzn/gsf-dispatcher-schema';
import { reaction } from 'mobx';
import RouteFilterController from '../controllers/RouteFilterController';
import filterReactor from './filterReactor';
import routeStore from 'stores/routeStore';

class RouteReactor {
  private routesChangeSubject = new BehaviorSubject<boolean>(false);
  routesChange$: Observable<boolean> = this.routesChangeSubject.asObservable();

  private filteredRoutesChangeSubject = new BehaviorSubject<boolean>(false);
  filteredRoutesChange$: Observable<boolean> =
    this.filteredRoutesChangeSubject.asObservable();

  private routeAssignmentChangeSubject = new BehaviorSubject<boolean>(false);
  routeAssignmentChange$: Observable<boolean> =
    this.routeAssignmentChangeSubject.asObservable();

  private selectedRouteIdChangeSubject = new BehaviorSubject<boolean>(false);
  selectedRouteIdChange$: Observable<boolean> =
    this.selectedRouteIdChangeSubject.asObservable();

  constructor() {
    reaction(
      () => {
        return routeStore.routes;
      },
      (routes: Route[]) => {
        this.routesChangeSubject.next(true);
      }
    );

    reaction(
      () => {
        return routeStore.selectedRouteId;
      },
      (selectedRouteId) => {
        this.selectedRouteIdChangeSubject.next(true);
      }
    );

    reaction(
      () => {
        return routeStore.filteredRoutes;
      },
      (filteredRoutes: Route[]) => {
        this.filteredRoutesChangeSubject.next(true);
      }
    );

    reaction(
      () => {
        return routeStore.routes.map((r) => r.transporter);
      },
      (assignedTransporters) => {
        this.routeAssignmentChangeSubject.next(true);
      }
    );

    /**
     * listen to changes in the routes and remove the selected route id
     * if the route id no longer exists in the routes
     * */
    this.routesChange$.subscribe(async (event) => {
      const { routes, selectedRouteId } = routeStore;

      // check if selected route id no longer exist
      const validRouteIds = routes.map((r) => r.routeId);
      if (!_.includes(validRouteIds, selectedRouteId)) {
        routeStore.setSelectedRouteId(undefined);
      }
    });

    /**
     * listen to changes in the routes, the order view, and the order filter selections, or route assignment and
     * perform route filter in a rate limited way
     * (max once per 200msec)
     */
    const rateLimitedObservableForRouteFilter = merge(
      this.routesChange$.pipe(),
      filterReactor.selectedOrderFilterCodesChange$.pipe(),
      this.routeAssignmentChange$.pipe()
    ).pipe(auditTime(200));
    rateLimitedObservableForRouteFilter.subscribe(async (event) => {
      const { routes } = routeStore;
      const filteredRoutes = RouteFilterController.filterRoutes(routes);
      routeStore.setFilteredRoutes(filteredRoutes);
    });
  }
}

export default new RouteReactor();
