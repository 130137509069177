export const ENV_BETA = 'beta';

export const ENV_GAMMA_NA = 'gamma';

export const ENV_GAMMA_EU = 'gamma-eu';
export const ENV_GAMMA_FE = 'gamma-fe';
export const ENV_GAMMA_IN = 'gamma-in';

export const ENV_PROD_NA = 'prod';

export const ENV_PROD_EU = 'prod-eu';
export const ENV_PROD_FE = 'prod-fe';
export const ENV_PROD_IN = 'prod-in';

export const REGION_NA = 'us-east-1';
export const REGION_EU = 'eu-west-1';

export const REGION_FE = 'us-west-2';
