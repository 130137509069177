import './MastHeadComp.css';
import { APP_NAME } from '../../AppConstants';
import { observer } from 'mobx-react';
import Link from '@amzn/meridian/link';
import LogoutComp from './LogoutComp';
import Masthead, { MastheadTitle } from '@amzn/meridian/masthead';
import NetworkStatusComp from './NetworkStatusComp';
import React from 'react';
import Row from '@amzn/meridian/row';
import SiteSearchComp from './SiteSearchComp';
import StaleDataWarningComp from './StaleDataWarningComp';
import Text from '@amzn/meridian/text';
import UrlHelper from 'util/UrlHelper';
import authStore from '../../stores/authStore';
import logoImage from '../../assets/images/logo192.png';

export interface MastHeadProps {}

class MastHeadComp extends React.Component<MastHeadProps> {
  render() {
    return (
      <Masthead size="medium">
        <MastheadTitle>
          <Row spacing="300">
            <img className="application-logo" src={logoImage} />
            <Text fontFamily="amazonEmber">
              <b>{`${APP_NAME} - ${UrlHelper.getEnvironmentName()}`}</b>
            </Text>
          </Row>
        </MastheadTitle>
        <NetworkStatusComp />
        <StaleDataWarningComp />
        <Row spacingInset="none">
          <Link
            href={
              'https://w.amazon.com/bin/view/GSF/Teams/SCAR/Tech/Projects/Dis2/SOP/'
            }
            propagateClickEvent={false}
            target="_blank"
            rel="noopener noreferrer"
          >
            {'SOP'}
          </Link>
        </Row>
        <Row spacingInset="300">
          <Link
            href={
              'https://t.corp.amazon.com/create/templates/f6b1f328-c398-4756-af51-66f577e63060'
            }
            propagateClickEvent={false}
            target="_blank"
            rel="noopener noreferrer"
          >
            {'Report a bug?'}
          </Link>
        </Row>
        <SiteSearchComp />
        <Row>
          <Text>{authStore.preferredUsername}</Text>
        </Row>
        <LogoutComp />
      </Masthead>
    );
  }
}

export default observer(MastHeadComp);
