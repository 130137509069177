import { Route } from '@amzn/gsf-dispatcher-schema';
import { observer } from 'mobx-react';
import React from 'react';
import RouteComp from './RouteComp';

export interface RouteListProps {
  routes: Route[];
}

class RouteListComp extends React.Component<RouteListProps> {
  render() {
    const { routes } = this.props;
    const routeComps = routes.map((r, index) => (
      <RouteComp key={`route-${r.routeId}`} route={r} />
    ));
    return routeComps;
  }
}

export default observer(RouteListComp);
