import {
  DELIVERY_WINDOW_END_TIME,
  SORT_OPTIONS,
} from '../../util/SortConstants';
import DataSortController from '../../controllers/DataSortController';
import React from 'react';
import Row from '@amzn/meridian/row';
import Select, { SelectOption } from '@amzn/meridian/select';

export interface OrderSortProps {}

export interface OrderSortState {
  value: string;
}

export default class OrderSortComp extends React.Component<
  OrderSortProps,
  OrderSortState
> {
  componentDidMount() {
    this.setState({ value: DELIVERY_WINDOW_END_TIME });
  }

  constructor(props: OrderSortProps) {
    super(props);
  }
  render() {
    const selectOptions = SORT_OPTIONS.map((option) => (
      <SelectOption
        key={`orderSortOption${option.code}`}
        label={option.displayValue}
        value={option.code}
      />
    ));
    return (
      <Row spacingInset="100">
        <Select
          label="Sort By"
          value={this.state?.value}
          onChange={(newValue) => this.handleValueChange(newValue)}
          width="100%"
        >
          {selectOptions}
        </Select>
      </Row>
    );
  }

  handleValueChange(newValue: string) {
    this.setState({ value: newValue });
    DataSortController.handleSelectedSortCodeChange(newValue);
  }
}
