import { BehaviorSubject, Observable } from 'rxjs';
import { reaction } from 'mobx';
import searchByTransporterNameStore from 'stores/searchByTransporterNameStore';

class SearchByTransporterNameReactor {
  private searchByNameValuesChangeSubject = new BehaviorSubject<boolean>(false);
  searchByNameValuesChange$: Observable<boolean> =
    this.searchByNameValuesChangeSubject.asObservable();

  constructor() {
    reaction(
      () => {
        return searchByTransporterNameStore.searchByNameValues;
      },
      (searchByNameValues: string[]) => {
        this.searchByNameValuesChangeSubject.next(true);
      }
    );
  }
}

export default new SearchByTransporterNameReactor();
