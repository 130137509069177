import * as _ from 'lodash';
import { Message } from '../messages/model/Message';
import { action, makeObservable, observable } from 'mobx';

class MessagesStore {
  messages: Message[];

  constructor() {
    makeObservable(this, {
      messages: observable,
      addMessage: action,
      removeMessageWithId: action,
      clearAllMessages: action,
    });

    this.messages = [];
  }

  clearAllMessages() {
    this.messages = [];
  }

  addMessage(message: Message) {
    const messages = _.clone(this.messages);
    messages.push(message);
    this.messages = messages;
  }

  removeMessageWithId(messageId: string) {
    const messages = _.clone(this.messages);
    const existingMessage = messages.find((m) => m.id === messageId);
    if (existingMessage) {
      const index = messages.indexOf(existingMessage);
      messages.splice(index, 1);
      this.messages = messages;
    }
  }
}

export default new MessagesStore();
