import { ConnectionState } from '@aws-amplify/pubsub';

export class NetworkStatusHelper {
  static isNetworkUp(connectionHistory: ConnectionState[]): boolean {
    const lastIndex = connectionHistory.length - 1;
    const successfulConnectionEndIndex =
      NetworkStatusHelper.lastSuccessfulConnectionSequenceEndIndex(
        connectionHistory,
        lastIndex
      );
    return (
      successfulConnectionEndIndex !== -1 &&
      lastIndex === successfulConnectionEndIndex
    );
  }

  static isNetworkDown(connectionHistory: ConnectionState[]): boolean {
    const currentState = NetworkStatusHelper.getCurrentState(connectionHistory);
    const successfulConnectionEndIndex =
      NetworkStatusHelper.lastSuccessfulConnectionSequenceEndIndex(
        connectionHistory,
        connectionHistory.length - 2
      );
    return (
      successfulConnectionEndIndex !== -1 &&
      (currentState === ConnectionState.ConnectedPendingNetwork ||
        currentState === ConnectionState.ConnectionDisruptedPendingNetwork)
    );
  }

  static shouldResetData(connectionHistory: ConnectionState[]): boolean {
    const currentState = NetworkStatusHelper.getCurrentState(connectionHistory);
    const endingIndex = connectionHistory.length - 1;
    if (currentState === ConnectionState.Disconnected && endingIndex > 1) {
      return true;
    } else if (currentState === ConnectionState.Connected) {
      // see if we have successful connection
      const lastSuccessfulConnectionEndIndex =
        NetworkStatusHelper.lastSuccessfulConnectionSequenceEndIndex(
          connectionHistory,
          endingIndex
        );
      const priorSuccessfulConnectionEndIndex =
        NetworkStatusHelper.lastSuccessfulConnectionSequenceEndIndex(
          connectionHistory,
          lastSuccessfulConnectionEndIndex - 2
        );
      if (
        lastSuccessfulConnectionEndIndex !== -1 &&
        lastSuccessfulConnectionEndIndex === endingIndex &&
        priorSuccessfulConnectionEndIndex !== -1 &&
        priorSuccessfulConnectionEndIndex ===
          lastSuccessfulConnectionEndIndex - 2
      ) {
        // we don't want to continually restart if we have Connecting, Connected, Connecting, Connected sequence, so
        // return false to avoid infinite data resets
        return false;
      } else if (
        lastSuccessfulConnectionEndIndex !== -1 &&
        lastSuccessfulConnectionEndIndex === endingIndex &&
        priorSuccessfulConnectionEndIndex !== -1 &&
        lastSuccessfulConnectionEndIndex - priorSuccessfulConnectionEndIndex >
          2 &&
        NetworkStatusHelper.dataLossStatuses(
          connectionHistory,
          priorSuccessfulConnectionEndIndex + 1,
          lastSuccessfulConnectionEndIndex - 1
        )
      ) {
        // we have reached a good connection and previously had a good connection
        // but in between there was a state which could have resulted in data not being synced,
        // so we will refresh the data.  In the history this would look something like
        // Connecting, Connected, bad stuff like ConnectedPendingNetwork etc, Connecting, Connected
        return true;
      } else if (
        lastSuccessfulConnectionEndIndex !== -1 &&
        lastSuccessfulConnectionEndIndex < endingIndex - 1 &&
        NetworkStatusHelper.dataLossStatuses(
          connectionHistory,
          lastSuccessfulConnectionEndIndex + 1,
          endingIndex
        )
      ) {
        // if the network was lost for a short time, the history may look like
        // Connecting,Connected,bad stuff like ConnectedPendingNetwork,Connected in which case there
        // is potential data loss
        return true;
      }
    }
    return false;
  }

  // check if any data loss statuses exist between these indices in the history
  private static dataLossStatuses(
    connectionHistory: ConnectionState[],
    startingIndex: number,
    endingIndexExclusive: number
  ): boolean {
    for (let i = startingIndex; i < endingIndexExclusive; i++) {
      const status = connectionHistory[i];
      const exists = status !== ConnectionState.Connected;
      if (exists) {
        return true;
      }
    }
    return false;
  }

  /**
   * successful connection means that the sequence of Connecting followed by Connected was observed
   * in the past (not current state)
   * @private
   */
  private static lastSuccessfulConnectionSequenceEndIndex(
    connectionHistory: ConnectionState[],
    maxIndex: number
  ): number {
    for (let i = maxIndex; i >= 0; i--) {
      const status = connectionHistory[i];
      const previousStatus = i > 0 ? connectionHistory[i - 1] : undefined;
      if (
        status === ConnectionState.Connected &&
        previousStatus === ConnectionState.Connecting
      ) {
        return i;
      }
    }
    return -1;
  }

  private static getCurrentState(
    connectionHistory: ConnectionState[]
  ): ConnectionState {
    return connectionHistory[connectionHistory.length - 1];
  }
}
