import './MapTransporterGroupComp.css';
import * as _ from 'lodash';
import { observer } from 'mobx-react';
import Box from '@amzn/meridian/box';
import MapLocatableEntityComp from '../common/MapLocatableEntityComp';
import Popover from '@amzn/meridian/popover';
import React, { RefObject } from 'react';
import Row from '@amzn/meridian/row';
import StringHelper from 'util/StringHelper';
import Text from '@amzn/meridian/text';
import TransporterComp from 'components/transporter/TransporterComp';
import TransporterGroup from 'map/model/TransporterGroup';
import TransporterSortController from 'controllers/TransporterSortController';
import transporterGroupImage from '../../../assets/images/transporter.svg';
import transporterStore from 'stores/transporterStore';

interface MapTransporterGroupProps {
  transporterGroup: TransporterGroup;
  // highlighted means to show the circle fully green regardless of the selected percent
  highlighted: boolean;
}

interface MapTransporterGroupState {
  popupOpened: boolean;
  lastCloseTime: number;
}

class MapTransporterGroupComp extends MapLocatableEntityComp<
  MapTransporterGroupProps,
  MapTransporterGroupState
> {
  private readonly ref: RefObject<HTMLDivElement>;
  constructor(props: MapTransporterGroupProps) {
    super(
      props,
      { popupOpened: false, lastCloseTime: 0 },
      'transporterGroup',
      'centroid',
      'map-transporter-group-locatable-entity'
    );
    this.closePopup = this.closePopup.bind(this);
    this.clickTransporterGroup = this.clickTransporterGroup.bind(this);
    this.render = this.render.bind(this);
    this.ref = React.createRef();
  }

  render() {
    const { transporterGroup, highlighted } = this.props;
    const transporters = TransporterSortController.sortTransporters(
      transporterGroup.entities
    );
    const transporterComps = transporters.map((t) => (
      <TransporterComp
        key={`transporterListItem${t.transporterId}`}
        transporter={t}
      />
    ));
    const { popupOpened } = this.state;
    const { selectedTransporterId } = transporterStore;
    const transporterIdsInGroup = transporters.map((t) => t.transporterId);
    const selected = _.includes(transporterIdsInGroup, selectedTransporterId);
    const selectedPercent = highlighted
      ? 100
      : Math.floor((100 * (selected ? 1 : 0)) / transporterIdsInGroup.length);
    // setting a CSS variable so that the percentage of selected transporters can be rendered as a pie chart
    // on the transporter group cluster icon
    const selectedTransporterPercentageStyle = {
      '--selectedPercentage': selectedPercent,
    } as React.CSSProperties;
    return (
      // it is important to wrap the jsx with a parent div in order to prevent
      // a react error when restarting the development server
      <div>
        <div
          id={this.getId()}
          className={
            highlighted || selected
              ? 'map-transporter-group-container-selected'
              : 'map-transporter-group-container'
          }
          style={
            highlighted || selected ? selectedTransporterPercentageStyle : {}
          }
          onClick={() => this.clickTransporterGroup(transporterGroup)}
          ref={this.ref}
        >
          <img
            className={'map-transporter-group-image'}
            src={transporterGroupImage}
          />
          <div className={'map-transporter-group-count-badge-label'}>
            {transporters.length}
          </div>
        </div>
        <Popover
          anchorNode={this.ref.current}
          open={popupOpened}
          onClose={this.closePopup}
          position={'end'}
          alignment={'center'}
        >
          <Row spacingInset="100" alignmentHorizontal="center" spacing="300">
            <Text type="h300">
              {StringHelper.makeCountDisplay(
                transporters.length,
                'Transporter'
              )}
            </Text>
          </Row>
          <Box overflowY="scroll" maxHeight={'20em'}>
            {transporterComps}
          </Box>
        </Popover>
      </div>
    );
  }

  async clickTransporterGroup(transporterGroup: TransporterGroup) {
    const { lastCloseTime } = this.state;
    // add a 250 msec delay so that user can click the order cluster to close the popup without it popping up again
    if (Date.now() - lastCloseTime >= 250) {
      this.setState({ popupOpened: true });
    }
  }

  closePopup() {
    this.setState({ popupOpened: false, lastCloseTime: Date.now() });
  }
}

export default observer(MapTransporterGroupComp);
