import { action, makeObservable, observable } from 'mobx';

class ApplicationStatusStore {
  networkUp: boolean = true;

  constructor() {
    makeObservable(this, {
      networkUp: observable,
      setNetworkUp: action,
    });
  }

  setNetworkUp(value: boolean) {
    this.networkUp = value;
  }
}

export default new ApplicationStatusStore();
