import { KatalMetricEntity } from '@amzn/katal-metrics-driver-aws-services';
import { metricsPublisher } from './KatalMetricPublishers';

let sequence = 0;

export default class Log {
  static error(message: string, operation: string = undefined) {
    Log.log('ERROR', message, operation);
  }

  static warn(message: string, operation: string = undefined) {
    Log.log('WARN', message, operation);
  }

  static info(message: string, operation: string = undefined) {
    Log.log('INFO', message, operation);
  }

  static debug(message: string, operation: string = undefined) {
    Log.log('DEBUG', message, operation);
  }

  private static log(
    level: string,
    message: string,
    operation: string = undefined
  ) {
    try {
      if (sequence >= Number.MAX_SAFE_INTEGER) {
        sequence = 0;
      }
      sequence++;

      const log: KatalMetricEntity = new KatalMetricEntity({
        type: 'Log',
        operation,
        level,
        message,
        sequence,
      });
      metricsPublisher.publish(log);
    } catch (e) {
      // do nothing
    }
  }
}
