import { BehaviorSubject, Observable } from 'rxjs';
import { DateTime, SystemZone } from 'luxon';
import { NOT_AVAILABLE } from '../AppConstants';
import { TimeWindow } from '@amzn/gsf-dispatcher-schema';
import siteStore from '../stores/siteStore';

export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;

export const SUCCESS_MESSAGE_TIMEOUT = 3 * SECOND;
export const WARNING_MESSAGE_TIMEOUT = 5 * SECOND;
export const ERROR_MESSAGE_TIMEOUT = 30 * SECOND;
export default class TimeHelper {
  private static lastMinuteValueSent: number = 0;

  private static minuteChangeSubject = new BehaviorSubject<boolean>(false);

  private static secondChangeSubject = new BehaviorSubject<boolean>(false);

  static minuteChange$: Observable<boolean> =
    TimeHelper.minuteChangeSubject.asObservable();

  static secondChange$: Observable<boolean> =
    TimeHelper.secondChangeSubject.asObservable();

  static getSelectedSiteTimeZone(): string {
    return siteStore.selectedSite?.timezone;
  }

  static secondsToHoursString(numberSeconds: number): string {
    if (numberSeconds < 0) {
      throw new Error(`numberOfSeconds cannot be negative: ${numberSeconds}`);
    }
    let numberHours = Math.floor(numberSeconds / 3600);
    const numberMinutesExact = (numberSeconds - 3600 * numberHours) / 60;
    let numberMinutes = Math.ceil(numberMinutesExact)
      .toFixed(0)
      .padStart(2, '0');
    if (numberMinutes === '60') {
      numberMinutes = '00';
      numberHours++;
    }

    return `${numberHours}:${numberMinutes} hr`;
  }

  static getDataAge(pastTimeMillisSinceEpoch: string): string {
    if (pastTimeMillisSinceEpoch) {
      const now = Date.now();
      const then = parseInt(pastTimeMillisSinceEpoch);
      const numberSeconds = Math.floor((now - then) / 1000);
      if (numberSeconds < 60) {
        return `${numberSeconds} sec`;
      }
      if (numberSeconds < 3600) {
        const numberMinutes = (numberSeconds / 60).toFixed(1);
        return `${numberMinutes} min`;
      }
      const numberHours = (numberSeconds / 3600).toFixed(1);
      return `${numberHours} hr`;
    } else {
      return 'never';
    }
  }

  static timeWindowAsString(timeWindow: TimeWindow): string {
    const timeZone =
      TimeHelper.getSelectedSiteTimeZone() || new SystemZone().name;
    const { startDate, endDate } = timeWindow;

    const startTime = startDate
      ? TimeHelper.getHoursMinutesFromMillisecSinceEpoch(startDate, timeZone)
      : NOT_AVAILABLE;
    const endTime = endDate
      ? TimeHelper.getHoursMinutesFromMillisecSinceEpoch(endDate, timeZone)
      : NOT_AVAILABLE;
    return `${startTime}-${endTime} ${timeZone}`;
  }

  /**
   * return a string in the format h:mm hr with a negative sign if a full minute in the past.  The intent
   * is for a user watching their clock will observe a full minute unless the clock has rolled over to a new
   * minute.
   * @param endTime time in millisec since epoch
   */
  static hoursTillEndTime(endTime: string): string {
    if (endTime) {
      const now = Date.now();
      const then = parseInt(endTime);
      const numberSeconds = Math.abs((then - now) / 1000);
      let numberHours = Math.floor(numberSeconds / 3600);
      const numberMinutesExact = (numberSeconds - 3600 * numberHours) / 60;
      let numberMinutes = (
        then > now
          ? Math.ceil(numberMinutesExact)
          : Math.floor(numberMinutesExact)
      )
        .toFixed(0)
        .padStart(2, '0');
      if (numberMinutes === '60') {
        numberMinutes = '00';
        numberHours++;
      }
      const prefix =
        (numberHours === 0 && numberMinutes === '00') || then > now ? '' : '-';

      return `${prefix}${numberHours}:${numberMinutes} hr`;
    }
    return '';
  }

  /**
   * returns true if the time span is exactly the specified time span
   * @param timeWindow
   * @param timeSpan an object e.g. { hours: 1}
   */
  static isTimeSpan(timeWindow: TimeWindow, timeSpan: object): boolean {
    const startDate = DateTime.fromMillis(
      Number.parseInt(timeWindow.startDate)
    );
    const endDate = DateTime.fromMillis(Number.parseInt(timeWindow.endDate));
    return startDate.plus(timeSpan).toMillis() === endDate.toMillis();
  }

  static async waitTillPredicateIsTrue(
    predicate: () => boolean,
    waitSomeAdditionalTimeAfterPredicateIsTrue = false
  ): Promise<void> {
    return new Promise(async (resolve) => {
      if (predicate()) {
        if (waitSomeAdditionalTimeAfterPredicateIsTrue) {
          await TimeHelper.waitSomeTime();
        }
        resolve();
      } else {
        setTimeout(async () => {
          await TimeHelper.waitTillPredicateIsTrue(
            predicate,
            waitSomeAdditionalTimeAfterPredicateIsTrue
          );
          resolve();
        }, 100);
      }
    });
  }

  private static async waitSomeTime(): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 100);
    });
  }

  private static setupMinuteEvent() {
    setInterval(() => {
      TimeHelper.secondChangeSubject.next(true);
      const now = Date.now();
      const minutes = Math.floor(now / 1000 / 60);
      if (minutes !== TimeHelper.lastMinuteValueSent) {
        // fire event
        TimeHelper.lastMinuteValueSent = minutes;
        TimeHelper.minuteChangeSubject.next(true);
      }
    }, 1000);
  }

  private static getHoursMinutesFromMillisecSinceEpoch(
    millisecSinceEpoch: string,
    timeZone: string = undefined
  ): string {
    const date = DateTime.fromMillis(Number.parseInt(millisecSinceEpoch), {
      zone: timeZone,
    });
    return date.toFormat('HH:mm');
  }
}

TimeHelper['setupMinuteEvent']();
