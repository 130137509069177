import { action, makeObservable, observable } from 'mobx';

class FilterStore {
  selectedOrderFilterCodes: string[];
  selectedTransporterFilterCodes: string[];

  constructor() {
    makeObservable(this, {
      selectedOrderFilterCodes: observable,
      selectedTransporterFilterCodes: observable,
      setSelectedOrderFilterCodes: action,
      setSelectedTransporterFilterCodes: action,
    });

    this.selectedOrderFilterCodes = [];
    this.selectedTransporterFilterCodes = [];
  }

  setSelectedOrderFilterCodes(filterCodes: string[]) {
    this.selectedOrderFilterCodes = filterCodes;
  }

  setSelectedTransporterFilterCodes(filterCodes: string[]) {
    this.selectedTransporterFilterCodes = filterCodes;
  }
}

export default new FilterStore();
