import { APP_NAME } from '../AppConstants';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  HTTP_PARAM_SERVICE_AREA_ID,
  HTTP_PARAM_SITE_CODE,
} from '../util/UrlConstants';
import {
  LOG_ATTRIBUTE_SITE_CODE,
  LOG_ATTRIBUTE_SITE_COUNTRY_CODE,
} from '../logging/LogConstants';
import { Site } from '@amzn/gsf-dispatcher-schema';
import { metricsPublisher } from '../logging/KatalMetricPublishers';
import { reaction } from 'mobx';
import SiteWarmingController from '../controllers/SiteWarmingController';
import UrlHelper from '../util/UrlHelper';
import orderStore from '../stores/orderStore';
import routeStore from '../stores/routeStore';
import siteStore from '../stores/siteStore';
import transporterStore from '../stores/transporterStore';

class SiteReactor {
  private selectedSiteChangeSubject = new BehaviorSubject<boolean>(false);

  selectedSiteChange$: Observable<boolean> =
    this.selectedSiteChangeSubject.asObservable();
  constructor() {
    reaction(
      () => {
        return siteStore.selectedSite;
      },
      (selectedSite: Site) => {
        this.selectedSiteChangeSubject.next(true);
      }
    );

    this.selectedSiteChange$.subscribe(() => {
      const { selectedSite } = siteStore;
      SiteWarmingController.handleSiteChange(selectedSite);
      if (selectedSite) {
        const { siteCode, serviceAreaId } = selectedSite;
        // if using service area id, then continue to use service area id, else use siteCode
        if (UrlHelper.getParameterValue(HTTP_PARAM_SERVICE_AREA_ID)) {
          UrlHelper.setParameterValue(
            HTTP_PARAM_SERVICE_AREA_ID,
            serviceAreaId
          );
        } else {
          UrlHelper.setParameterValue(HTTP_PARAM_SITE_CODE, siteCode);
        }
        document.title = `${siteCode} - ${APP_NAME}`;
        // set static dimension values
        metricsPublisher.publishString(LOG_ATTRIBUTE_SITE_CODE, siteCode);
        const countryCode = selectedSite.pickupAddress?.countryCode;
        metricsPublisher.publishString(
          LOG_ATTRIBUTE_SITE_COUNTRY_CODE,
          countryCode
        );
      } else {
        document.title = APP_NAME;
      }

      // clear out any selected transporter, routes, or order ids
      orderStore.setSelectedOrderIds([]);
      orderStore.setHighlightedOrderIds([]);
      routeStore.setSelectedRouteId(undefined);
      routeStore.setSelectedRouteOrderGroups([]);
      routeStore.setSelectedRouteTransporterGroups([]);
      transporterStore.setSelectedTransporterId(undefined);
      transporterStore.setSelectedTransporterTransporterGroup(undefined);
    });
  }
}

export default new SiteReactor();
