import { API } from 'aws-amplify';
import AuthHelper from '../auth/AuthHelper';
import Observable from 'zen-observable';
import Service from './Service';
import SubscriptionPayload from './SubscriptionPayload';

export default class Subscription<T> {
  private readonly query: any;
  private readonly variables: any;
  private readonly dataReceiver: (T) => void;
  private errorReceiver: (any) => void;
  private readonly operationName: string;
  private appSyncSubscription: ZenObservable.Subscription;

  constructor(
    query: any,
    variables: any,
    dataReceiver: (updatedEntity: T) => void
  ) {
    this.query = query;
    this.variables = variables;
    this.dataReceiver = dataReceiver;
    this.operationName = Service.getOperationName(query);
  }

  setErrorReceiver(errorReceiver: (error: any) => void): void {
    this.errorReceiver = errorReceiver;
  }

  async openSubscription() {
    const appSyncObservable = (await API.graphql({
      query: this.query,
      variables: this.variables,
      authToken: await AuthHelper.getJwt(),
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    })) as Observable<T>;
    this.appSyncSubscription = appSyncObservable.subscribe(
      (subscriptionPayload: SubscriptionPayload) => {
        const data = subscriptionPayload.value?.data;
        if (data && data[this.operationName]) {
          const entity = data[this.operationName];
          this.dataReceiver(entity);
        }
      },
      async (error: SubscriptionPayload) => {
        // notify error receiver that an error has occurred
        if (this.errorReceiver) {
          this.errorReceiver(error);
        }
      }
    );
  }

  async closeSubscription(): Promise<void> {
    if (this.appSyncSubscription) {
      this.appSyncSubscription.unsubscribe();
    }
  }
}
