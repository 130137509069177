import { PREFIX_ORDER_ID, PREFIX_ROUTE_ID } from '../../util/FilterConstants';
import { observer } from 'mobx-react';
import Box from '@amzn/meridian/box';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import FilterHelper from 'util/FilterHelper';
import Icon from '@amzn/meridian/icon';
import React from 'react';
import RouteHelper from '../../util/RouteHelper';
import Row from '@amzn/meridian/row';
import SearchByIdController from '../../controllers/SearchByIdController';
import SearchByIdValueComp from './SearchByIdValueComp';
import SearchField, { SearchSuggestion } from '@amzn/meridian/search-field';
import Text from '@amzn/meridian/text';
import orderStore from '../../stores/orderStore';
import routeStore from '../../stores/routeStore';
import searchByIdStore from '../../stores/searchByIdStore';
import trashTokens from '@amzn/meridian-tokens/base/icon/trash';

export interface SearchByIdProps {}

class SearchByIdComp extends React.Component<SearchByIdProps> {
  render() {
    const [searchSuggestions, searchKeys] = this.createSearchSuggestions();

    const placeHolder = 'Search by Route/Order Id';
    const { searchByPartialStringInput, searchByIdValues } = searchByIdStore;
    const values = [];
    if (searchByIdValues.length > 1) {
      values.push(
        <Box
          backgroundColor="highlight"
          key="deleteAllSearchByIdValues"
          type="outline"
          spacingInset="none 200 none 200"
        >
          <Row spacing="100">
            <Text>Remove All</Text>
            <Button
              type="icon"
              size="small"
              onClick={() => SearchByIdController.handleRemoveAll()}
            >
              <Icon tokens={trashTokens} />
            </Button>
          </Row>
        </Box>
      );
    }
    values.push(
      ...searchByIdValues.map((searchByIdValue) => (
        <SearchByIdValueComp key={searchByIdValue} value={searchByIdValue} />
      ))
    );

    return (
      <div className={'search-by-id'}>
        <Column spacing="200">
          <Row>
            <SearchField
              value={searchByPartialStringInput}
              width={'100%'}
              onChange={(value) =>
                SearchByIdController.handleSearchByPartialString(value)
              }
              onSubmit={(value) => {
                if (searchKeys.length === 1) {
                  SearchByIdController.handleSearchByIdSelection(searchKeys[0]);
                }
              }}
              placeholder={`${placeHolder}...`}
              searchButton={true}
            >
              {searchSuggestions}
            </SearchField>
          </Row>
          <Row wrap="down" spacing="100">
            {values}
          </Row>
        </Column>
      </div>
    );
  }

  private createSearchSuggestions() {
    const { searchByPartialStringInput } = searchByIdStore;
    const searchSuggestions = [];
    const searchKeys: string[] = [];
    const routesFilteredBySearchString = routeStore.routes.filter((route) => {
      const filterKey = FilterHelper.makeFilterString(
        PREFIX_ROUTE_ID,
        route.routeId
      ).toLowerCase();
      return filterKey.indexOf(searchByPartialStringInput.toLowerCase()) > -1;
    });
    routesFilteredBySearchString.forEach((route) => {
      const filterKey = FilterHelper.makeFilterString(
        PREFIX_ROUTE_ID,
        route.routeId
      );
      searchSuggestions.push(this.createSearchSuggestion(filterKey));
      searchKeys.push(filterKey);
    });

    const allOrders = RouteHelper.getOrders(routeStore.routes);
    allOrders.push(...orderStore.orders);

    const ordersFilteredBySearchString = allOrders.filter((order) => {
      const filterKey = FilterHelper.makeFilterString(
        PREFIX_ORDER_ID,
        order.orderId
      ).toLowerCase();
      return filterKey.indexOf(searchByPartialStringInput.toLowerCase()) > -1;
    });
    ordersFilteredBySearchString.forEach((order) => {
      const filterKey = FilterHelper.makeFilterString(
        PREFIX_ORDER_ID,
        order.orderId
      );
      searchSuggestions.push(this.createSearchSuggestion(filterKey));
      searchKeys.push(filterKey);
    });
    return [searchSuggestions, searchKeys];
  }

  private createSearchSuggestion(code: string) {
    return (
      <SearchSuggestion
        key={`searchById${code}`}
        onClick={() => SearchByIdController.handleSearchByIdSelection(code)}
      >
        {code}
      </SearchSuggestion>
    );
  }
}

export default observer(SearchByIdComp);
