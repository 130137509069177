import filterReactor from './filterReactor';
import networkReactor from './networkReactor';
import orderReactor from './orderReactor';
import routeReactor from './routeReactor';
import searchByIdReactor from './searchByIdReactor';
import selectedTransporterGroupsReactor from './selectedTransporterGroupsReactor';
import selectionReactor from './selectionReactor';
import siteDataReactor from './siteDataReactor';
import siteReactor from './siteReactor';
import sortReactor from './sortReactor';
import transporterReactor from './transporterReactor';
import urlReactor from './urlReactor';
import viewReactor from './viewReactor';

class ReactorLoader {
  /**
   * We add explicit references to all reactors so that they are running in the browser
   * @private
   */
  private reactors = [
    filterReactor,
    orderReactor,
    networkReactor,
    routeReactor,
    selectionReactor,
    selectedTransporterGroupsReactor,
    searchByIdReactor,
    siteDataReactor,
    siteReactor,
    sortReactor,
    transporterReactor,
    urlReactor,
    viewReactor,
  ];
  private loaded = false;

  load() {
    this.loaded = true;
  }
}

export default new ReactorLoader();
