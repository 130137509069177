import {
  ERROR_MESSAGE_TIMEOUT,
  SUCCESS_MESSAGE_TIMEOUT,
} from '../util/TimeHelper';
import AssignmentHelper from '../util/AssignmentHelper';
import Log from '../logging/Log';
import MessagesController from './MessagesController';
import OperationController from './OperationController';
import OrderService from '../services/OrderService';
import StringHelper from '../util/StringHelper';
import assignmentStore from '../stores/assignmentStore';
import orderStore from '../stores/orderStore';

export const orderUnassignmentOperationName = 'orderUnassignment';

export default class OrderUnassignmentController {
  static async startUnassignment(): Promise<void> {
    assignmentStore.clearConfirmationMessages();
    const confirmationMessages =
      AssignmentHelper.determineUnassignmentConfirmationMessages();
    if (confirmationMessages.length === 0) {
      // nothing for the user to confirm, so call confirm assignment
      await OrderUnassignmentController.confirmUnassignment();
    } else {
      // cause the confirmation dialog to popup
      Log.info(
        `Confirmation messages: [${confirmationMessages}]`,
        orderUnassignmentOperationName
      );
      assignmentStore.setConfirmationMessages(confirmationMessages);
    }
  }

  static async confirmUnassignment(): Promise<void> {
    OperationController.startOperation('Unassign transporter');
    const { siteCode } = AssignmentHelper.getCommonAssignmentRequestFields();
    const orderIdInputs = AssignmentHelper.gatherOrderIdsForAssignment();
    const orderIds: string[] = orderIdInputs.map((o) => o.orderId);

    const assignmentResponse = await OrderService.unassignOrdersFromTransporter(
      siteCode,
      orderIdInputs
    );
    OperationController.completeOperation();

    // gather the results and determine if need to inform user of any failures
    const successfulOrderIds: string[] = [];
    const failedOrderIds: string[] = [];
    const { errorMessage, successful, failed } = assignmentResponse;
    if (errorMessage) {
      failedOrderIds.push(...orderIds);
    } else {
      successfulOrderIds.push(...successful);
      failedOrderIds.push(...failed);
    }
    if (failedOrderIds.length > 0 && successfulOrderIds.length === 0) {
      const msg = `All un-assignments failed${
        errorMessage ? `: ${errorMessage}` : ''
      }`;
      MessagesController.addAlertMessage(msg, ERROR_MESSAGE_TIMEOUT);
      Log.error(msg, orderUnassignmentOperationName);
    } else if (failedOrderIds.length > 0 && successfulOrderIds.length > 0) {
      const msg =
        'Partial order un-assignment failure: ' +
        `${StringHelper.makeCountDisplay(
          successfulOrderIds.length,
          'order'
        )} succeeded, but ` +
        `${StringHelper.makeCountDisplay(
          failedOrderIds.length,
          'order'
        )} failed.`;
      MessagesController.addAlertMessage(msg, ERROR_MESSAGE_TIMEOUT);
      Log.error(msg, orderUnassignmentOperationName);
    }

    // remove the successful order ids from the selected order ids in case the user wants to try again with
    // just the failed order ids
    orderStore.removeSelectedOrderIds(successfulOrderIds);

    if (failedOrderIds.length === 0) {
      const msg = 'Successful un-assignment';
      MessagesController.addSuccessMessage(msg, SUCCESS_MESSAGE_TIMEOUT);
      Log.info(msg, orderUnassignmentOperationName);
    }
  }
}
