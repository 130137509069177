import './OrderComp.css';
import { Order } from '@amzn/gsf-dispatcher-schema';
import { PICKUP_WINDOW_END_TIME } from '../../util/SortConstants';
import { observer } from 'mobx-react';
import AddressHelper from 'util/AddressHelper';
import Box from '@amzn/meridian/box';
import Button from '@amzn/meridian/button';
import ClipboardHelper from '../../util/ClipboardHelper';
import Column from '@amzn/meridian/column';
import GeospatialHelper from '../../map/GeospatialHelper';
import Icon from '@amzn/meridian/icon';
import Link from '@amzn/meridian/link';
import OrderController from '../../controllers/OrderController';
import OrderHelper from 'util/OrderHelper';
import Popover from '@amzn/meridian/popover';
import React, { RefObject } from 'react';
import RemainingTimeComp from '../common/RemainingTimeComp';
import Row from '@amzn/meridian/row';
import StringHelper from '../../util/StringHelper';
import Text from '@amzn/meridian/text';
import Tooltip from '@amzn/meridian/tooltip';
import TransporterController from '../../controllers/TransporterController';
import TransporterHelper from '../../util/TransporterHelper';
import UnitHelper from '../../util/UnitHelper';
import UrlHelper from 'util/UrlHelper';
import bagImage from '../../assets/images/bag.svg';
import copyTokens from '@amzn/meridian-tokens/base/icon/copy';
import dropoffImage from '../../assets/images/arrow_dn.svg';
import heavyBagImage from '../../assets/images/heavy.svg';
import infoTokens from '@amzn/meridian-tokens/base/icon/info';
import locationTokens from '@amzn/meridian-tokens/base/icon/location';
import orderStore from '../../stores/orderStore';
import phoneTokens from '@amzn/meridian-tokens/base/icon/phone';
import pickupImage from '../../assets/images/arrow_up.svg';
import siteStore from '../../stores/siteStore';
import sortStore from '../../stores/sortStore';

export interface OrderProps {
  order: Order;
}

interface OrderState {
  popupOpened: boolean;
  lastCloseTime: number;
}

class OrderComp extends React.Component<OrderProps, OrderState> {
  private ref: RefObject<HTMLDivElement>;

  constructor(props: OrderProps) {
    super(props);
    this.closePopup = this.closePopup.bind(this);
  }

  componentDidMount() {
    this.setState({ popupOpened: false, lastCloseTime: 0 });
    this.ref = React.createRef();
  }

  render() {
    const { order } = this.props;
    const popupOpened = this.state?.popupOpened;
    const isSelectedOrder = orderStore.isOrderIdSelected(order.orderId);
    const transporterName = TransporterHelper.getTransporterName(
      order.transporter
    );
    const isHighlightedOrder = orderStore.isOrderIdHighlighted(order.orderId);
    const isAssignedOrder = OrderHelper.isOrderOrRouteAssigned(order);
    const isOrderHeavy = OrderHelper.isOrderHeavy(order);
    const isOneHourOrder = OrderHelper.isOneHourDeliveryWindow(order);
    const isOrderPicked = OrderHelper.isOrderOrRoutePickedUp(order);
    const { selectedSite } = siteStore;
    const pickupFromSite = GeospatialHelper.sameLocation(
      selectedSite.pickupLocation,
      order.pickupLocation
    );
    const distance = UnitHelper.convertMetersToDrivingUnit(
      GeospatialHelper.getDistance(order.pickupLocation, order.deliveryLocation)
    );
    const orderWeight = UnitHelper.getOrderWeight(order);
    const { selectedSortCode } = sortStore;
    const urlForOrder = UrlHelper.getUrlForOrder(order);
    return (
      <div
        onClick={(event) => OrderController.orderClicked(order, event)}
        className={
          isHighlightedOrder
            ? 'highlighted-order'
            : isAssignedOrder
            ? 'assigned-order'
            : 'nonhighlighted-order'
        }
        ref={this.ref}
      >
        <Box
          type="outline"
          spacingInset="200"
          className={isSelectedOrder ? 'div-selected' : 'div-not-selected'}
        >
          <Row wrap="down" widths={['fill', '2em']}>
            <Column spacing="100">
              <Row wrap="down" spacing="100 300">
                {selectedSortCode === PICKUP_WINDOW_END_TIME ? (
                  <RemainingTimeComp endTime={order.pickupWindow?.endDate} />
                ) : (
                  <RemainingTimeComp endTime={order.deliveryWindow?.endDate} />
                )}
                <Text tag="span" className="nowrap">
                  {distance}
                </Text>
                <Box className="nowrap">
                  {isOrderHeavy ? (
                    <Tooltip
                      position="end"
                      title={
                        isOrderHeavy
                          ? 'Heavy packages'
                          : 'Normal weight packages'
                      }
                    >
                      <img
                        className="order-package-image"
                        src={heavyBagImage}
                      />
                    </Tooltip>
                  ) : (
                    <img className="order-package-image" src={bagImage} />
                  )}
                  <Text tag="span">
                    {order.packageCount
                      ? StringHelper.makeCountDisplay(
                          order.packageCount,
                          'package'
                        )
                      : StringHelper.makeCountDisplay(order.itemCount, 'item')}
                  </Text>
                </Box>
                <Text tag="span" className="nowrap">
                  {orderWeight}
                </Text>
                {isOneHourOrder && (
                  <Tooltip position="end" title="One hr delivery window">
                    <Text type="h200">1 hr</Text>
                  </Tooltip>
                )}
              </Row>
              <Row wrap="down" spacing="100 300">
                <Text tag="span">
                  <img className="order-pickup-image" src={pickupImage} />
                  <Text tag="span" color={pickupFromSite ? 'error' : 'primary'}>
                    {AddressHelper.getDisplayAddress(order.pickupAddress)}
                  </Text>
                </Text>
                <Text tag="span">
                  <img className="order-dropoff-image" src={dropoffImage} />
                  <Text tag="span">
                    {AddressHelper.getDisplayAddress(order.deliveryAddress)}
                  </Text>
                </Text>
              </Row>
              {transporterName && (
                <Row wrap="down" spacing="200">
                  <Column>
                    <Text type="h100" className="nowrap">
                      {isOrderPicked
                        ? `Picked Up By: ${transporterName}`
                        : `Assigned To: ${transporterName}`}
                    </Text>
                  </Column>
                  <Column>
                    <Button
                      size="small"
                      type="icon"
                      propagateClickEvent={false}
                      onClick={() =>
                        TransporterController.handleTransporterLocationClick(
                          order.transporter
                        )
                      }
                    >
                      <Icon tokens={locationTokens} />
                    </Button>
                  </Column>
                  <Column>
                    <Button
                      size="small"
                      type="icon"
                      propagateClickEvent={false}
                      onClick={() =>
                        TransporterController.handleTransporterPhoneClick(
                          order.transporter
                        )
                      }
                    >
                      <Icon tokens={phoneTokens} />
                    </Button>
                  </Column>
                </Row>
              )}
            </Column>
            <Column alignmentVertical="top">
              <Button
                size="small"
                type="icon"
                onClick={() => this.clickInformationIcon()}
                propagateClickEvent={false}
              >
                <Icon tokens={infoTokens} />
              </Button>
            </Column>
          </Row>
        </Box>
        {this.ref && (
          <Popover
            anchorNode={this.ref.current}
            open={popupOpened}
            onClose={this.closePopup}
            position="end"
            alignment="center"
          >
            <Column alignmentHorizontal="center" spacingInset="200 500 200 500">
              <Row>
                <Link
                  href={urlForOrder}
                  propagateClickEvent={false}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {order.orderId}
                </Link>
                <Button
                  propagateClickEvent={false}
                  type="icon"
                  size="small"
                  onClick={() => ClipboardHelper.copyToClipboard(order.orderId)}
                >
                  <Icon tokens={copyTokens}></Icon>
                </Button>
              </Row>
              <Row>
                <Text>{OrderHelper.getPickupWindowDisplayValue(order)}</Text>
              </Row>

              <Row>
                <Text>{OrderHelper.getDeliveryWindowDisplayValue(order)}</Text>
              </Row>
            </Column>
          </Popover>
        )}
      </div>
    );
  }

  async clickInformationIcon() {
    const { lastCloseTime } = this.state;
    // add a 250 msec delay so that user can click the information icon to close the popup without it popping up again
    if (Date.now() - lastCloseTime >= 250) {
      this.setState({ popupOpened: true });
    }
  }

  closePopup() {
    this.setState({ popupOpened: false, lastCloseTime: Date.now() });
  }
}

export default observer(OrderComp);
