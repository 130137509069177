import './SiteAppComp.css';
import { observer } from 'mobx-react';
import AssignmentConfirmDialogComp from '../assignment/AssignmentConfirmDialogComp';
import Column from '@amzn/meridian/column';
import GroupByAssignmentComp from './GroupByAssignmentComp';
import HtmlHelper from '../../util/HtmlHelper';
import Loader from '@amzn/meridian/loader';
import MapComp from '../map/MapComp';
import MapControlsComp from './MapControlsComp';
import MapViewComp from './MapViewComp';
import MessagesComp from '../common/MessagesComp';
import OrderFilterComp from './OrderFilterComp';
import OrderFilterSearchByIdComp from './SearchByIdComp';
import OrderSortComp from './OrderSortComp';
import OrderViewComp from './OrderViewComp';
import React from 'react';
import RoutesAndOrdersComp from './RoutesAndOrdersComp';
import RoutesAndOrdersHeaderComp from './RoutesAndOrdersHeaderComp';
import Row from '@amzn/meridian/row';
import SiteMaskComp from '../common/SiteMaskComp';
import TransporterFilterComp from './TransporterFilterComp';
import TransporterFilterSearchByNameComp from './SearchTransporterByNameComp';
import TransporterListComp from '../transporter/TransporterListComp';
import UnassignComp from './UnassignComp';
import UnassignmentConfirmDialogComp from '../assignment/UnassignmentConfirmDialogComp';
import orderStore from 'stores/orderStore';
import routeStore from 'stores/routeStore';
import siteStore from '../../stores/siteStore';
import transporterStore from '../../stores/transporterStore';

export interface SiteAppProps {}

class SiteAppComp extends React.Component<SiteAppProps> {
  componentDidMount() {
    this.setSiteDivHeight();

    addEventListener('resize', () => {
      this.setSiteDivHeight();
    });
  }
  render() {
    const { loadingSiteData } = siteStore;
    const widths = loadingSiteData ? ['50%', '50%'] : ['25%', '25%', '50%'];
    return (
      <React.Fragment>
        <MessagesComp />
        <SiteMaskComp />
        <AssignmentConfirmDialogComp />
        <UnassignmentConfirmDialogComp />
        <Row widths={['25%', '25%', '50%']} height={'50px'}>
          <Column>
            <OrderViewComp />
          </Column>
          <Column />
          <Column>
            <Row alignmentHorizontal={'justify'}>
              <Column>
                <MapViewComp />
              </Column>
              <Column>
                <MapControlsComp />
              </Column>
            </Row>
          </Column>
        </Row>
        <div id="appRow" className="appRow">
          <Row widths={widths} height="100%">
            {loadingSiteData ? (
              <Column
                key="orderAndRouteLoader"
                height="100%"
                alignmentHorizontal="center"
                alignmentVertical="center"
              >
                <Loader />
              </Column>
            ) : (
              <React.Fragment>
                <Column height="100%">
                  <OrderFilterSearchByIdComp />
                  <OrderFilterComp />
                  <OrderSortComp />
                  <GroupByAssignmentComp />
                  <UnassignComp />
                  <RoutesAndOrdersHeaderComp
                    routes={routeStore.sortedRoutes}
                    orders={orderStore.sortedOrders}
                  />
                  <RoutesAndOrdersComp
                    routes={routeStore.sortedRoutes}
                    orders={orderStore.sortedOrders}
                  />
                </Column>
                <Column height="100%">
                  <TransporterFilterSearchByNameComp />
                  <TransporterFilterComp />
                  <TransporterListComp
                    key={'transporter-list-comp'}
                    transporters={transporterStore.sortedTransporters}
                  />
                </Column>
              </React.Fragment>
            )}
            <Column height="100%">
              <MapComp />
            </Column>
          </Row>
        </div>
      </React.Fragment>
    );
  }

  private async setSiteDivHeight() {
    const viewportHeight = window.innerHeight;
    const headerHeight = (await HtmlHelper.getHtmlElementById('header'))
      .clientHeight;
    const div = await HtmlHelper.getHtmlElementById('appRow');
    const padding = 70;
    div.style.height = `${viewportHeight - headerHeight - padding}px`;
  }
}

export default observer(SiteAppComp);
