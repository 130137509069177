import * as _ from 'lodash';
import {
  ENVIRONMENT_BETA,
  ENVIRONMENT_DEV,
  ENVIRONMENT_GAMMA,
  ENVIRONMENT_PROD,
} from './UrlConstants';
import { Order } from '@amzn/gsf-dispatcher-schema';
import EnvConfigHelper from './EnvConfigHelper';

export default class UrlHelper {
  static getParameterValue(name: string): string | null {
    const urlQueryString = UrlHelper.readUrlQueryString();
    const searchParams = new URLSearchParams(urlQueryString);
    return searchParams.get(name);
  }

  static setParameterValue(parameterName: string, parameterValue: string) {
    const urlQueryString = UrlHelper.readUrlQueryString();
    const params = new URLSearchParams(urlQueryString);
    params.set(parameterName, parameterValue);
    const newQueryString = `?${params.toString()}`;
    window.history.pushState({}, document.title, `/${newQueryString}`);
  }

  static removeParameter(parameterName: string) {
    const urlQueryString = UrlHelper.readUrlQueryString();
    const searchString = `${parameterName}=`;
    const startIndex = urlQueryString.indexOf(searchString);
    if (startIndex > -1) {
      let endIndex = urlQueryString.indexOf(
        '&',
        startIndex + searchString.length
      );
      if (endIndex === -1) {
        endIndex = urlQueryString.length;
      } else {
        endIndex++;
      }
      let newQueryString = `${urlQueryString.substring(
        0,
        startIndex
      )}${urlQueryString.substring(endIndex)}`;
      if (newQueryString.endsWith('&')) {
        newQueryString = newQueryString.substring(0, newQueryString.length - 1);
      }
      if (newQueryString === '?') {
        newQueryString = '';
      }
      window.history.pushState({}, document.title, `/${newQueryString}`);
    }
  }

  /**
   * Returns the environment configuration key.  Values = 'beta', 'gamma-eu', 'prod', etc.  The default value is 'dev'
   */
  static getEnvironmentName(): string {
    const hostPrefix = UrlHelper.getHostPrefix();
    const hostEnvironment = UrlHelper.getEnvironmentFromHostPrefix(hostPrefix);
    const knownEnvironments = [
      ENVIRONMENT_BETA,
      ENVIRONMENT_GAMMA,
      ENVIRONMENT_PROD,
    ];
    return _.includes(knownEnvironments, hostEnvironment)
      ? hostPrefix
      : ENVIRONMENT_DEV;
  }

  /**
   * Returns prod if should use production cognito, false otherwise
   */
  static useProductionCognito(): boolean {
    return UrlHelper.getEnvironmentName().startsWith(ENVIRONMENT_PROD);
  }

  static readUrlQueryString(): string {
    return window.location.search;
  }

  static getUrlForOrder(order: Order): string {
    const orderId = order.orderId;
    const { logisticsHostName } = EnvConfigHelper.getEnvironmentConfig();
    return `https://${logisticsHostName}/internal/network/package?type=ORDER&orderId=${orderId}`;
  }

  private static getHostPrefix(): string {
    const host = window.location.host;
    const firstDotIndex = host.indexOf('.');
    return firstDotIndex > -1 ? host.substring(0, firstDotIndex) : host;
  }

  private static getEnvironmentFromHostPrefix(hostPrefix: string): string {
    const dashIndex = hostPrefix.indexOf('-');
    return dashIndex > -1 ? hostPrefix.substring(0, dashIndex) : hostPrefix;
  }
}
